import 'tippy.js/dist/tippy.css';

import { type ForwardedRef, forwardRef, memo, useMemo } from 'react';
import { css, useTheme } from '@emotion/react';
import Tippy, { type TippyProps } from '@tippyjs/react';

import { withLazyTippy } from '../tippy/lazy/withLazyTippy';
import { Typography } from '../typography/Typography';

const SHOW_DELAY_MS = 150;
const HIDE_DELAY_MS = 100;

const LazyTippy = withLazyTippy(Tippy);

export type TooltipProps = Omit<TippyProps, 'ref'> & {
  /** Only render content when visible. Use it when the content has lifecycle logic. */
  lazy?: boolean;
  /** Display tooltip with light background. */
  light?: boolean;
  /** Apply default delay. */
  withDelay?: boolean;
  /** Use Tippy's delay prop when withDelay is false. */
  delay?: number | [number, number];
};

export const TooltipBase = forwardRef(
  (
    {
      content = null,
      render = null,
      duration = null,
      lazy = false,
      light = false,
      withDelay = true,
      delay = null,
      ...props
    }: TooltipProps,
    ref: ForwardedRef<HTMLElement>,
  ) => {
    const theme = useTheme();

    // If render is defined, wrap the render function to render a Typography component.
    const renderProxy = useMemo(
      () =>
        render
          ? (...args: Parameters<TooltipProps['render']>) => (
              <Typography variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}>
                {render(...args)}
              </Typography>
            )
          : null,
      [render],
    );

    // If content is defined, wrap it in a Typography component.
    const contentProxy = content ? (
      <Typography variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}>
        {content}
      </Typography>
    ) : null;

    const TippyComponent = lazy ? LazyTippy : Tippy;

    return (
      <TippyComponent
        {...props}
        content={contentProxy}
        render={renderProxy}
        ref={ref}
        duration={
          Number.isFinite(duration)
            ? duration
            : theme.transitions.default.duration
        }
        delay={withDelay ? [SHOW_DELAY_MS, HIDE_DELAY_MS] : delay}
        css={css`
          background-color: ${light
            ? theme.colors.gray[25]
            : theme.colors.gray[700]};
          color: ${light
            ? theme.contrastColors.gray[25]
            : theme.contrastColors.gray[700]};
          border: ${light ? `1px solid ${theme.colors.gray[50]}` : 'none'};

          .tippy-content {
            padding: 8px;
            text-align: center;
          }

          .tippy-arrow {
            color: ${light ? theme.colors.gray[50] : theme.colors.gray[700]};
          }
        `}
      />
    );
  },
);

TooltipBase.displayName = 'Tooltip';

export const Tooltip = memo(TooltipBase);
