import { memo, type ReactNode } from 'react';

import {
  Button,
  OrnamentWrapper,
  Typography,
  WaveCard,
} from '@eversity/ui/design-system';

import * as styles from './FlashCardsEmptyState.styles';

type FlashCardsEmptyStateProps = {
  title: ReactNode;
  subtitle: ReactNode;
  buttonLabel: ReactNode;
  onClickConfirm: () => void;
};

const FlashCardsEmptyStateBase = ({
  title,
  subtitle,
  buttonLabel,
  onClickConfirm,
}: FlashCardsEmptyStateProps) => {
  return (
    <div css={styles.container}>
      <OrnamentWrapper element="flashCards">
        <WaveCard variant={WaveCard.VARIANTS.PRIMARY_DARK}>
          <div css={styles.card}>
            <Typography
              css={styles.text}
              variant={Typography.VARIANTS.HEADING_4}
            >
              {title}
            </Typography>

            <Typography
              css={styles.text}
              variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}
            >
              {subtitle}
            </Typography>

            <Button
              variant={Button.VARIANTS.PRIMARY}
              onClick={onClickConfirm}
              outline
              css={styles.buttonContainer}
            >
              {buttonLabel}
            </Button>
          </div>
        </WaveCard>
      </OrnamentWrapper>
    </div>
  );
};

FlashCardsEmptyStateBase.displayName = 'FlashCardsEmptyState';

export const FlashCardsEmptyState = memo(FlashCardsEmptyStateBase);
