import {
  memo,
  type MemoExoticComponent,
  type MouseEvent as RMouseEevent,
  type PropsWithChildren,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import Floater from 'react-floater';
import cn from 'classnames';

import { useBoolState } from '@eversity/ui/utils';

import { Button, type ButtonProps } from '../../general/button/Button';
import { Card } from '../card/Card';
import * as styles from './TooltipButton.styles';

export enum TOOLTIP_BUTTON_TRIGGERS {
  CLICK = 'CLICK',
  HOVER = 'HOVER',
}

type TooltipButtonProps = {
  trigger?: TOOLTIP_BUTTON_TRIGGERS;
  title?: ReactNode;
  content?: ReactNode;
  isOpen?: boolean;
} & Omit<ButtonProps, 'content'> &
  PropsWithChildren;

const TooltipButtonBase = ({
  trigger = TOOLTIP_BUTTON_TRIGGERS.HOVER,
  content = null,
  title = null,
  onClick,
  isOpen = false,
  children,
  ...props
}: TooltipButtonProps) => {
  const [isTooltipOpen, onOpenTooltip, onCloseTooltip, onToggle] =
    useBoolState(isOpen);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const isClickable = useMemo(
    () => trigger === TOOLTIP_BUTTON_TRIGGERS.CLICK,
    [trigger],
  );

  const onMouseEnter = useCallback(() => {
    if (isClickable) {
      return;
    }

    onOpenTooltip();
  }, [isClickable, onOpenTooltip]);

  const onMouseLeave = useCallback(() => {
    if (isClickable) {
      return;
    }

    onCloseTooltip();
  }, [isClickable, onCloseTooltip]);

  const onClickChildren = useCallback(
    (event: RMouseEevent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(event);
      }

      if (!isClickable) {
        return;
      }

      onToggle();
    },
    [isClickable, isTooltipOpen, onCloseTooltip, onOpenTooltip],
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node)
      ) {
        onCloseTooltip();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tooltipRef, onCloseTooltip]);

  return (
    <div ref={tooltipRef}>
      <Floater
        open={isTooltipOpen}
        content={
          <Card
            className={cn({ isTooltipOpen })}
            css={styles.contentContainer}
          >
            {title && <Card.Title>{title}</Card.Title>}

            {content && (
              <Card.Body css={styles.contentBody}>{content}</Card.Body>
            )}
          </Card>
        }
        offset={5}
        styles={{
          arrow: {
            length: 12,
            spread: 24,
          },
          wrapper: {
            cursor: isClickable ? 'pointer' : 'default',
          },
          floater: {
            filter: 'drop-shadow(0 0 11px #1314161A)',
          },
          container: {
            display: 'flex',
            minWidth: 0,
            padding: 0,
            borderRadius: 8,
            color: 'unset',
          },
        }}
      >
        <Button
          {...props}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onClick={onClickChildren}
        >
          {children}
        </Button>
      </Floater>
    </div>
  );
};

export const TooltipButton: MemoExoticComponent<typeof TooltipButtonBase> & {
  TRIGGERS?: typeof TOOLTIP_BUTTON_TRIGGERS;
} = memo(TooltipButtonBase);

TooltipButton.TRIGGERS = TOOLTIP_BUTTON_TRIGGERS;
