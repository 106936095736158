import { type ForwardedRef, forwardRef, type HTMLProps, memo } from 'react';

import * as styles from './LinearProgress.styles';

export type LinearProgressProps = Omit<HTMLProps<HTMLDivElement>, 'ref'> & {
  progress?: number;
  backgroundColor?: string;
  progressColor?: string;
};

export const LinearProgressBase = forwardRef(
  (
    {
      progress = 0,
      backgroundColor,
      progressColor,
      ...props
    }: LinearProgressProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const normalizedProgress = Math.min(Math.max(0, progress), 1);

    return (
      <div
        {...props}
        css={styles.container(backgroundColor)}
      >
        <div
          className="animate"
          css={styles.progressBar(normalizedProgress, progressColor)}
          ref={ref}
        >
          {props.children}
        </div>
      </div>
    );
  },
);

LinearProgressBase.displayName = 'LinearProgress';

export const LinearProgress = memo(LinearProgressBase);
