import 'react-datepicker/dist/react-datepicker.css';

import {
  cloneElement,
  memo,
  type ReactElement,
  useCallback,
  useEffect,
} from 'react';
import ReactDatePicker, {
  type ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import { FormattedDate } from 'react-intl';
import { Global } from '@emotion/react';
import { CalendarThirtyTwo, Left, Right } from '@icon-park/react';

import { useLocaleContext } from '@eversity/ui/utils';

import { Button } from '../../general/button/Button';
import { useLayerContext } from '../../general/layer/Layer.context';
import { Typography } from '../../general/typography/Typography';
import { type INPUT_SIZES } from '../input/constants';
import { Input } from '../input/Input';
import { CustomInput } from './components/CustomInput';
import * as styles from './Datepicker.styles';

export type DatepickerProps<WithRange extends boolean = false> = Omit<
  ReactDatePickerProps<WithRange>,
  | 'placeholderText'
  | 'selected'
  | 'value'
  | 'customInput'
  | 'renderCustomHeader'
  | 'startDate'
  | 'endDate'
> & {
  value: WithRange extends false ? Date | null : [Date | null, Date | null];
  placeholder?: ReactDatePickerProps<undefined, WithRange>['placeholderText'];
  inputSize?: INPUT_SIZES;
  isClearable?: boolean;
  'data-cy'?: string;
  // When using selectsRange, set startDate and endDate by passing [startDate, endDate] in value.
  startDate?: WithRange extends true ? never : Date;
  endDate?: WithRange extends true ? never : Date;
  headerIcon?: React.ReactElement;
};

export const DatepickerBase = <WithRange extends boolean = false>({
  value = null,
  startDate = null,
  endDate = null,
  onChange,
  inputSize = Input.SIZES.MEDIUM,
  placeholder = null,
  selectsRange = false as WithRange,
  isClearable = false,
  dateFormat = 'P',
  portalId = undefined,
  'data-cy': dataCy = undefined,
  headerIcon = undefined,
  ...props
}: DatepickerProps<WithRange>) => {
  const { locale, dateFnsLocale } = useLocaleContext();

  const { isInLayer, layerZIndex } = useLayerContext();

  const hasValue = selectsRange ? !!value[0] && !!value[1] : !!value;

  const onClear = useCallback(
    () =>
      (
        onChange as (
          newValue: [Date | null, Date | null] | Date | null,
          event: Parameters<typeof onChange>[1],
        ) => void
      )(selectsRange === false ? null : [null, null], null),
    [onChange, selectsRange],
  );

  useEffect(() => {
    registerLocale(locale, dateFnsLocale);
  }, [locale, dateFnsLocale]);

  return (
    <div css={styles.datepicker}>
      <Global styles={styles.reactdatepicker({ isInLayer, layerZIndex })} />

      <ReactDatePicker<WithRange>
        {...props}
        selected={Array.isArray(value) ? value[0] : value}
        startDate={Array.isArray(value) ? value[0] : startDate}
        endDate={Array.isArray(value) ? value[1] : endDate}
        onChange={onChange}
        customInput={
          <CustomInput
            inputSize={inputSize}
            isClearable={isClearable}
            hasValue={hasValue}
            onClear={onClear}
            iconRight={<CalendarThirtyTwo theme="outline" />}
            {...(dataCy ? { 'data-cy': dataCy } : {})}
          />
        }
        locale={locale}
        dateFormat={dateFormat}
        placeholderText={placeholder}
        selectsRange={selectsRange}
        // The id name is arbitrary, it is appended to body if it does not exist.
        portalId={portalId ?? (isInLayer ? 'calendar-root' : undefined)}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div css={styles.header}>
            <div css={styles.title}>
              <Typography
                variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}
                css={styles.headerDate}
              >
                <FormattedDate
                  value={date}
                  year="numeric"
                  month="long"
                />
              </Typography>

              {headerIcon &&
                cloneElement(headerIcon, {
                  size: 16,
                })}
            </div>

            <div css={styles.monthButtons}>
              <Button
                icon={<Left />}
                size={Button.SIZES.SMALL}
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
              />

              <Button
                icon={<Right />}
                size={Button.SIZES.SMALL}
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
              />
            </div>
          </div>
        )}
      />
    </div>
  );
};

DatepickerBase.displayName = 'Datepicker';

export const Datepicker = memo(DatepickerBase) as {
  <WithRange extends boolean = false>(
    props: DatepickerProps<WithRange>,
  ): ReactElement | null;
};
