import { defineMessages } from 'react-intl';

import { LESSON_RESOURCE_TYPES } from '@eversity/domain/constants';

export const sequencesMessages = defineMessages({
  SEQUENCE_NUMBER: {
    defaultMessage: 'Séquence {sequenceNumber, number}',
  },

  SEQUENCES_COUNT: {
    defaultMessage: '{count, plural, one {# séquence} other {# séquences}}',
  },

  MODULES_COUNT: {
    defaultMessage: '{count, plural, one {# module} other {# modules}}',
  },
});

export const lessonsMessages = defineMessages({
  AVAILABLE_IN: {
    defaultMessage: 'Ce cours sera disponible dans',
  },

  LESSON: {
    defaultMessage: 'Cours',
  },
});

export const lessonResourceTypesMessages =
  defineMessages<LESSON_RESOURCE_TYPES>({
    [LESSON_RESOURCE_TYPES.FILE]: {
      defaultMessage: 'Fichier',
    },
    [LESSON_RESOURCE_TYPES.VIDEO]: {
      defaultMessage: 'Vidéo',
    },
  });
