import {
  type ForwardedRef,
  forwardRef,
  memo,
  type MemoExoticComponent,
} from 'react';
import { type To } from 'react-router-dom';

import { removeLinkAppearance } from '../../../utils/style';
import { Button, type ButtonProps } from '../../general/button/Button';
import { Link } from '../link/Link';

export type ButtonLinkProps = ButtonProps & {
  /** Link href. */
  to: To;
  /** External link. */
  isExternal?: boolean;
  /** Target for external links (used with "_blank" most of the time). */
  target?: string;
};

export const ButtonLinkBase = forwardRef(
  (
    {
      to,
      isExternal = false,
      target = undefined,
      className = null,
      children = null,
      disabled = false,
      ...props
    }: ButtonLinkProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => (
    <Link
      to={!isExternal ? to : undefined}
      href={isExternal ? (to as string) : undefined}
      target={target}
      isExternal={isExternal}
      css={removeLinkAppearance}
      isDisabled={disabled}
      className={className}
    >
      <Button
        ref={ref}
        {...props}
      >
        {children}
      </Button>
    </Link>
  ),
);

ButtonLinkBase.displayName = 'ButtonLink';

export const ButtonLink: MemoExoticComponent<typeof ButtonLinkBase> & {
  VARIANTS?: typeof Button.VARIANTS;
  HUES?: typeof Button.HUES;
  SIZES?: typeof Button.SIZES;
} = memo(ButtonLinkBase);

ButtonLink.VARIANTS = Button.VARIANTS;
ButtonLink.HUES = Button.HUES;
ButtonLink.SIZES = Button.SIZES;
