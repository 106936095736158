import { getTeachersFromCourse } from '@eversity/domain/courses';
import {
  type CourseViewStudent,
  type EmployeeViewMinimal,
} from '@eversity/types/domain';

/**
 * Get the referent teacher of a student in a given course
 * @param course - Student course
 * @param referentTeacherId - Id of the student's referent teacher
 * @returns EmployeeViewMinimal : the referent teacher data if exists
 */
export const getUserReferentTeacher = (
  course: CourseViewStudent,
  referentTeacherId: string | null,
): EmployeeViewMinimal => {
  if (course && referentTeacherId) {
    const teachers = getTeachersFromCourse(course);

    return (
      teachers
        .filter((teacher) => 'user' in teacher)
        .find((teacher) => teacher.user.id === referentTeacherId)?.user ?? null
    );
  }

  return null;
};
