import { memo, type ReactNode, useCallback, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@emotion/react';
import {
  BackOne,
  CloseSmall,
  Drag,
  GoAhead,
  GoEnd,
  GoStart,
  Pause,
  PlayOne,
  VolumeMute,
  VolumeNotice,
} from '@icon-park/react';
import cn from 'classnames';

import { Button, Slider, Tooltip } from '@eversity/ui/design-system';

import {
  DISPLAY_SMALL_CLASSNAME,
  DRAGGABLE_CLASSNAME,
  HIDE_DRAWER_CLASSNAME,
  HIDE_SMALL_CLASSNAME,
  MAX_AUDIO_VOLUME,
  MIN_AUDIO_VOLUME,
} from '../constants';
import { AudioPlayerContext } from '../contexts/AudioPlayer.context';
import { controlsTooltipsMessages } from './AudioControls.messages';
import * as styles from './AudioControls.styles';

export type AudioControlProps = {
  onNextTrackTooltipMessage?: ReactNode;
  onPreviousTrackTooltipMessage?: ReactNode;
  onNextTracklistTooltipMessage?: ReactNode;
  onPreviousTracklistTooltipMessage?: ReactNode;
  onRequestClose?: () => void;
  isLoading?: boolean;
};

const AudioControlsBase = ({
  onPreviousTracklistTooltipMessage = null,
  onPreviousTrackTooltipMessage = null,
  onNextTracklistTooltipMessage = null,
  onNextTrackTooltipMessage = null,
  onRequestClose,
  isLoading,
}: AudioControlProps) => {
  const {
    onClickMute,
    onToggleAudioPlay,
    isAudioPlaying,
    isMuted,
    audioVolume,
    onChangeAudioVolume,
    isDrawerOpen,
    onNextTrack,
    onPreviousTrack,
    onPreviousTracklist,
    onNextTracklist,
    onPauseAudio,
  } = useContext(AudioPlayerContext);
  const { colors } = useTheme();

  const onRequestCloseProxy = useCallback(() => {
    onPauseAudio();
    onRequestClose();
  }, [onPauseAudio, onRequestClose]);

  return (
    <div css={styles.mainContainer}>
      <div
        css={styles.soundAndTimeContainer}
        className={cn({ isDrawerOpen })}
      >
        <div css={styles.mainControlsContainer}>
          <Tooltip
            content={
              onPreviousTracklistTooltipMessage || (
                <FormattedMessage
                  {...controlsTooltipsMessages.PREVIOUS_TRACKLIST}
                />
              )
            }
            disabled={!onPreviousTracklist}
            touch={false}
          >
            <Button
              icon={<BackOne />}
              className={HIDE_SMALL_CLASSNAME}
              disabled={!onPreviousTracklist || isLoading}
              onClick={onPreviousTracklist}
            />
          </Tooltip>

          <Tooltip
            content={
              onPreviousTrackTooltipMessage || (
                <FormattedMessage
                  {...controlsTooltipsMessages.PREVIOUS_TRACK}
                />
              )
            }
            disabled={!onPreviousTrack}
            touch={false}
          >
            <Button
              className={HIDE_SMALL_CLASSNAME}
              icon={<GoStart />}
              disabled={!onPreviousTrack || isLoading}
              onClick={onPreviousTrack}
            />
          </Tooltip>

          <Tooltip
            touch={false}
            content={
              <FormattedMessage
                {...controlsTooltipsMessages[isAudioPlaying ? 'PAUSE' : 'PLAY']}
              />
            }
          >
            <Button
              icon={isAudioPlaying ? <Pause /> : <PlayOne />}
              className={HIDE_SMALL_CLASSNAME}
              onClick={onToggleAudioPlay}
              outline
              disabled={isLoading}
            />
          </Tooltip>

          <Tooltip
            content={
              onNextTrackTooltipMessage || (
                <FormattedMessage {...controlsTooltipsMessages.NEXT_TRACK} />
              )
            }
            touch={false}
            disabled={!onNextTrack}
          >
            <Button
              className={HIDE_SMALL_CLASSNAME}
              onClick={onNextTrack}
              icon={<GoEnd />}
              disabled={!onNextTrack || isLoading}
            />
          </Tooltip>

          <Tooltip
            content={
              onNextTracklistTooltipMessage || (
                <FormattedMessage
                  {...controlsTooltipsMessages.NEXT_TRACKLIST}
                />
              )
            }
            touch={false}
            disabled={!onNextTracklist}
          >
            <Button
              className={HIDE_SMALL_CLASSNAME}
              icon={<GoAhead />}
              onClick={onNextTracklist}
              disabled={!onNextTracklist || isLoading}
            />
          </Tooltip>
        </div>
        <div
          css={styles.volumeControlsContainer}
          className={HIDE_SMALL_CLASSNAME}
        >
          <Button
            onClick={onClickMute}
            css={styles.muteButton}
            icon={
              isMuted ? (
                <VolumeMute
                  size={18}
                  fill={[colors.primary[500], colors.primary[50]]}
                />
              ) : (
                <VolumeNotice
                  size={18}
                  fill={[colors.primary[500], colors.primary[50]]}
                />
              )
            }
            type="button"
          />

          <div
            css={styles.sliderContainer}
            className={cn({ isDrawerOpen })}
          >
            <Slider
              min={MIN_AUDIO_VOLUME}
              max={MAX_AUDIO_VOLUME}
              value={audioVolume}
              onChange={onChangeAudioVolume}
            />
          </div>
        </div>
      </div>

      <Button
        className={DISPLAY_SMALL_CLASSNAME}
        icon={<CloseSmall />}
        onClick={onRequestCloseProxy}
        disabled={isLoading}
      />

      <div css={styles.crossDrag}>
        <Tooltip
          touch={false}
          content={
            <FormattedMessage
              {...controlsTooltipsMessages.CLOSE_AUDIO_PLAYER}
            />
          }
        >
          <Button
            className={`${HIDE_SMALL_CLASSNAME} ${HIDE_DRAWER_CLASSNAME}`}
            icon={<CloseSmall />}
            onClick={onRequestCloseProxy}
            disabled={isLoading}
          />
        </Tooltip>

        <Drag
          fill={[colors.gray[500], colors.gray[500]]}
          css={styles.dragButton}
          size={18}
          className={`${HIDE_SMALL_CLASSNAME} ${DRAGGABLE_CLASSNAME} ${HIDE_DRAWER_CLASSNAME}`}
        />
      </div>
    </div>
  );
};

export default memo(AudioControlsBase);
