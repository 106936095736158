import { memo, useCallback } from 'react';
import { useTheme } from '@emotion/react';
import { Drag } from '@icon-park/react';
import cn from 'classnames';
import { noop } from 'lodash';

import { ICON_SIZES } from '../../general/icon/constants';
import { Typography } from '../../general/typography/Typography';
import { Badge } from '../badge/Badge';
import * as styles from './Tab.styles';
import { type TabKey, type TabShape } from './types';

const TAB_DRAGGABLE_CLASSNAME = 'draggableTab';

export type TabProps<TTabKey extends TabKey = TabKey> = {
  tab: TabShape<TTabKey>;
  isActive?: boolean;
  onClick?: (key: TTabKey) => void;
  vertical?: boolean;
  isDraggable?: boolean;
};

export const TabBase = <TTabKey extends TabKey = TabKey>({
  tab: { key, label, badge, disabled = false },
  isActive = false,
  onClick = noop,
  vertical = false,
  isDraggable = false,
}: TabProps<TTabKey>) => {
  const theme = useTheme();

  const onClickProxy = useCallback(() => onClick(key), [key, onClick]);

  const hasBadge = badge !== null && badge !== undefined;

  return (
    <div
      css={styles.container}
      className={cn({
        isActive,
        isVertical: vertical,
      })}
    >
      <button
        type="button"
        onClick={onClickProxy}
        css={styles.tab}
        className={cn({
          isVertical: vertical,
          isDisabled: disabled,
          isDraggable,
          isActive,
        })}
        disabled={disabled}
      >
        {hasBadge && (
          <Badge
            variant={isActive ? Badge.VARIANTS.PRIMARY : Badge.VARIANTS.NEUTRAL}
          >
            {badge}
          </Badge>
        )}

        <Typography
          variant={Typography.VARIANTS.BUTTON_MEDIUM_REGULAR}
          css={styles.label}
          className={cn({
            hasBadge,
          })}
        >
          {label}
        </Typography>
      </button>

      {isDraggable && (
        <div
          css={styles.drag}
          className={cn({
            isVertical: vertical,
            TAB_DRAGGABLE_CLASSNAME,
          })}
        >
          <Drag
            size={ICON_SIZES.MEDIUM}
            fill={[theme.colors.gray[300]]}
          />
        </div>
      )}
    </div>
  );
};

TabBase.displayName = 'Tab';

export const Tab = memo(TabBase) as unknown as typeof TabBase & {
  DRAGGABLE_CLASSNAME?: string;
};

Tab.DRAGGABLE_CLASSNAME = TAB_DRAGGABLE_CLASSNAME;
