import {
  type ForwardedRef,
  forwardRef,
  memo,
  type ReactNode,
  useContext,
} from 'react';
import { type To } from 'react-router-dom';
import cn from 'classnames';

import { Typography } from '../../../general/typography/Typography';
import { Link } from '../../link/Link';
import { TableOfContentsSectionContext } from '../section/TableOfContentsSection.context';
import * as styles from './TableOfContentsSubSectionItem.styles';

export type TableOfContentsSubSectionItemProps = {
  /** Text label. Hidden when collapsed. */
  label: ReactNode;
  /** Link href. */
  href?: To;
  /** Is the TableOfContents sub section item currently selected. */
  isSelected?: boolean;
};

export const TableOfContentsSubSectionItemBase = forwardRef(
  (
    {
      label,
      href = null,
      isSelected = false,
    }: TableOfContentsSubSectionItemProps,
    ref: ForwardedRef<HTMLLIElement>,
  ) => {
    const { variant } = useContext(TableOfContentsSectionContext);

    return (
      <li ref={ref}>
        <Link
          to={href}
          isDisabled={!href}
          className={cn(variant, { isSelected })}
          css={styles.link}
        >
          <div css={styles.label}>
            <Typography variant={Typography.VARIANTS.BODY_SMALL_REGULAR}>
              {label}
            </Typography>
          </div>
        </Link>
      </li>
    );
  },
);

TableOfContentsSubSectionItemBase.displayName = 'TableOfContentsSubSectionItem';

export const TableOfContentsSubSectionItem = memo(
  TableOfContentsSubSectionItemBase,
);
