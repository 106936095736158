export const BlueishEgg = () => (
  <svg
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1771 2.12911e-06C18.8001 3.28711e-06 24 9.13563 24 17.9662C24 26.7967 18.8001 32 12.1771 32C5.55413 32 8.53917e-06 26.7967 1.00831e-05 17.9662C1.16271e-05 9.13562 5.55414 9.71114e-07 12.1771 2.12911e-06Z"
      fill="#66ADCB"
    />
    <mask
      id="mask0_105_12636"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="32"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1771 2.12911e-06C18.8001 3.28711e-06 24 9.13563 24 17.9662C24 26.7967 18.8001 32 12.1771 32C5.55413 32 8.53917e-06 26.7967 1.00831e-05 17.9662C1.16271e-05 9.13562 5.55414 9.71114e-07 12.1771 2.12911e-06Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_105_12636)">
      <line
        y1="-0.86"
        x2="43.6233"
        y2="-0.86"
        transform="matrix(0.25659 0.96652 0.965321 -0.261064 13.8193 -7.86011)"
        stroke="#3391B9"
        strokeWidth="1.72"
      />
      <line
        y1="-0.86"
        x2="43.6233"
        y2="-0.86"
        transform="matrix(0.25659 0.96652 0.965321 -0.261064 6.73898 -5.94531)"
        stroke="#3391B9"
        strokeWidth="1.72"
      />
      <line
        y1="-0.86"
        x2="43.6233"
        y2="-0.86"
        transform="matrix(0.25659 0.96652 0.965321 -0.261064 10.2791 -6.90271)"
        stroke="#3391B9"
        strokeWidth="1.72"
      />
      <line
        y1="-0.86"
        x2="43.6233"
        y2="-0.86"
        transform="matrix(0.25659 0.96652 0.965321 -0.261064 3.19882 -4.98792)"
        stroke="#3391B9"
        strokeWidth="1.72"
      />
      <line
        y1="-0.86"
        x2="43.6233"
        y2="-0.86"
        transform="matrix(0.25659 0.96652 0.965321 -0.261064 -0.341362 -4.03052)"
        stroke="#3391B9"
        strokeWidth="1.72"
      />
    </g>
  </svg>
);
