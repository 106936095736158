import {
  type HTMLProps,
  memo,
  type PropsWithChildren,
  type ReactElement,
  useCallback,
} from 'react';
import cn from 'classnames';
import { noop } from 'lodash';
import { motion, useAnimate } from 'motion/react';

import { AlienEgg } from './assets/AlienEgg';
import { BlueishEgg } from './assets/BlueishEgg';
import { BrownishEgg } from './assets/BrownishEgg';
import { EggEgg } from './assets/EggEgg';
import { GoldenEgg } from './assets/GoldenEgg';
import { GreenishEgg } from './assets/GreenishEgg';
import { OrangeishEgg } from './assets/OrangeishEgg';
import { PinkishEgg } from './assets/PinkishEgg';
import { PurpleishEgg } from './assets/PurpleishEgg';
import { RedishEgg } from './assets/RedishEgg';
import * as styles from './EasterEggWrapper.styles';

type EggTypes =
  | 'redish'
  | 'egg'
  | 'golden'
  | 'blueish'
  | 'greenish'
  | 'pinkish'
  | 'orangeish'
  | 'brownish'
  | 'purpleish'
  | 'alien';

type EasterEggProps = {
  type?: EggTypes;
  withInteraction?: boolean;
  orientation?: 'left' | 'right';
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  onClickEgg?: () => void;
  isHidden?: boolean;
} & HTMLProps<HTMLDivElement> &
  PropsWithChildren;

const EASTER_EGG_MAP: Record<EggTypes, ReactElement> = {
  redish: <RedishEgg />,
  egg: <EggEgg />,
  golden: <GoldenEgg />,
  blueish: <BlueishEgg />,
  greenish: <GreenishEgg />,
  pinkish: <PinkishEgg />,
  orangeish: <OrangeishEgg />,
  brownish: <BrownishEgg />,
  purpleish: <PurpleishEgg />,
  alien: <AlienEgg />,
};

const EasterEggWrapperBase = ({
  type = 'egg',
  withInteraction = false,
  children,
  orientation = 'left',
  position = 'top-left',
  onClickEgg = noop,
  isHidden = false,
  ...props
}: EasterEggProps) => {
  const [scope, animate] = useAnimate();

  const onClickEggProxy = useCallback(() => {
    if (withInteraction) {
      animate(
        scope.current,
        {
          opacity: 0,
          scale: 0,
        },
        { duration: 0.5, ease: 'easeInOut' },
      );
    }

    onClickEgg();
  }, [withInteraction, onClickEgg]);

  const onMouseEnterEgg = useCallback(
    withInteraction
      ? () => {
          animate(
            scope.current,
            {
              translateY: -20,
            },
            { duration: 1 },
          );
        }
      : noop,
    [withInteraction],
  );

  const onMouseLeaveEgg = useCallback(
    withInteraction
      ? () => {
          animate(
            scope.current,
            {
              translateY: 0,
            },
            { duration: 1 },
          );
        }
      : noop,
    [withInteraction],
  );

  return (
    <div
      css={styles.wrapper}
      {...props}
    >
      <motion.button
        ref={scope}
        onClick={onClickEggProxy}
        onMouseEnter={onMouseEnterEgg}
        onMouseLeave={onMouseLeaveEgg}
        css={styles.eggContainer}
        className={cn({ isHidden }, `orientation-${orientation}`, position)}
      >
        {EASTER_EGG_MAP[type]}
      </motion.button>

      <div css={styles.childContainer}>{children}</div>
    </div>
  );
};

EasterEggWrapperBase.displayName = 'EasterEggWrapper';

export const EasterEggWrapper = memo(EasterEggWrapperBase);
