export const EggEgg = () => (
  <svg
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.5 17.9662C23.5 13.6409 22.2244 9.24221 20.1488 5.93758C18.0653 2.62045 15.2435 0.500003 12.1771 0.500002C9.10589 0.500002 6.19498 2.62607 4.02335 5.94537C1.86039 9.25142 0.500003 13.6478 0.500002 17.9662C0.500002 22.2751 1.85348 25.6566 3.97258 27.9557C6.09131 30.2543 9.00078 31.5 12.1771 31.5C15.3511 31.5 18.1685 30.2564 20.1977 27.9632C22.2298 25.6668 23.5 22.2839 23.5 17.9662Z"
      fill="#FFFDF9"
      stroke="#F4BA9B"
    />
    <mask
      id="mask0_105_12634"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="32"
    >
      <path
        d="M23.5 17.9662C23.5 13.6409 22.2244 9.24221 20.1488 5.93758C18.0653 2.62045 15.2435 0.500003 12.1771 0.500002C9.10589 0.500002 6.19498 2.62607 4.02335 5.94537C1.86039 9.25142 0.500003 13.6478 0.500002 17.9662C0.500002 22.2751 1.85348 25.6566 3.97258 27.9557C6.09131 30.2543 9.00078 31.5 12.1771 31.5C15.3511 31.5 18.1685 30.2564 20.1977 27.9632C22.2298 25.6668 23.5 22.2839 23.5 17.9662Z"
        fill="white"
        stroke="white"
      />
    </mask>
    <g mask="url(#mask0_105_12634)">
      <ellipse
        cx="7"
        cy="7.15438"
        rx="7"
        ry="7.15438"
        transform="matrix(-1 -1.74846e-07 -1.74846e-07 1 19.0038 14.625)"
        fill="#F5BA33"
      />
    </g>
  </svg>
);
