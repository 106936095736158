import { css, type Theme } from '@emotion/react';

export const container = (backgroundColor?: string) => (theme: Theme) => css`
  min-height: 1px;
  width: 100%;
  position: relative;
  background-color: ${backgroundColor || theme.colors.gray[50]};
  border-radius: 10px;
`;

export const incrementTag =
  (normalizedProgress: number) => (theme: Theme) => css`
    position: absolute;
    left: ${normalizedProgress * 100}%;
    top: -30px;
    font-size: 14px;
    color: ${theme.colors.success[400]};

    animation: moveRightFadeOut 1.5s ease-out;

    @keyframes moveRightFadeOut {
      0% {
        opacity: 1;
        transform: translateX(0);
      }
      100% {
        opacity: 0;
        transform: translateX(10px);
      }
    }
  `;

export const progressBar =
  (normalizedProgress: number, progressColor?: string) => (theme: Theme) => css`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;

    width: ${normalizedProgress * 100}%;

    background-color: ${progressColor || theme.colors.primary[500]};

    transition: ${theme.transitions.default('width')};

    border-radius: 10px;
  `;
