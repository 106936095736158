export enum VARIANTS {
  PRIMARY = 'PRIMARY',
  WARNING = 'WARNING',
  DANGER = 'DANGER',
  SUCCESS = 'SUCCESS',
  NEUTRAL = 'NEUTRAL',
  /** Add more variants here as needed... */
}

export const CARD_PROGRESS_HEIGHT_OFFSET = 3;
