import { memo } from 'react';
import ReactPlayer from 'react-player';

import { LESSON_RESOURCE_TYPES } from '@eversity/domain/constants';
import { type LessonResource, type Upload } from '@eversity/types/domain';
import { FileDownload } from '@eversity/ui/design-system';
import { ComponentSwitch } from '@eversity/ui/utils';

import * as styles from './ResourceDisplay.styles';

type ResourceDisplayProps = {
  resource: LessonResource;
  onDownloadFile: (file: Upload) => void;
};

export const ResourceDisplayBase = ({
  resource,
  onDownloadFile,
}: ResourceDisplayProps) => {
  return (
    <ComponentSwitch value={resource.type}>
      <ComponentSwitch.Item value={LESSON_RESOURCE_TYPES.VIDEO}>
        <ReactPlayer
          url={resource.href}
          width="auto"
          css={styles.videoPlayer}
          controls
        />
      </ComponentSwitch.Item>

      <ComponentSwitch.Item value={LESSON_RESOURCE_TYPES.FILE}>
        <FileDownload
          file={resource.upload}
          onDownload={onDownloadFile}
        />
      </ComponentSwitch.Item>
    </ComponentSwitch>
  );
};

ResourceDisplayBase.displayName = 'ResourceDisplay';

export const ResourceDisplay = memo(ResourceDisplayBase);
