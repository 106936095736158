import { Fragment, type ReactNode } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import { css, Global, useTheme } from '@emotion/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import {
  DesignSystemProvider,
  TYPOGRAPHY_VARIANTS,
} from '@eversity/ui/design-system';
import { ErrorView } from '@eversity/ui/domain';
import { IntlProvider, SentryErrorBoundary } from '@eversity/ui/utils';

import AppRouter from '../AppRouter';
import { queryClient } from '../client';
import { LocaleProvider } from '../generic/intl/locale-provider/LocaleProvider';
import { AuthProvider } from './auth-context';

const GlobalStyle = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();

  return (
    <Fragment>
      <Global
        styles={css`
          #sentry-feedback {
            --font-family: ${theme.typography[
              TYPOGRAPHY_VARIANTS.BODY_SMALL_REGULAR
            ].fontFamily};
            --font-size: ${theme.typography[
              TYPOGRAPHY_VARIANTS.BODY_SMALL_REGULAR
            ].fontSize}px;
            --page-margin: 18px 26px;
          }
        `}
      />
      {children}
    </Fragment>
  );
};

const AppProviders = () => (
  <HelmetProvider>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools buttonPosition="bottom-right" />
      <LocaleProvider>
        <IntlProvider>
          <DesignSystemProvider>
            <Router>
              <SentryErrorBoundary fallback={<ErrorView />}>
                <AuthProvider>
                  <GlobalStyle>
                    <AppRouter />
                  </GlobalStyle>
                </AuthProvider>
              </SentryErrorBoundary>
            </Router>
          </DesignSystemProvider>
        </IntlProvider>
      </LocaleProvider>
    </QueryClientProvider>
  </HelmetProvider>
);

export default AppProviders;
