import { css, type Theme } from '@emotion/react';

import { LESSON_RESOURCE_PICTURE_ASPECT_RATIO } from '@eversity/domain/constants';

import { SIZES } from './constants';

export const container = (pictureHref?: string) => (theme: Theme) => css`
  &.${SIZES.SMALL} {
    width: 150px;
  }

  &.${SIZES.MEDIUM} {
    width: 235px;
  }

  &.${SIZES.LARGE} {
    width: 400px;
  }

  aspect-ratio: ${LESSON_RESOURCE_PICTURE_ASPECT_RATIO};
  background-color: ${theme.colors.primary[15]};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${theme.colors.gray[100]};
  border-radius: 4px;
  position: relative;

  ${pictureHref &&
  css`
    background-image: url(${pictureHref});
    background-position: center;
    background-size: cover;
  `}
`;
