export const PurpleishEgg = () => (
  <svg
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.823 -1.06454e-06C5.19988 -1.64356e-06 1.99887e-06 9.13563 1.22688e-06 17.9662C4.54885e-07 26.7967 5.19988 32 11.823 32C18.4457 32 24 26.7967 24 17.9662C24 9.13563 18.4457 -4.85574e-07 11.823 -1.06454e-06Z"
      fill="#514689"
    />
    <mask
      id="mask0_105_12641"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="32"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.823 -1.06454e-06C5.19988 -1.64356e-06 1.99887e-06 9.13563 1.22688e-06 17.9662C4.54885e-07 26.7967 5.19988 32 11.823 32C18.4457 32 24 26.7967 24 17.9662C24 9.13563 18.4457 -4.85574e-07 11.823 -1.06454e-06Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_105_12641)">
      <path
        d="M19.1752 17.7684L17.0671 22.6729L19.1752 27.5953L21.2654 22.6729L19.1752 17.7684Z"
        fill="#3391B9"
      />
      <path
        d="M4.99828 17.7684L2.89023 22.6729L4.99827 27.5953L7.08846 22.6729L4.99828 17.7684Z"
        fill="#3391B9"
      />
      <path
        d="M19.1752 5.50378L17.0671 10.4082L19.1752 15.3307L21.2654 10.4082L19.1752 5.50378Z"
        fill="#3391B9"
      />
      <path
        d="M12.0867 17.7684L9.9787 22.6729L12.0867 27.5953L14.1769 22.6729L12.0867 17.7684Z"
        fill="#3391B9"
      />
      <path
        d="M4.99828 5.50378L2.89023 10.4082L4.99827 15.3307L7.08846 10.4082L4.99828 5.50378Z"
        fill="#3391B9"
      />
      <path
        d="M12.0867 5.47351L9.9787 10.3779L12.0867 15.3004L14.1769 10.3779L12.0867 5.47351Z"
        fill="#3391B9"
      />
    </g>
  </svg>
);
