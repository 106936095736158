import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { noop } from 'lodash';

import {
  Button,
  Dialog,
  HtmlFormatter,
  LinkButton,
  Typography,
} from '@eversity/ui/design-system';

import messages from './FeedbackTypeformDialogIntro.messages';
import * as styles from './FeedbackTypeformDialogIntro.styles';
import hamster from './hamster.png';

export type FeedbackTypeformDialogIntroProps = {
  onShowTypeform?: () => void;
  onAskLater?: () => void;
  onNeverAskAgain?: () => void;
};

export const FeedbackTypeformDialogIntroBase = ({
  onShowTypeform = noop,
  onAskLater = noop,
  onNeverAskAgain = null,
}: FeedbackTypeformDialogIntroProps) => (
  <div css={styles.container}>
    <Dialog.Body css={styles.dialogBody}>
      <div css={styles.dialogBodyContent}>
        <HtmlFormatter>
          <FormattedMessage
            {...messages.PROMPT}
            values={{
              h4: (chunks) => (
                <Typography variant={Typography.VARIANTS.HEADING_4}>
                  {chunks}
                </Typography>
              ),
              p: (chunks) => (
                <Typography
                  variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}
                  element="p"
                >
                  {chunks}
                </Typography>
              ),
              b: (chunks) => (
                <Typography variant={Typography.VARIANTS.BODY_MEDIUM_BOLD}>
                  {chunks}
                </Typography>
              ),
            }}
          />
        </HtmlFormatter>

        <div css={styles.dialogBodyButtons}>
          <Button
            onClick={onShowTypeform}
            size={Button.SIZES.LARGE}
            variant={Button.VARIANTS.PRIMARY}
          >
            <FormattedMessage {...messages.ANSWER} />
          </Button>

          <Button
            onClick={onAskLater}
            size={Button.SIZES.SMALL}
            variant={Button.VARIANTS.NEUTRAL}
            outline
          >
            <FormattedMessage {...messages.ASK_LATER} />
          </Button>
        </div>
      </div>

      {onNeverAskAgain && (
        <div>
          <LinkButton onClick={onNeverAskAgain}>
            <FormattedMessage {...messages.NEVER_ASK_AGAIN} />
          </LinkButton>
        </div>
      )}
    </Dialog.Body>

    <div css={styles.hamsterImage(hamster)} />
  </div>
);

FeedbackTypeformDialogIntroBase.displayName = 'FeedbackTypeformDialogIntro';

export const FeedbackTypeformDialogIntro = memo(
  FeedbackTypeformDialogIntroBase,
);
