import { STREAK_DAY_TYPES } from '@eversity/domain/constants';
import dayjs from '@eversity/services/dayjs';

/**
 * Compute the connection day streak types for a given week.
 *
 * @param {dayjs.Dayjs} currentDay - The current day from which streaks are calculated.
 * @param {string[]} connectionDays - An array of connection days in ISO string format.
 * @returns {Record<string, STREAK_DAY_TYPES>} An object where keys are dates and values are their corresponding streak types.
 */
export const getStreaksFromConnectionDays = (
  currentDay: dayjs.Dayjs,
  connectionDays: string[],
): Record<string, STREAK_DAY_TYPES> => {
  const streakTypes: Record<string, STREAK_DAY_TYPES> = {};

  const startOfWeek = dayjs(currentDay).startOf('week').weekday(0);
  const endOfWeek = currentDay.endOf('week');
  const today = dayjs();

  for (
    let date = endOfWeek;
    date >= startOfWeek;
    date = date.subtract(1, 'day')
  ) {
    const stringifiedDate = date.toISOString();
    const wasUserConnectedOnCurrentDay = connectionDays.find((cd) =>
      dayjs(cd).isSame(date, 'day'),
    );

    const isToday = date.isSame(today, 'day');
    const isWeekEnd = date.day() === 6 || date.day() === 0;

    if (wasUserConnectedOnCurrentDay && !isWeekEnd) {
      streakTypes[stringifiedDate] = isToday
        ? STREAK_DAY_TYPES.CURRENT_DAY
        : STREAK_DAY_TYPES.CONNECTED;
    } else {
      streakTypes[stringifiedDate] = isWeekEnd
        ? STREAK_DAY_TYPES.WEEK_END
        : STREAK_DAY_TYPES.NOT_CONNECTED;
    }
  }

  return streakTypes;
};

/**
 * Compute user daily connection streak.
 * @param {number} lastConnectionStreak - Daily connection streak value on last session.
 * @param {string} lastActiveTimestamp - Timestamp from last user session.
 * @param {number} daysSinceLastSession - Number of days since last user session.
 * @returns {number} new user connection streak.
 */
export const computeNewDailyConnectionStreak = ({
  lastConnectionStreak,
  lastActiveTimestamp,
  daysSinceLastSession,
}: {
  lastConnectionStreak: number;
  lastActiveTimestamp: string;
  daysSinceLastSession: number;
}): number => {
  let newDailyConnectionStreak = 1;

  // Day of the last session
  const lastSessionDay = dayjs(lastActiveTimestamp).day();

  const isLastSessionOnFridayOrSaturday =
    lastSessionDay === 5 || lastSessionDay === 6;

  const todayDay = dayjs().day();
  const isMonday = todayDay === 1;
  // Sunday equals 0
  const isWeekEnd = todayDay === 6 || todayDay === 0;

  /**
   * Cases we keep last streak :
   * - last session was today
   * - current session is on week-end and last session was on friday or on saturday
   */
  if (
    daysSinceLastSession === 0 ||
    (isWeekEnd && isLastSessionOnFridayOrSaturday)
  ) {
    newDailyConnectionStreak = lastConnectionStreak;
    /**
     * Cases we increment streak :
     * - last session was a day ago and we are not on week-end
     * - we are monday and last session was on friday, on saturday or on sunday
     */
  } else if (
    (daysSinceLastSession === 1 && !isWeekEnd) ||
    (isMonday && [5, 6, 0].includes(lastSessionDay))
  ) {
    newDailyConnectionStreak = lastConnectionStreak + 1;
  }

  return newDailyConnectionStreak;
};
