import { cloneElement, type ReactElement } from 'react';

import { TEACHING_UNIT_ORIGIN } from '@eversity/domain/constants';

import { CourseraLogo } from './logos/CourseraLogo';

type EXTERNAL_ORIGIN = Exclude<TEACHING_UNIT_ORIGIN, 'DIDASKILL'>;

export const EXTERNAL_LOGOS_MAP: Record<EXTERNAL_ORIGIN, ReactElement> = {
  [TEACHING_UNIT_ORIGIN.COURSERA]: <CourseraLogo />,
};

export type EXTERNAL_LOGOS_LOCATION = 'lesson-thumbnail' | 'lesson-information';

export const EXTERNAL_LOGOS_LOCATION_PROPS_MAP: Record<
  EXTERNAL_ORIGIN,
  {
    location: Record<EXTERNAL_LOGOS_LOCATION, { color: string; size: number }>;
  }
> = {
  [TEACHING_UNIT_ORIGIN.COURSERA]: {
    location: {
      'lesson-thumbnail': { color: 'white', size: 10 },
      'lesson-information': { color: '#0056D2', size: 14 },
    },
  },
};
export const getLogoForOrigin = (
  origin: EXTERNAL_ORIGIN,
  location: EXTERNAL_LOGOS_LOCATION,
) =>
  cloneElement(
    EXTERNAL_LOGOS_MAP[origin],
    EXTERNAL_LOGOS_LOCATION_PROPS_MAP[origin].location[location],
  );
