import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@emotion/react';
import { Attention } from '@icon-park/react';
import {
  type QueryObserverResult,
  type RefetchOptions,
} from '@tanstack/react-query';

import { Button, Card, Typography } from '@eversity/ui/design-system';
import { globalMessages } from '@eversity/ui/intl';

import messages from './StreaksCalendarError.messages';
import * as styles from './StreaksCalendarError.styles';

type StreaksCalendarErrorProps = {
  refetch: (options?: RefetchOptions) => Promise<
    QueryObserverResult<{
      streak: number;
      connectionDays: string[];
    }>
  >;
  onClose: () => void;
};

const StreaksCalendarErrorBase = ({
  refetch,
  onClose,
}: StreaksCalendarErrorProps) => {
  const theme = useTheme();
  return (
    <Card css={styles.cardContainer}>
      <Card.Body css={styles.cardTitle}>
        <Attention
          size={20}
          fill={[theme.colors.warning[500], theme.colors.gray[0]]}
        />

        <div css={styles.cardContent}>
          <Typography variant={Typography.VARIANTS.HEADING_4}>
            <FormattedMessage {...messages.TITLE} />
          </Typography>

          <Typography variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}>
            <FormattedMessage
              {...messages.BODY}
              values={{
                b: (chunks) => (
                  <Typography variant={Typography.VARIANTS.BODY_MEDIUM_BOLD}>
                    {chunks}
                  </Typography>
                ),
              }}
            />
          </Typography>
        </div>
      </Card.Body>

      <Card.Footer
        variant={Card.VARIANTS.WARNING}
        css={styles.cardFooter}
      >
        <Button onClick={onClose}>
          <FormattedMessage {...globalMessages.IGNORE} />
        </Button>

        <Button
          variant={Button.VARIANTS.WARNING}
          onClick={() => refetch()}
        >
          <FormattedMessage {...globalMessages.RETRY} />
        </Button>
      </Card.Footer>
    </Card>
  );
};

StreaksCalendarErrorBase.displayName = 'StreaksCalendarError';

export const StreaksCalendarError = memo(StreaksCalendarErrorBase);
