import dayjs from '@eversity/services/dayjs';

import { getBrowserLocale, setHtmlLangAttribute } from '../intl/browser';
import { type makeLocalStorageAdapter } from '../storage';

let localStorageAdapter: ReturnType<typeof makeLocalStorageAdapter>;

export const availableLocales = ['fr', 'en'];

export const DEFAULT_APP_LOCALE = 'fr';

/**
 * Initializes the locale adapter.
 *
 * @param makeAdapter - The function to make the adapter.
 * @param appName - The name of the app.
 */
export const bootstrapLocaleAdapter = (
  makeAdapter: typeof makeLocalStorageAdapter,
  appName: string,
) => {
  localStorageAdapter = makeAdapter(`${appName}:locale`);
};

/**
 * Looks for the presence of the locale in the storage.
 *
 * @returns The locale if found, undefined otherwise.
 */
export const getStoredLocale = () => localStorageAdapter.get();

/**
 * Stores locale in the storage.
 *
 * @param locale - The locale to store.
 */
export const storeLocale = (locale: string) => {
  localStorageAdapter.set(locale);
};

/**
 * Gets the default locale for the app.
 *
 * @param availableLocales - The available locales for the app.
 * @param defaultLocale - The default locale for the app.
 * @returns - The locale to use for the app.
 */
export const getDefaultLocale = () => {
  const storedLocale = getStoredLocale();

  if (storedLocale) {
    return storedLocale;
  }

  const browserLocale = getBrowserLocale();

  if (availableLocales.includes(browserLocale)) {
    return browserLocale;
  }

  return DEFAULT_APP_LOCALE;
};

/**
 * Sets the locale for the app.
 *
 * @param locale - The locale to set.
 */
export const setAppLocale = (locale: string) => {
  dayjs.locale(locale);
  setHtmlLangAttribute(locale);
  storeLocale(locale);
};
