import {
  Children,
  Fragment,
  type HTMLProps,
  memo,
  type MemoExoticComponent,
  type PropsWithChildren,
} from 'react';

import * as styles from './Navbar.styles';

type NavbarComponentProps = HTMLProps<HTMLDivElement> & PropsWithChildren;

type NavbarSectionProps = NavbarComponentProps & {
  spacing?: 'small' | 'medium' | 'large';
};

const NavbarBase = ({ children, ...props }: NavbarComponentProps) => {
  return (
    <div
      css={styles.navbarContainer}
      {...props}
    >
      {children}
    </div>
  );
};

const NavbarGroup = memo(({ children, ...props }: NavbarComponentProps) => {
  const childrenArray = Children.toArray(children);

  return (
    <div
      css={styles.navbarGroupContainer}
      {...props}
    >
      {childrenArray.map((child, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={`navbar-group-item-${index}`}>
          {child}

          {index !== childrenArray.length - 1 && <div css={styles.separator} />}
        </Fragment>
      ))}
    </div>
  );
});

const NavbarSection = memo(
  ({ children, spacing = 'medium', ...props }: NavbarSectionProps) => {
    return (
      <div
        css={styles.navbarSectionContainer}
        className={`spacing-${spacing}`}
        {...props}
      >
        {children}
      </div>
    );
  },
);

const NavbarItem = memo(({ children, ...props }: NavbarComponentProps) => (
  <div {...props}>{children}</div>
));

type CompoundedComponent = MemoExoticComponent<typeof NavbarBase> & {
  Group: typeof NavbarGroup;
  Section: typeof NavbarSection;
  Item: typeof NavbarItem;
};

const Navbar = memo(NavbarBase) as CompoundedComponent;

Navbar.Group = NavbarGroup;
Navbar.Section = NavbarSection;
Navbar.Item = NavbarItem;

export { Navbar };
