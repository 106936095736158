import { Fragment, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@emotion/react';
import { CheckOne, ImageFiles } from '@icon-park/react';
import cn from 'classnames';

import { TEACHING_UNIT_ORIGIN } from '@eversity/domain/constants';
import {
  type TeachingUnitLessonViewStudent,
  type Upload,
  type UploadWithHref,
} from '@eversity/types/domain';
import { Tag, Typography } from '@eversity/ui/design-system';
import { lessonsMessages, sequencesMessages } from '@eversity/ui/intl';

import { getLogoForOrigin } from '../../../../assets/external-logos/constants';
import messages from './LessonCard.messages';
import * as styles from './LessonCard.styles';

export type LessonCardProps = {
  lesson: TeachingUnitLessonViewStudent['lesson'];
  origin?: TeachingUnitLessonViewStudent['origin'];
  thumbnail?: Upload | UploadWithHref;
  completionPercentage?: number;
  isDisabled?: boolean;
};

export const LessonCardBase = ({
  lesson,
  origin,
  thumbnail = null,
  completionPercentage = null,
  isDisabled = false,
}: LessonCardProps) => {
  const theme = useTheme();
  const isCompleted = completionPercentage === 100;

  const isLessonExternal = origin !== TEACHING_UNIT_ORIGIN.DIDASKILL;

  return (
    <div css={styles.container}>
      <div
        css={styles.card}
        className={cn({
          isDisabled,
          isExternal: isLessonExternal,
        })}
      >
        {thumbnail?.href ? (
          <div
            css={styles.progressImage(
              theme,
              thumbnail?.href,
              completionPercentage,
            )}
          />
        ) : (
          <div css={styles.placeholderImage}>
            <ImageFiles
              size={48}
              theme="outline"
              fill={theme.colors.primary[500]}
              strokeWidth={1}
            />
          </div>
        )}

        {isLessonExternal && (
          <div css={styles.logoContainer}>
            {getLogoForOrigin(origin, 'lesson-thumbnail')}
          </div>
        )}

        {completionPercentage > 0 && completionPercentage < 100 && (
          <Fragment>
            <Typography
              variant={Typography.VARIANTS.HEADING_4}
              element="span"
              css={styles.progressTextBackground}
            >
              <FormattedMessage
                {...messages.PROGRESS}
                values={{
                  progress: (
                    <Typography
                      variant={Typography.VARIANTS.HEADING_1}
                      element="span"
                    >
                      {completionPercentage}
                    </Typography>
                  ),
                }}
              />
            </Typography>

            <Typography
              variant={Typography.VARIANTS.HEADING_4}
              element="span"
              css={styles.progressText}
            >
              <FormattedMessage
                {...messages.PROGRESS}
                values={{
                  progress: (
                    <Typography
                      variant={Typography.VARIANTS.HEADING_1}
                      element="span"
                    >
                      {completionPercentage}
                    </Typography>
                  ),
                }}
              />
            </Typography>
          </Fragment>
        )}
      </div>

      <div
        css={styles.subText}
        className={cn({ isDisabled })}
      >
        {isCompleted && (
          <CheckOne
            size={30}
            fill={[theme.colors.success[500], theme.colors.success[25]]}
          />
        )}

        <div>
          <div css={styles.tags}>
            <Tag label={<FormattedMessage {...lessonsMessages.LESSON} />} />
          </div>

          <Typography
            variant={Typography.VARIANTS.BODY_MEDIUM_BOLD}
            element="span"
          >
            {lesson.title}
          </Typography>

          <div css={styles.info}>
            {!!lesson?.sequencesCount && (
              <Typography
                variant={Typography.VARIANTS.BODY_SMALL_REGULAR}
                element="span"
              >
                <FormattedMessage
                  {...(isLessonExternal
                    ? sequencesMessages.MODULES_COUNT
                    : sequencesMessages.SEQUENCES_COUNT)}
                  values={{ count: lesson.sequencesCount }}
                />
              </Typography>
            )}

            {/* When estimatedCompletionTime is implemented on lessons. */}
            {/* !!lesson?.estimatedCompletionTime && (
              <div css={styles.completionTime}>
                <Typography
                  variant={Typography.VARIANTS.BODY_SMALL_REGULAR}
                  element="span"
                >
                  <FormattedDuration
                    value={lesson.estimatedCompletionTime}
                  />
                </Typography>
              </div>
            ) */}
          </div>
        </div>
      </div>
    </div>
  );
};

LessonCardBase.displayName = 'LessonCard';

export const LessonCard = memo(LessonCardBase);
