import { TinyColor } from '@ctrl/tinycolor';
import { css, type Theme } from '@emotion/react';

import { TYPOGRAPHY_VARIANTS } from '../../../config/typography/constants';

export const datepicker = css`
  flex: 1;
`;

export const reactdatepicker =
  ({ isInLayer, layerZIndex }: { isInLayer: boolean; layerZIndex?: number }) =>
  (theme: Theme) => css`
    .react-datepicker {
      ${theme.typography[TYPOGRAPHY_VARIANTS.BODY_SMALL_REGULAR]};

      border: 1px solid ${theme.colors.gray[200]};
      border-radius: 4px;
    }

    .react-datepicker-wrapper {
      width: 100%;
    }

    .react-datepicker__triangle {
      display: none;
    }

    .react-datepicker__header {
      border: none;

      background-color: ${theme.colors.gray[0]};
    }

    .react-datepicker__current-month {
      ${theme.typography[TYPOGRAPHY_VARIANTS.BODY_MEDIUM_REGULAR]};

      font-weight: normal;
    }

    .react-datepicker__day-names {
      display: flex;
      justify-content: space-around;

      padding: 0 10px;

      border-bottom: 1px solid ${theme.colors.gray[100]};
    }

    .react-datepicker__week {
      transition: ${theme.transitions.default()};

      &:hover {
        background-color: ${theme.colors.primary[15]};
      }
    }

    .react-datepicker__day {
      ${theme.typography[TYPOGRAPHY_VARIANTS.BUTTON_SMALL_REGULAR]};

      border-radius: 4px;

      display: inline-flex;
      align-items: center;
      justify-content: center;

      transition: ${theme.transitions.default()};

      width: 20px;
      height: 20px;

      margin: 5px 10px;

      color: ${theme.colors.gray[700]};

      &:hover {
        background-color: ${theme.colors.primary[500]};
        color: ${theme.colors.gray[0]};
      }
    }

    .react-datepicker__day--disabled {
      color: ${theme.colors.gray[300]};

      &:hover {
        background-color: ${theme.colors.gray[0]};
        color: ${theme.colors.gray[300]};
      }
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-range {
      background-color: ${theme.colors.primary[500]};
      color: ${theme.colors.gray[0]};

      box-shadow: 0 0 0 4px
        ${new TinyColor(theme.colors.primary[500]).setAlpha(0.15).toRgbString()};
    }

    .react-datepicker__day--in-selecting-range {
      background-color: ${theme.colors.primary[300]};
      color: ${theme.colors.gray[0]};
    }

    .react-datepicker__day--keyboard-selected {
      background-color: ${theme.colors.primary[500]};
      color: ${theme.colors.gray[0]};
    }

    .react-datepicker__day--outside-month {
      color: ${theme.colors.gray[300]};
    }

    .react-datepicker-popper {
      z-index: ${isInLayer ? layerZIndex : undefined};
    }

    .react-datepicker__children-container {
      width: 100%;
    }

    .streaks-calendar {
      border: none;
      border-radius: 8px;
      box-shadow: 0px 0px 5px 1px ${theme.colors.gray[100]};
    }

    /** Week-end days must be gray except if user was connected on these days */
    .streaks-calendar .react-datepicker__day-names :nth-last-child(1),
    .streaks-calendar .react-datepicker__day-names :nth-last-child(2) {
      color: ${theme.colors.gray[300]};
    }

    .streaks-calendar .react-datepicker__day {
      cursor: default;
      margin: 5px 12px;
      &:hover {
        background-color: transparent;
        color: ${theme.colors.gray[700]};
      }
    }

    .streaks-calendar .react-datepicker__day--weekend {
      &:not(.days-streaks) {
        color: ${theme.colors.gray[300]};
      }
    }

    .streaks-calendar .react-datepicker__day--outside-month {
      visibility: hidden;
    }

    .streaks-calendar .react-datepicker__day--today {
      ${theme.typography[TYPOGRAPHY_VARIANTS.BUTTON_SMALL_BOLD]};
      color: ${theme.colors.primary[500]};
    }

    .days-streaks {
      position: relative;
      background-color: transparent;

      &:hover {
        background-color: transparent;
        color: ${theme.colors.gray[700]};
      }
    }

    .days-streaks::before {
      content: '';
      position: absolute;
      mix-blend-mode: multiply;
      top: 0;
      left: -12px;
      right: -12px;
      bottom: 0;
      background-color: ${theme.colors.warning[200]};
      z-index: 0;
    }

    .days-streaks.start::before {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      right: -12px;
    }

    .days-streaks.end::before {
      left: -12px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  `;

export const header = css`
  display: flex;
  justify-content: space-between;

  padding: 0 10px;
  margin-bottom: 10px;
`;

export const headerDate = css`
  text-transform: capitalize;
`;

export const monthButtons = css`
  display: flex;
  gap: 20px;
`;

export const title = css`
  display: flex;
  align-items: center;
  gap: 10px;
`;
