import { type ForwardedRef, forwardRef, memo, type ReactElement } from 'react';
import { type GroupBase, type SelectInstance } from 'react-select';
import {
  AsyncPaginate,
  type AsyncPaginateProps,
} from 'react-select-async-paginate';

import { type Additional, type SelectOption } from '@eversity/types/web';

import {
  type CommonSelectProps,
  DEFAULT_DEBOUNCE_TIME_MS,
  SELECT_SIZES,
} from './constants';
import { commonSelectDefaultProps } from './utils/defaultProps';
import { commonSelectPropTypes } from './utils/propTypes';
import { useReactSelectCustomization } from './utils/useReactSelectCustomizations';
import { type useReactSelectValue } from './utils/useReactSelectValueWrapper';

export type AsyncSelectProps<
  TOption extends SelectOption<any> = SelectOption<any>,
  TIsMulti extends boolean = false,
  TGroup extends GroupBase<TOption> = GroupBase<TOption>,
  TAdditional extends Additional = Additional,
> = Omit<
  AsyncPaginateProps<TOption, TGroup, TAdditional, TIsMulti>,
  'isDisabled' | 'formatOptionLabel' | 'value' | 'onChange' | 'options'
> &
  CommonSelectProps & {
    onChange?: Parameters<
      typeof useReactSelectValue<TOption, TGroup, TIsMulti, true>
    >[0]['onChange'];
    value: Parameters<
      typeof useReactSelectValue<TOption, TGroup, TIsMulti, true>
    >[0]['value'];
  };

const AsyncSelectInner = <
  TOption extends SelectOption<any>,
  TIsMulti extends boolean,
  TGroup extends GroupBase<TOption>,
  TAdditional extends Additional,
>(
  {
    id,
    size,
    disabled,
    hasError,
    hasWarning,
    ...props
  }: AsyncSelectProps<TOption, TIsMulti, TGroup, TAdditional>,
  ref: ForwardedRef<SelectInstance<TOption, TIsMulti, TGroup>>,
) => {
  const { onChange, onChangeMulti, ...customProps } =
    useReactSelectCustomization<TOption, TIsMulti, TGroup, true>({
      hasError,
      hasWarning,
      size,
      useOptionAsValue: true,
      ...props,
    });

  return (
    <AsyncPaginate<TOption, TGroup, Additional, TIsMulti>
      {...props}
      {...customProps}
      selectRef={ref}
      inputId={id}
      aria-labelledby={id}
      onChange={props.isMulti ? onChangeMulti : onChange}
      isDisabled={disabled}
      debounceTimeout={DEFAULT_DEBOUNCE_TIME_MS}
    />
  );
};

export const AsyncSelectBase = forwardRef(AsyncSelectInner) as {
  <
    TOption extends SelectOption<any> = SelectOption<any>,
    TIsMulti extends boolean = false,
    TGroup extends GroupBase<TOption> = GroupBase<TOption>,
    TAdditional extends Additional = Additional,
  >(
    props: AsyncSelectProps<TOption, TIsMulti, TGroup, TAdditional> & {
      ref?: ForwardedRef<SelectInstance<TOption, TIsMulti, TGroup>>;
    },
  ): ReactElement | null;

  propTypes?: any;
  defaultProps?: any;
  displayName?: any;
};

AsyncSelectBase.displayName = 'AsyncSelect';

AsyncSelectBase.propTypes = {
  ...commonSelectPropTypes,
};

AsyncSelectBase.defaultProps = {
  ...commonSelectDefaultProps,
};

export const AsyncSelect = memo(AsyncSelectBase) as typeof AsyncSelectBase & {
  SIZES?: typeof SELECT_SIZES;
};

AsyncSelect.SIZES = SELECT_SIZES;
