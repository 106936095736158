import { memo, type ReactNode } from 'react';

import { type LESSON_RESOURCE_TYPES } from '@eversity/domain/constants';
import { type Upload } from '@eversity/types/domain';

import { ResourcePicture } from '../picture/ResourcePicture';
import * as styles from './ResourceCard.styles';

export type ResourceCardProps = {
  pictureTag?: ReactNode;
  picture?: Upload;
  type?: LESSON_RESOURCE_TYPES;
  children?: ReactNode;
};

export const ResourceCardBase = ({
  picture,
  children,
  type,
  pictureTag,
}: ResourceCardProps) => {
  return (
    <div css={styles.container}>
      <ResourcePicture
        picture={picture}
        type={type}
      >
        {pictureTag && <div css={styles.tag}>{pictureTag}</div>}
      </ResourcePicture>

      <div css={styles.descriptionContainer}>{children}</div>
    </div>
  );
};

ResourceCardBase.displayName = 'ResourceCard';

export const ResourceCard = memo(ResourceCardBase);
