import { createContext, memo, type ReactNode, useMemo } from 'react';

import {
  DEFAULT_EDITOR_OUTLINE_MAX_LEVEL,
  DEFAULT_EDITOR_OUTLINE_MIN_LEVEL,
} from '@eversity/domain/constants';
import { getContentOutline, type SanitizeSchema } from '@eversity/domain/html';
import { type ContentOutlineElement } from '@eversity/types/domain';
import { useMemoizedBundle } from '@eversity/ui/utils';

export type CommonEditorContentContextValue = {
  content: string | ReactNode;
  contentOutline: ContentOutlineElement[] | null;
  sanitizeSchema: SanitizeSchema;
  isEditorContent: boolean;
};

export const CommonEditorContentContext =
  createContext<CommonEditorContentContextValue>({
    content: '',
    contentOutline: [],
    sanitizeSchema: null,
    isEditorContent: true,
  });

export type CommonEditorContentContextProviderProps = {
  content: string | ReactNode;
  sanitizeSchema: SanitizeSchema;
  isEditorContent?: boolean;
  children?: ReactNode;
};

export const CommonEditorContentContextProviderBase = ({
  content,
  sanitizeSchema,
  isEditorContent = true,
  children = null,
}: CommonEditorContentContextProviderProps) => {
  const contentOutline = useMemo(
    () =>
      isEditorContent
        ? getContentOutline(content as string, {
            schema: sanitizeSchema,
            minHeadingLevel: DEFAULT_EDITOR_OUTLINE_MIN_LEVEL,
            maxHeadingLevel: DEFAULT_EDITOR_OUTLINE_MAX_LEVEL,
          })
        : null,
    [content, sanitizeSchema, isEditorContent],
  );

  const contextValue = useMemoizedBundle<CommonEditorContentContextValue>({
    content,
    contentOutline,
    isEditorContent,
    sanitizeSchema,
  });

  return (
    <CommonEditorContentContext.Provider value={contextValue}>
      {children}
    </CommonEditorContentContext.Provider>
  );
};

CommonEditorContentContextProviderBase.displayName =
  'CommonEditorContentContextProvider';

export const CommonEditorContentContextProvider = memo(
  CommonEditorContentContextProviderBase,
);
