import { css, type Theme } from '@emotion/react';

export const navbarContainer = (theme: Theme) => css`
  display: flex;
  justify-content: space-between;
  padding: 10px;

  border-bottom: 1px solid ${theme.colors.gray[100]};

  @media (min-width: ${theme.breakpoints.small}) {
    padding: 10px 20px 10px 11px;
  }
`;

export const navbarGroupContainer = (theme: Theme) => css`
  display: flex;
  align-items: center;

  gap: 10px;

  @media (min-width: ${theme.breakpoints.small}) {
    gap: 20px;
  }
`;

export const navbarSectionContainer = (theme: Theme) => css`
  display: flex;
  align-items: center;

  gap: 10px;

  @media (min-width: ${theme.breakpoints.small}) {
    &.spacing-small {
      gap: 10px;
    }

    &.spacing-medium {
      gap: 20px;
    }

    &.spacing-large {
      gap: 40px;
    }
  }
`;

export const separator = (theme: Theme) => css`
  height: 100%;
  width: 1px;

  background-color: ${theme.colors.gray[100]};
`;
