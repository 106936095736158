type Callback<TResult = any> = (...args: any[]) => TResult | Promise<TResult>;

/**
 * A class to run async operations sequentially, executing pending operations with the last params.
 * When calling multiple times the promise while it is still pending, it caches the last params and
 * calls the promise again when the current one resolves.
 */
export class SequentialAsyncThrottle<TResult = any> {
  /**
   * A reference to a promise resolving when pending calls have all been flushed.
   */
  currentPromise?: Promise<TResult>;

  /**
   * @private
   */
  pendingCallback?: Callback<TResult>;

  /**
   * @private
   */
  pendingParameters?: any[];

  /**
   * @param callback - Function.
   * @param args - Params of callback.
   * @returns Promise resolving to the return value of the last call to callback.
   */
  run(callback: Callback, ...args: any[]) {
    // If there is already a call pending, cache the callback and its parameters, and return the
    // current promise reference.
    if (this.currentPromise) {
      this.pendingCallback = callback;
      this.pendingParameters = args;
      return this.currentPromise;
    }

    // If there is no call pending, execute the callback with its parameters.
    this.currentPromise = callback(...args)
      .then((result: unknown) => {
        // Reset the promise reference to signal that there are no pending calls.
        this.currentPromise = undefined;

        // If there was a call to `run` while the callback was pending, call run with the cached
        // callback and parameters. This will set the currentPromise reference again.
        if (this.pendingParameters && this.pendingCallback) {
          const { pendingCallback, pendingParameters } = this;
          this.pendingCallback = undefined;
          this.pendingParameters = undefined;

          // Return the new promise. This will make the original currentPromise resolve only when
          // this one does, recursively as long as `run` is called while it's still pending.
          return this.run(pendingCallback, ...pendingParameters);
        }

        // Resolve with the latest return value of the callback.
        return result;
      })
      .catch((err: any) => {
        this.currentPromise = undefined;
        return Promise.reject(err);
      });

    return this.currentPromise;
  }
}
