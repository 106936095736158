import { camelCase, isPlainObject } from 'lodash';
import { marked } from 'marked';

import { httpRegexp } from '../http/constants';
/**
 * Get the first letter of a set of labels.
 *
 * @param labels - All the labels passed as parameters.
 * @returns The first letter of all the labels.
 */
export const getInitials = (...labels: string[]) =>
  labels
    .map((label) => label.charAt(0))
    .join('')
    .toUpperCase();

/**
 * Check if a string is filled with empty spaces.
 *
 * @param str - Input.
 * @returns True if no string or only empty spaces.
 */
export const isEmptyString = (str?: string) => !str?.trim();

/**
 * Remove diacritics from a string (é, è, ê -> e, ç -> c, etc).
 *
 * TODO (927): make str required when everything is in TS.
 *
 * @param str - Input string.
 * @returns String without diacritics.
 */
export const removeDiacritics = (str: string): string =>
  str.normalize('NFD').replace(/\p{Diacritic}/gu, '');

/**
 * Replace string formatting with html formatting.
 *
 * @param description - Event description.
 * @returns
 */
export const formatStringAsHtml = (description: string) =>
  description
    .replace(
      httpRegexp,
      '<a target="_blank" rel="noopener noreferrer" href="$&">$&</a>',
    )
    .split('\n')
    .map((p) => `<p>${p}</p>`)
    .join('');

/**
 * Remove references from a string.
 *
 * @param str - Input string.
 * @returns String without references.
 */
export const removeReferencesFromString = (str: string) =>
  str.replace(/\s*\[\s*\d+\s*\]\s*/g, ' ');

/**
 * Replace markdown tags with html tags.
 *
 * @param str - Input markdown string.
 * @returns - HTML string.
 */
export const replaceMarkdownWithHtml = (str?: string) => {
  if (!str) return '';

  const multilineMarkdown = str.replace(/(?<!\*)\*(?=\s)/g, `\n* `);
  const html = marked(multilineMarkdown) as string;

  // remove last \n
  return html.replace(/\n+$/g, '');
};

/**
 * Escape a string to be used in a regular expression.
 *
 * @param string - The string to escape.
 * @returns - The escaped string.
 */
export const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

/**
 * Replace all occurrences of a string based on a map of replacements.
 * @param str - The string to replace.
 * @param mapObj - The map of replacements.
 * @returns - The string with all replacements made.
 */
export const replaceAll = (str: string, mapObj: { [key: string]: string }) => {
  const regex = new RegExp(`\\b(${Object.keys(mapObj).join('|')})\\b`, 'g');

  return escapeRegExp(str).replace(regex, (matched) => {
    return mapObj[matched];
  });
};

/**
 * Converts the keys of an object from snake_case to camelCase, including nested objects and arrays.
 * Uses `isPlainObject` to ensure we only handle plain objects (not instances like Date, RegExp, etc.).
 * Optionally limits the recursion depth to prevent stack overflow.
 *
 * @param obj - The object or array whose keys need to be converted to camelCase.
 * @param maxDepth - The maximum depth to recurse for nested objects (default is Infinity).
 * @param currentDepth - The current depth of recursion (internal use, defaults to 0).
 * @returns A new object or array with camelCase keys, including for nested objects and array elements.
 */
export const convertKeysToCamelCase = (
  obj: Record<string, any> | any[],
  maxDepth: number = 10,
  currentDepth: number = 0,
): Record<string, any> | any[] => {
  // Stop recursion if max depth is reached
  if (currentDepth >= maxDepth) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item: any) =>
      convertKeysToCamelCase(item, maxDepth, currentDepth + 1),
    );
  }

  if (isPlainObject(obj)) {
    return Object.entries(obj).reduce(
      (result, [key, value]) => {
        const camelKey = camelCase(key);

        result[camelKey] =
          isPlainObject(value) || Array.isArray(value)
            ? convertKeysToCamelCase(value, maxDepth, currentDepth + 1)
            : value;

        return result;
      },
      {} as Record<string, any>,
    );
  }

  return obj;
};
