import { type LocaleBundle } from '@eversity/types/misc';

import env from '../../config/env';

export const availableLocales = ['fr', 'en'];

export const DEFAULT_APP_LOCALE = 'fr';

export const importLocale = async (locale: string): Promise<LocaleBundle> => {
  const localeBundle = env.app.intlEnabled
    ? await import(`../../intl/locales/${locale}`)
    : await import(`../../intl/locales/${DEFAULT_APP_LOCALE}`);

  const { default: bundle } = localeBundle;
  return bundle;
};
