import { css, type Theme } from '@emotion/react';

export const footer = (theme: Theme) => css`
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background: ${theme.colors.primary[15]};
`;
