import {
  type ForwardedRef,
  forwardRef,
  type HTMLProps,
  memo,
  useCallback,
} from 'react';
import { css } from '@emotion/react';

import { type Upload } from '@eversity/types/domain';
import { downloadHref } from '@eversity/utils/web';

import { TYPOGRAPHY_VARIANTS } from '../../../../../config/typography/constants';
import { FileDownload } from '../../../file-download/FileDownload';

export type AttachmentProps = Omit<HTMLProps<HTMLDivElement>, 'ref'> & {
  upload: Upload;
  onRemove?: (file: Upload) => void;
};

export const AttachmentBase = forwardRef(
  (
    { upload, children = null, onRemove, ...props }: AttachmentProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const onDownload = useCallback(
      async ({ href, fileName }) => downloadHref(href, fileName),
      [],
    );

    return (
      <div
        {...props}
        ref={ref}
        css={(theme) =>
          css(theme.typography[TYPOGRAPHY_VARIANTS.BODY_MEDIUM_REGULAR])
        }
      >
        <FileDownload<Upload>
          file={upload}
          onDownload={onDownload}
          onRemove={onRemove}
        />

        {children}
      </div>
    );
  },
);

AttachmentBase.displayName = 'Attachment';

export const Attachment = memo(AttachmentBase);
