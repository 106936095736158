export enum ACHIEVEMENT_TYPES {
  CONNECTION_STREAK = 'CONNECTION_STREAK',
  EASTER_EGGS_2025 = 'EASTER_EGGS_2025',
}

export enum ACHIEVEMENT_TIERS {
  NEUTRAL = 'NEUTRAL',
  BRONZE = 'BRONZE',
  SILVER = 'SILVER',
  GOLD = 'GOLD',
}

type AchievementTierSettings = {
  threshold?: number;
  isListedIfNotOwned?: boolean;
};

export const ACHIEVEMENTS_DATA: Record<
  ACHIEVEMENT_TYPES,
  Partial<Record<ACHIEVEMENT_TIERS, AchievementTierSettings>>
> = {
  [ACHIEVEMENT_TYPES.CONNECTION_STREAK]: {
    [ACHIEVEMENT_TIERS.NEUTRAL]: {
      threshold: 5,
    },
    [ACHIEVEMENT_TIERS.BRONZE]: {
      threshold: 15,
    },
    [ACHIEVEMENT_TIERS.SILVER]: { threshold: 30 },
    [ACHIEVEMENT_TIERS.GOLD]: { threshold: 100 },
  },

  [ACHIEVEMENT_TYPES.EASTER_EGGS_2025]: {
    [ACHIEVEMENT_TIERS.GOLD]: {
      isListedIfNotOwned: true,
    },
  },
};
