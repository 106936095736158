import { defineMessages } from 'react-intl';

import { VARIANTS } from './constants';

export default defineMessages({
  [VARIANTS.SHORT]: {
    defaultMessage: '{hours}H{minutes, number, ::00}',
  },
  [VARIANTS.LONG]: {
    defaultMessage: `{hours, select,
      0 {} other {{hours, plural, one {# heure} other {# heures}}}
    }{hours, select,
      0 {} other {{minutes, select, 0 {} other { et }}}
    }{minutes, select,
      0 {{hours, select, 0 {moins d'une minute} other {}}}
      other {{minutes, plural, one {# minute} other {# minutes}}}
    }`,
  },
  [VARIANTS.ALL]: {
    defaultMessage: `{days, select,
      0 {} other {{days, plural, one {# jour} other {# jours}}}
    }{days, select,
      0 {} other {{hours, select, 0 {} other {, }}}
    }{hours, select,
      0 {} other {{hours, plural, one {# heure} other {# heures}}}
    }{hours, select,
      0 {} other {{minutes, select, 0 {} other {, }}}
    }{minutes, select,
      0 {} other {{minutes, plural, one {# minute} other {# minutes}}}
    }{minutes, select,
      0 {} other {{seconds, select, 0 {} other {, }}}
    }{seconds, select,
      0 {} other {{seconds, plural, one {# seconde} other {# secondes}}}
    }`,
  },
});
