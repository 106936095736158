import { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@emotion/react';
import { Fire } from '@icon-park/react';
import { noop } from 'lodash';

import dayjs from '@eversity/services/dayjs';
import {
  Datepicker,
  LineSeparator,
  Typography,
} from '@eversity/ui/design-system';

import messages from './StreaksCalendar.messages';
import * as styles from './StreaksCalendar.styles';

type StreaksCalendarProps = {
  connectionDays: string[];
  onMonthChangeCallback: (date: Date) => void;
};

const StreaksCalendarBase = ({
  connectionDays,
  onMonthChangeCallback,
}: StreaksCalendarProps) => {
  const theme = useTheme();

  const highlightedDays = useMemo(() => {
    return connectionDays.map((day) => new Date(day));
  }, [connectionDays]);

  const handleDaysClassName = useCallback(
    (date: Date) => {
      // Determines the appropriate CSS class for each day in the calendar.
      // Highlights consecutive dates as a streak and marks the start and end points.
      const dateObj = dayjs(date);
      const sortedDates = connectionDays
        .map((day) => dayjs(day))
        .sort((a, b) => a.unix() - b.unix());

      const index = sortedDates.findIndex((d) => d.isSame(dateObj, 'day'));
      const isHighlighted = index !== -1;

      const isStart =
        isHighlighted &&
        (index === 0 ||
          !sortedDates[index - 1].isSame(dateObj.subtract(1, 'day'), 'day'));

      const isEnd =
        isHighlighted &&
        (index === sortedDates.length - 1 ||
          !sortedDates[index + 1].isSame(dateObj.add(1, 'day'), 'day'));

      return isHighlighted
        ? `days-streaks ${isStart ? 'start' : ''} ${isEnd ? 'end' : ''}`.trim()
        : undefined;
    },
    [connectionDays],
  );

  return (
    <Datepicker
      onChange={noop}
      value={null}
      highlightDates={highlightedDays}
      headerIcon={
        <Fire fill={[theme.colors.secondary[500], theme.colors.warning[300]]} />
      }
      calendarClassName="streaks-calendar"
      dayClassName={handleDaysClassName}
      onMonthChange={onMonthChangeCallback}
      disabledKeyboardNavigation
      disabled
      inline
      readOnly
    >
      <LineSeparator css={styles.divider} />

      <div css={styles.footer}>
        <div css={styles.legendItem} />

        <Typography variant={Typography.VARIANTS.BODY_SMALL_REGULAR}>
          <FormattedMessage {...messages.LEGEND} />
        </Typography>
      </div>
    </Datepicker>
  );
};

StreaksCalendarBase.displayName = 'StreaksCalendar';

export const StreaksCalendar = memo(StreaksCalendarBase);
