import { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMedia } from 'react-use';
import { useTheme } from '@emotion/react';

import { type StudentAchievement } from '@eversity/types/domain';
import { type StudentStatistics } from '@eversity/types/node';
import { TilesList, Typography } from '@eversity/ui/design-system';
import { formattedDurationMessages } from '@eversity/ui/utils';
import { PARSE_DURATION_BASE_UNITS, parseDuration } from '@eversity/utils/misc';

import { AchievementsCard } from '../../achievements-card/AchievementsCard';
import {
  STATISTICS_ICON_MAP,
  STATISTICS_TILES_TYPES,
  STATISTICS_TILES_TYPES_MAP,
  STATISTICS_TILES_TYPES_VARIANT_MAP,
  STATISTICS_TYPES,
  STATISTICS_TYPES_OBJECT_MAP,
} from './constants';
import messages, {
  statisticsTileTitleMessages,
  statisticsTileTypesMessages,
} from './Statistics.messages';
import * as styles from './Statistics.styles';

type StatisticsProps = {
  achievements?: StudentAchievement[] | null;
  statistics?: StudentStatistics;
  isAchievementsLoading?: boolean;
  isStatisticsLoading?: boolean;
  withAchievements?: boolean;
  displayDialogOnMount?: boolean;
};

export const Statistics = ({
  statistics,
  isStatisticsLoading,
  achievements,
  isAchievementsLoading,
  withAchievements = false,
  displayDialogOnMount = false,
}: StatisticsProps) => {
  const intl = useIntl();

  const theme = useTheme();

  const isAboveLarge = useMedia(`(min-width: ${theme.breakpoints.large})`);

  const totalSessionsDurationSubtitle = useMemo(() => {
    if (statistics?.totalSessionsDuration) {
      const parsedTime = parseDuration(
        statistics.totalSessionsDuration,
        PARSE_DURATION_BASE_UNITS.MINUTE,
      );
      return intl.formatMessage(formattedDurationMessages.ALL, {
        days: parsedTime.days,
        hours: parsedTime.hours,
        minutes: parsedTime.minutes,
        seconds: parsedTime.seconds,
      });
    }

    return '';
  }, [statistics?.totalSessionsDuration, intl]);

  const getStatisticsTileProps = useCallback(
    (statisticsType: STATISTICS_TYPES) => {
      const statisticsFieldName = STATISTICS_TYPES_OBJECT_MAP[statisticsType];
      const statisticsValue = statistics?.[statisticsFieldName];
      const isStatisticsRatio =
        typeof statisticsValue === 'object' && statisticsValue !== null;
      const count = isStatisticsRatio
        ? statisticsValue?.count
        : (statisticsValue as number);
      const subtitleValue = isStatisticsRatio
        ? `${count}/${statisticsValue.total}`
        : count;

      return {
        icon: STATISTICS_ICON_MAP[statisticsType],
        title: intl.formatMessage(statisticsTileTitleMessages[statisticsType]),
        subtitle:
          statisticsType === STATISTICS_TYPES.TOTAL_SESSIONS_DURATION
            ? totalSessionsDurationSubtitle
            : intl.formatMessage(messages.TILE_SUBTITLE, {
                value: subtitleValue,
              }),
        variant:
          STATISTICS_TILES_TYPES_VARIANT_MAP[
            STATISTICS_TILES_TYPES_MAP[statisticsType]
          ],
        isLoading: isStatisticsLoading,
        isDisabled: !statistics,
      };
    },
    [totalSessionsDurationSubtitle, statistics, isStatisticsLoading, intl],
  );

  return (
    <div css={styles.container}>
      {Object.keys(STATISTICS_TILES_TYPES).map((currentType, index) => (
        <div key={currentType}>
          <div
            key={currentType}
            css={styles.item}
          >
            <TilesList>
              <TilesList.Title
                title={
                  <FormattedMessage
                    {...statisticsTileTypesMessages[currentType]}
                  />
                }
              />
              <TilesList.Group
                isLoading={isStatisticsLoading}
                tiles={Object.values(STATISTICS_TYPES)
                  .filter((statisticsType) => {
                    return (
                      STATISTICS_TILES_TYPES_MAP[statisticsType] === currentType
                    );
                  })
                  .map((statisticsType) =>
                    getStatisticsTileProps(statisticsType),
                  )}
              />
            </TilesList>
          </div>

          {index === 0 && withAchievements && !isAboveLarge && (
            <div
              css={styles.item}
              className="medal-item"
            >
              <Typography variant={Typography.VARIANTS.HEADING_4}>
                <FormattedMessage {...messages.ACHIEVEMENTS} />
              </Typography>

              <AchievementsCard
                achievements={achievements}
                isLoading={isAchievementsLoading}
                displayDialogOnMount={displayDialogOnMount}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
