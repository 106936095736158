import { useTheme } from '@emotion/react';
import { type IIcon } from '@icon-park/react/lib/runtime';

export const FileText = ({ size = 24, fill }: IIcon) => {
  const theme = useTheme();

  const mainColor = Array.isArray(fill) ? fill[0] : fill;
  const secondaryColor = Array.isArray(fill) ? fill[1] : theme.colors.gray[0];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 22H19C19.5523 22 20 21.5523 20 21V7H15V2H5C4.44771 2 4 2.44771 4 3V21C4 21.5523 4.44771 22 5 22Z"
        fill={secondaryColor}
        stroke={mainColor}
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 14.875C8.98325 14.875 9.375 14.4832 9.375 14C9.375 13.5168 8.98325 13.125 8.5 13.125C8.01675 13.125 7.625 13.5168 7.625 14C7.625 14.4832 8.01675 14.875 8.5 14.875Z"
        fill={mainColor}
      />
      <path
        d="M12 14.875C12.4832 14.875 12.875 14.4832 12.875 14C12.875 13.5168 12.4832 13.125 12 13.125C11.5168 13.125 11.125 13.5168 11.125 14C11.125 14.4832 11.5168 14.875 12 14.875Z"
        fill={mainColor}
      />
      <path
        d="M15.5 14.875C15.9832 14.875 16.375 14.4832 16.375 14C16.375 13.5168 15.9832 13.125 15.5 13.125C15.0168 13.125 14.625 13.5168 14.625 14C14.625 14.4832 15.0168 14.875 15.5 14.875Z"
        fill={mainColor}
      />
      <path
        d="M15 2L20 7"
        stroke={mainColor}
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
