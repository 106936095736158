import 'tippy.js/dist/tippy.css';

import {
  type ForwardedRef,
  forwardRef,
  memo,
  type ReactElement,
  type ReactNode,
} from 'react';
import { css, useTheme } from '@emotion/react';
import { Right } from '@icon-park/react';

import { useBoolState } from '@eversity/ui/utils';

import { ICON_SIZES } from '../../../general/icon/constants';
import { Popover } from '../../../general/popover/Popover';
import { ItemRenderer } from '../renderers/item/ItemRenderer';
import { ListRenderer } from '../renderers/list/ListRenderer';

export type DropdownSubListProps = {
  /** Icon from @icon-park/react. */
  icon?: ReactElement;
  /** Text label. */
  label?: ReactNode;
  /**
   * Override default inner renderer.
   * If this is specified, icon and label are ignored.
   * Signature: ({ isDisabled }) => React.Node.
   */
  render?: (params: { isSelected: boolean; isDisabled: boolean }) => ReactNode;
  /** Is the item disabled. */
  isDisabled?: boolean;
  /** Items and SubLists. */
  children?: ReactNode;
};

export const DropdownSubListBase = forwardRef(
  (
    {
      icon = null,
      label = null,
      render = null,
      isDisabled = false,
      children = null,
    }: DropdownSubListProps,
    ref: ForwardedRef<HTMLLIElement>,
  ) => {
    const theme = useTheme();

    const [isSubMenuOpen, onShow, onHide] = useBoolState(false);

    return (
      <li
        ref={ref}
        css={css`
          display: flex;
        `}
      >
        <Popover
          interactive
          trigger="click"
          placement="right-start"
          onShow={onShow}
          onHide={onHide}
          content={<ListRenderer>{children}</ListRenderer>}
        >
          <ItemRenderer
            icon={icon}
            label={label}
            render={render}
            isDisabled={isDisabled}
            isActive={isSubMenuOpen}
          >
            <Right
              size={ICON_SIZES.MEDIUM}
              fill={
                isDisabled ? theme.colors.gray[200] : theme.colors.gray[500]
              }
              css={css`
                justify-self: flex-end;
                margin-left: 8px;
              `}
            />
          </ItemRenderer>
        </Popover>
      </li>
    );
  },
);

DropdownSubListBase.displayName = 'DropdownSubList';

export const DropdownSubList = memo(DropdownSubListBase);
