import { serializeVersion } from '@eversity/domain/versions';
import {
  type AssignmentLesson,
  type CourseViewFull,
  type CourseViewMinimal,
  type CreateEditableSequenceBody,
  type CreateLessonBody,
  type CreateLessonEditableVersionBody,
  type CreateResourcesLessonBody,
  type GetLessonCoursesQuery,
  type GetLessonQuery,
  type GetLessonsQuery,
  type GetLessonVersionQuery,
  type LessonAssignment,
  type LessonEditableVersionSequence,
  type LessonEditableVersionViewFull,
  type LessonEditableVersionViewFullNumberedTitled,
  type LessonResource,
  type LessonVersionViewFull,
  type LessonVersionViewFullNumberedTitled,
  type LessonVersionWithSequencesViewMinimal,
  type LessonVersionWithSequencesViewMinimalNumberedTitled,
  type LessonViewFull,
  type LessonViewFullNumberedTitled,
  type LessonViewInternal,
  type LessonViewMinimal,
  type McqQuestionRequest,
  type UpdateEditableSequenceBody,
  type UpdateLessonEditableVersionBody,
  type UpdateResourcesLessonBody,
  type UpsertLessonAssignmentBody,
  type VersionObjectBase,
} from '@eversity/types/domain';

import { HttpRepository } from '../httpRepository';
import { mapLesson, mapVersion } from './mappers';

const e = encodeURIComponent;

const LESSONS_API_URL = '/api/v1/school/lessons';
const LESSON_FILES_API_URL = '/api/v1/file/downloads/lessons';

export class LessonsRepository extends HttpRepository {
  /**
   * Fetch lessons.
   *
   * @param query - Query.
   * @param query.q - Query string (filters on title or code).
   * @param query.view - View to fetch (LESSON_VIEWS enum).
   * @param query.sort - Field to sort (field is ASC, -field is DESC).
   * @param query.limit - Limit number of results.
   * @param query.onlyPublished - Filter out lessons without published versions.
   * @returns Lessons.
   */
  async getLessons(query: GetLessonsQuery): Promise<{
    count: number;
    lessons:
      | LessonViewMinimal[]
      | LessonViewInternal[]
      | LessonViewFullNumberedTitled[];
  }> {
    const {
      body: { count, lessons },
    } = await this.http.get(LESSONS_API_URL).query(query);

    return {
      count,
      lessons: lessons.map(
        (lesson: LessonViewFull | LessonViewMinimal | LessonViewInternal) =>
          mapLesson(lesson),
      ),
    };
  }

  /**
   * Get a lesson by its id.
   *
   * @param string lessonId - Lesson id.
   * @param query - Query.
   * @param query.view - View to fetch (LESSON_VIEWS enum).
   * @returns Lesson.
   */
  async getLesson(
    lessonId: string,
    query: GetLessonQuery,
  ): Promise<
    LessonViewMinimal | LessonViewInternal | LessonViewFullNumberedTitled
  > {
    const { body: lesson } = await this.http
      .get(`${LESSONS_API_URL}/${e(lessonId)}`)
      .query(query);

    return mapLesson(
      lesson as LessonViewFull | LessonViewMinimal | LessonViewInternal,
    );
  }

  /**
   * Create a new lesson.
   *
   * @param params - Params.
   * @param params.title - Lesson title.
   * @param params.code - Lesson code.
   * @param params.editors - Lesson editor ids.
   * @returns Lesson.
   */
  async createLesson(
    params: CreateLessonBody,
  ): Promise<LessonViewFullNumberedTitled> {
    const { body: lesson } = await this.http.post(LESSONS_API_URL).send(params);

    return mapLesson(lesson as LessonViewFull) as LessonViewFullNumberedTitled;
  }

  /**
   * Delete a lesson.
   *
   * @param lessonId - Lesson id.
   */
  async deleteLesson(lessonId: string) {
    await this.http.delete(`${LESSONS_API_URL}/${e(lessonId)}`);
  }

  /**
   * Get a lesson version by its number.
   *
   * @param lessonId - Lesson id.
   * @param version - Version.
   * @param version.versionMajor - Major version number.
   * @param version.versionMinor - Minor version number.
   * @returns Lesson version.
   */
  async getLessonVersion(
    lessonId: string,
    version: VersionObjectBase<false>,
    query?: GetLessonVersionQuery,
  ): Promise<
    | LessonVersionViewFullNumberedTitled
    | LessonVersionWithSequencesViewMinimalNumberedTitled
  > {
    const serializedVersion = serializeVersion(version);

    const { body: lessonVersion } = await this.http
      .get(`${LESSONS_API_URL}/${e(lessonId)}/versions/${e(serializedVersion)}`)
      .query(query);

    return mapVersion(
      lessonVersion as
        | LessonVersionViewFull
        | LessonVersionWithSequencesViewMinimal,
    ) as
      | LessonVersionViewFullNumberedTitled
      | LessonVersionWithSequencesViewMinimalNumberedTitled;
  }

  /**
   * Get a lesson editable version by its id.
   *
   * @param lessonId - Lesson id.
   * @param editableVersionId - Editable version id.
   * @returns Lesson editable version.
   */
  async getLessonEditableVersion(
    lessonId: string,
    editableVersionId: string,
  ): Promise<LessonEditableVersionViewFullNumberedTitled> {
    const { body: editableVersion } = await this.http.get(
      `${LESSONS_API_URL}/${e(lessonId)}/editable-versions/${e(
        editableVersionId,
      )}`,
    );

    return mapVersion(
      editableVersion as LessonEditableVersionViewFull,
    ) as LessonEditableVersionViewFullNumberedTitled;
  }

  /**
   * Create a new editable version.
   *
   * @param lessonId - Lesson id.
   * @param params - Params.
   * @param params.fromVersionMajor - Origin major version.
   * @param params.fromVersionMinor - Origin major minor.
   * @param params.editors - Editor ids of this version.
   * @param params.type - Type of version (MAJOR|MINOR).
   * @returns Editable version.
   */
  async createLessonEditableVersion(
    lessonId: string,
    params: CreateLessonEditableVersionBody,
  ): Promise<LessonEditableVersionViewFullNumberedTitled> {
    const { body: editableVersion } = await this.http
      .post(`${LESSONS_API_URL}/${e(lessonId)}/editable-versions`)
      .send(params);

    return mapVersion(
      editableVersion as LessonEditableVersionViewFull,
    ) as LessonEditableVersionViewFullNumberedTitled;
  }

  /**
   * Edit a lesson editable version metadata.
   *
   * @param lessonId - Lesson id.
   * @param editableVersionId - Editable version id.
   * @param params - Params.
   * @param params.editors - Editor user ids.
   * @param params.status - Editable version status.
   * @param params.sequences - Sequences in order.
   * @param params.publish - Set to true to publish the version.
   * @returns Editable version or published version.
   */
  async updateLessonEditableVersion(
    lessonId: string,
    editableVersionId: string,
    params: UpdateLessonEditableVersionBody,
  ): Promise<
    | LessonVersionViewFullNumberedTitled
    | LessonEditableVersionViewFullNumberedTitled
  > {
    const { body: editableVersion } = await this.http
      .patch(
        `${LESSONS_API_URL}/${e(lessonId)}/editable-versions/${e(
          editableVersionId,
        )}`,
      )
      .send(params);

    return mapVersion(
      editableVersion as LessonEditableVersionViewFull | LessonVersionViewFull,
    ) as
      | LessonVersionViewFullNumberedTitled
      | LessonEditableVersionViewFullNumberedTitled;
  }

  /**
   * Delete a lesson editable version.
   *
   * @param lessonId - Lesson id.
   * @param editableVersionId - Editable version id.
   */
  async deleteLessonEditableVersion(
    lessonId: string,
    editableVersionId: string,
  ) {
    await this.http.delete(
      `${LESSONS_API_URL}/${e(lessonId)}/editable-versions/${e(
        editableVersionId,
      )}`,
    );
  }

  /**
   * Create a new empty editable sequence.
   *
   * @param lessonId - Lesson id.
   * @param editableVersionId - Editable version id.
   * @param params - Body.
   * @param params.type - Sequence type.
   * @returns Lesson editable version.
   */
  async createEditableSequence(
    lessonId: string,
    editableVersionId: string,
    params?: CreateEditableSequenceBody,
  ): Promise<LessonEditableVersionSequence> {
    const { body: editableSequence } = await this.http
      .post(
        `${LESSONS_API_URL}/${e(lessonId)}/editable-versions/${e(
          editableVersionId,
        )}/sequences`,
      )
      .send(params);

    return editableSequence;
  }

  /**
   * Update an editable sequence.
   *
   * @param lessonId - Lesson id.
   * @param editableVersionId - Editable version id.
   * @param sequenceVersionId - Sequence version id.
   * @param params - Params.
   * @param params.content - Content.
   * @param params.isNumbered - Is the sequence numbered.
   * @returns Updated sequence.
   */
  async updateEditableSequence(
    lessonId: string,
    editableVersionId: string,
    sequenceVersionId: string,
    params: UpdateEditableSequenceBody,
  ): Promise<LessonEditableVersionSequence> {
    const { body: editableSequence } = await this.http
      .patch(
        `${LESSONS_API_URL}/${e(lessonId)}/editable-versions/${e(
          editableVersionId,
        )}/sequences/${e(sequenceVersionId)}`,
      )
      .send(params);

    return editableSequence;
  }

  /**
   * Delete an editable sequence.
   *
   * @param lessonId - Lesson id.
   * @param editableVersionId - Editable version id.
   * @param sequenceVersionId - Sequence version id.
   */
  async deleteEditableSequence(
    lessonId: string,
    editableVersionId: string,
    sequenceVersionId: string,
  ) {
    await this.http.delete(
      `${LESSONS_API_URL}/${e(lessonId)}/editable-versions/${e(
        editableVersionId,
      )}/sequences/${e(sequenceVersionId)}`,
    );
  }

  /**
   * Add a question to an editable sequence exercise.
   *
   * @param lessonId - Lesson id.
   * @param editableVersionId - Editable version id.
   * @param sequenceVersionId - Sequence version id.
   * @param question - Question data.
   * @returns Updated sequence.
   */
  async createExerciseQuestion(
    lessonId: string,
    editableVersionId: string,
    sequenceVersionId: string,
    question: McqQuestionRequest,
  ): Promise<LessonEditableVersionSequence> {
    const { body: editableSequence } = await this.http
      .post(
        `${LESSONS_API_URL}/${e(lessonId)}/editable-versions/${e(
          editableVersionId,
        )}/sequences/${e(sequenceVersionId)}/exercise/questions`,
      )
      .send(question);

    return editableSequence;
  }

  /**
   * Update a question in an editable sequence exercise.
   *
   * @param lessonId - Lesson id.
   * @param editableVersionId - Editable version id.
   * @param sequenceVersionId - Sequence version id.
   * @param questionId - Question id.
   * @param question - Question data.
   * @returns Updated sequence.
   */
  async updateExerciseQuestion(
    lessonId: string,
    editableVersionId: string,
    sequenceVersionId: string,
    questionId: string,
    question: McqQuestionRequest,
  ): Promise<LessonEditableVersionSequence> {
    const { body: editableSequence } = await this.http
      .patch(
        `${LESSONS_API_URL}/${e(lessonId)}/editable-versions/${e(
          editableVersionId,
        )}/sequences/${e(sequenceVersionId)}/exercise/questions/${e(
          questionId,
        )}`,
      )
      .send(question);

    return editableSequence;
  }

  /**
   * Remove a question in an editable sequence exercise.
   *
   * @param lessonId - Lesson id.
   * @param editableVersionId - Editable version id.
   * @param sequenceVersionId - Sequence version id.
   * @param questionId - Question id.
   * @returns Updated sequence.
   */
  async deleteExerciseQuestion(
    lessonId: string,
    editableVersionId: string,
    sequenceVersionId: string,
    questionId: string,
  ): Promise<LessonEditableVersionSequence> {
    const { body: editableSequence } = await this.http.delete(
      `${LESSONS_API_URL}/${e(lessonId)}/editable-versions/${e(
        editableVersionId,
      )}/sequences/${e(sequenceVersionId)}/exercise/questions/${e(questionId)}`,
    );

    return editableSequence;
  }

  /**
   * Link an assignment to lesson major versions.
   *
   * @param lessonId - Lesson id.
   * @param params - Params.
   * @param params.assignmentId - Assignment id.
   * @param params.forVersionsMajor - Lesson major versions.
   * @returns Assignment with all major versions.
   */
  async upsertLessonAssignment(
    lessonId: string,
    params: UpsertLessonAssignmentBody,
  ): Promise<LessonAssignment> {
    const { body: lessonAssignment } = await this.http
      .post(`${LESSONS_API_URL}/${e(lessonId)}/assignments`)
      .send(params);

    return lessonAssignment;
  }

  /**
   * Unlink lesson versions from an assignment.
   *
   * @param lessonId - Lesson id.
   * @param params - Params.
   * @param params.assignmentId - Assignment id.
   * @returns Updated lesson assignments
   */
  async unlinkLessonVersionsFromAssignment(
    lessonId: string,
    params: UpsertLessonAssignmentBody,
  ): Promise<AssignmentLesson[]> {
    const { body: assignmentLessons } = await this.http
      .patch(`${LESSONS_API_URL}/${e(lessonId)}/assignments`)
      .send(params);

    return assignmentLessons;
  }

  /**
   * Get courses linked to a lessonId.
   *
   * @param lessonId - Lesson id.
   * @returns List of courses linked to a lesson.
   */
  async getLessonCourses<T extends CourseViewMinimal[] | CourseViewFull[]>(
    lessonId: string,
    query?: GetLessonCoursesQuery,
  ): Promise<T> {
    const { body: lessonCourses } = await this.http
      .get(`${LESSONS_API_URL}/${e(lessonId)}/courses`)
      .query(query);

    return lessonCourses;
  }

  /**
   * Create a new resource.
   *
   * @param lessonId - Lesson id.
   * @param params - Body.
   * @returns Created resource.
   */
  async createLessonResource(
    lessonId: string,
    params?: CreateResourcesLessonBody,
  ): Promise<LessonResource> {
    const { body: createdResource } = await this.http
      .post(`${LESSONS_API_URL}/${e(lessonId)}/resources`)
      .send(params);

    return createdResource;
  }

  /**
   * Update a resource.
   *
   * @param lessonId - Lesson id.
   * @param resourceId - Resource id.
   * @param params - Body.
   * @returns Updated resource.
   */
  async updateLessonResource(
    lessonId: string,
    resourceId: string,
    params?: UpdateResourcesLessonBody,
  ): Promise<LessonViewFull> {
    const { body: updatedResource } = await this.http
      .patch(`${LESSONS_API_URL}/${e(lessonId)}/resources/${e(resourceId)}`)
      .send(params);

    return updatedResource;
  }

  /**
   * Delete a lesson resource.
   *
   * @param lessonId - Lesson id.
   * @param resourceId - Id of the resource
   */
  async deleteLessonResource(lessonId: string, resourceId: string) {
    await this.http.delete(
      `${LESSONS_API_URL}/${e(lessonId)}/resources/${e(resourceId)}`,
    );
  }

  /**
   * Retrieve all resources (lesson resources and virtual classroom replays) linked to a lesson
   *
   * @param lessonId - Lesson Id
   * @returns All lesson resources.
   */
  async getLessonResources(
    lessonId: string,
    options: { includeReplays: boolean },
  ): Promise<LessonResource[]> {
    const { body: lessonResources } = await this.http
      .get(`${LESSONS_API_URL}/${e(lessonId)}/resources`)
      .query(options);

    return lessonResources;
  }

  /**
   * Download a file resource.
   *
   * @param lessonId - Lesson id.
   * @param uploadId - Upload id.
   * @returns - File.
   */
  async downloadLessonResource(
    lessonId: string,
    uploadId: string,
  ): Promise<{ type: string; body: Blob }> {
    const { body, type } = await this.http
      .get(`${LESSON_FILES_API_URL}/${e(lessonId)}/resources/${e(uploadId)}`)
      .responseType('blob');

    return { body, type };
  }
}
