/**
 * Remove html tags from string
 * @param html string which contains html tags
 * @returns text without html tags
 */
export const removeHtmlTags = (html: string) => {
  return html
    .replace(/(<([^>]+)>)/gi, ' ')
    .replace('  ', ' ')
    .replace(/&#39;/gi, "'")
    .replace(/&quot;/gi, '"')

    .trim();
};

/**
 * Remove html tags from string, add \n and - to bullet points
 * @param html string which contains html tags
 * @returns parsed text
 */
export const hmtlToPlainText = (html: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  doc.querySelectorAll('ul, ol').forEach((list) => {
    const items = list.querySelectorAll('li');
    items.forEach((item) => {
      const text = `- ${item.textContent?.trim()}\n`;
      const textNode = document.createTextNode(text);
      item.parentNode?.replaceChild(textNode, item);
    });
    list.replaceWith(...(Array.from(list.childNodes) as ChildNode[]));
  });

  return doc.body.textContent?.replace(/\n+/g, '\n').trim();
};
