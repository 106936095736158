import { type ReactElement } from 'react';
import { VideoTwo } from '@icon-park/react';

import { LESSON_RESOURCE_TYPES } from '@eversity/domain/constants';

import { FileText } from './icon/FileText';

export enum SIZES {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export const LESSON_RESOURCE_ICON_MAPPING: Record<
  LESSON_RESOURCE_TYPES,
  ReactElement
> = {
  [LESSON_RESOURCE_TYPES.VIDEO]: <VideoTwo />,
  [LESSON_RESOURCE_TYPES.FILE]: <FileText />,
} as const;
