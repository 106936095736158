import { memo, type MemoExoticComponent, type ReactNode } from 'react';
import { css } from '@emotion/react';

import { type AvatarShape } from '@eversity/types/web';

import { Typography } from '../../general/typography/Typography';
import { Avatar } from '../avatar/Avatar';
import { AVATAR_SIZES } from '../avatar/constants';
import * as styles from './AvatarInline.styles';

export type AvatarInlineProps = {
  /** User to whom the avatar belongs. */
  user: AvatarShape;
  /** Status of the user. */
  status?: ReactNode;
  /** Avatar size. */
  size?: typeof Avatar.SIZES.MEDIUM | typeof Avatar.SIZES.SMALL;
  /** Display light text. */
  light?: boolean;
  /** Root classname. */
  className?: string;
};

export const AvatarInlineBase = ({
  user,
  status = null,
  size = Avatar.SIZES.MEDIUM,
  light = false,
  className = null,
}: AvatarInlineProps) => (
  <div
    css={styles.container({ hasStatus: !!status })}
    className={className}
  >
    <Avatar
      user={user}
      size={size}
    />
    <div css={styles.textContainer}>
      <Typography
        variant={
          size === Avatar.SIZES.SMALL
            ? Typography.VARIANTS.BODY_MEDIUM_REGULAR
            : Typography.VARIANTS.BODY_MEDIUM_BOLD
        }
        css={(theme) => css`
          color: ${light ? theme.colors.gray[0] : theme.colors.gray[900]};
        `}
      >
        {'firstName' in user
          ? `${user.firstName} ${user.lastName}`
          : user.fullName}
      </Typography>

      {size === Avatar.SIZES.MEDIUM && status && (
        <Typography
          variant={Typography.VARIANTS.BODY_SMALL_REGULAR}
          css={(theme) => css`
            color: ${theme.colors.gray[700]};
          `}
        >
          {status}
        </Typography>
      )}
    </div>
  </div>
);

AvatarInlineBase.displayName = 'AvatarInline';

export const AvatarInline: MemoExoticComponent<typeof AvatarInlineBase> & {
  SIZES?: typeof AVATAR_SIZES;
} = memo(AvatarInlineBase);

AvatarInline.SIZES = AVATAR_SIZES;
