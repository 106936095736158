import { css, type Theme } from '@emotion/react';

export const container = (theme: Theme) => css`
  background-color: inherit;

  display: flex;
  align-items: center;
  justify-content: initial;
  width: auto;

  position: relative;

  top: 1px;
  left: 0px;

  border-right: 1px solid transparent;
  border-bottom: 1px solid transparent;

  &.isActive {
    border-bottom: 1px solid ${theme.colors.primary[500]};
  }

  &.isVertical {
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;

    top: 0px;
    left: 1px;

    &.isActive {
      border-right: 1px solid ${theme.colors.primary[500]};
      border-bottom: 1px solid transparent;
    }
  }
`;

export const tab = (theme: Theme) => css`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: ${theme.colors.gray[700]};

  padding: 7px 0px 7px 0px;

  &.isVertical {
    justify-content: flex-end;
    text-align: right;
    padding: 4px 20px 4px 4px;
    &.isDraggable {
      padding: 4px 0px 4px 4px;
    }
  }

  &.isActive {
    color: ${theme.colors.primary[500]};
  }

  &.isDisabled {
    color: ${theme.colors.gray[300]};
    cursor: default;
  }
  transition: ${theme.transitions.default()};
`;

export const label = () => css`
  margin-left: 0px;
  &.hasBadge {
    margin-left: 10px;
  }
`;

export const drag = () => css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  cursor: grab;

  padding: 2px;
  margin: 0px;

  &.isVertical {
    margin: 0 15px 0 5px;
  }
`;
