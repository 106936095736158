import { memo, type ReactNode } from 'react';
import cn from 'classnames';

import { Typography } from '@eversity/ui/design-system';

import * as styles from './FlashCard.styles';

export type FlashCardProps = {
  question: ReactNode;
  answer: ReactNode;
  displayedSide?: 'front' | 'back';
  isTextSelectable?: boolean;
};

export const FlashCardBase = ({
  question,
  answer,
  displayedSide = 'front',
  isTextSelectable = true,
}: FlashCardProps) => {
  return (
    <div css={styles.flashCardContainer}>
      <div
        css={styles.card}
        className={`${displayedSide}Displayed`}
      >
        <div
          css={styles.cardSide}
          className="frontSide"
        >
          <Typography
            variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}
            css={styles.text}
            className={cn({ isSelectable: isTextSelectable })}
          >
            {question}
          </Typography>
        </div>

        <div
          css={styles.cardSide}
          className="backSide"
        >
          <Typography
            variant={Typography.VARIANTS.BODY_MEDIUM_BOLD}
            css={styles.text}
            className={cn({ isSelectable: isTextSelectable })}
          >
            {answer}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export const FlashCard = memo(FlashCardBase);
