import {
  Children,
  Fragment,
  memo,
  type MemoExoticComponent,
  type PropsWithChildren,
  type ReactNode,
} from 'react';

import { LineSeparator } from '../../general/line-separator/LineSeparator';
import { LinkButton } from '../../general/link-button/LinkButton';
import { Typography } from '../../general/typography/Typography';
import { Tile, type TileProps } from '../tile/Tile';
import * as styles from './TilesList.styles';

/**
 * A container component that displays a grid of Tile components.
 *
 * @example
 * <TilesList>
 *   <TilesList.Title title={title} />
 *   <TilesList.Section>
 *     <TilesList.Group tiles={tiles} isLoading={isLoading} />
 *     <TilesList.Group tiles={otherTiles} isLoading={isLoading} />
 *   </TilesList.Section>
 * </TilesList>
 *
 * @returns {ReactElement} Rendered TileList component.
 */
const TilesListBase = ({ children }: PropsWithChildren) => (
  <div css={styles.container}>{children}</div>
);

const TilesListSection = memo(({ children }: PropsWithChildren) => {
  const childrenArray = Children.toArray(children);

  return (
    <div css={styles.container}>
      {childrenArray.map((child, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={`tiles-list-group-item-${index}`}>
          {child}

          {index !== childrenArray.length - 1 && <LineSeparator />}
        </Fragment>
      ))}
    </div>
  );
});

type TilesListTitleProps = {
  /** Tiles list title */
  title: ReactNode;
  /** Link title */
  linkTitle?: ReactNode;
  /** Optional internal href to make the title a link */
  onClick?: () => void;
};

const TilesListTitle = memo(
  ({ title, linkTitle, onClick }: TilesListTitleProps) => {
    return (
      <div css={styles.titleContainer}>
        <Typography variant={Typography.VARIANTS.HEADING_4}>{title}</Typography>

        {onClick && linkTitle && (
          <LinkButton onClick={onClick}>{linkTitle}</LinkButton>
        )}
      </div>
    );
  },
);

type TilesListGroupProps = {
  /** List of props for each Tile */
  tiles: TileProps[];
  /** Propagation of loading state to Tiles */
  isLoading?: boolean;
};

const TilesListGroup = memo(
  ({ tiles, isLoading = false }: TilesListGroupProps) => {
    return (
      <div css={styles.tilesContainer}>
        {tiles.map((tileProps) => (
          <Tile
            key={tileProps.title}
            {...tileProps}
            isLoading={tileProps.isLoading || isLoading}
          />
        ))}
      </div>
    );
  },
);

TilesListBase.displayName = 'TilesList';

type CompoundedComponent = MemoExoticComponent<typeof TilesListBase> & {
  Title: typeof TilesListTitle;
  Group: typeof TilesListGroup;
  Section: typeof TilesListSection;
};

const TilesList = memo(TilesListBase) as CompoundedComponent;

TilesList.Title = TilesListTitle;
TilesList.Group = TilesListGroup;
TilesList.Section = TilesListSection;

export { TilesList };
