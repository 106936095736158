import { css } from '@emotion/react';

export const cardContainer = css`
  max-width: 300px;
`;

export const cardTitle = css`
  display: flex;
  gap: 20px;
`;

export const cardFooter = css`
  justify-content: flex-end;
`;

export const cardContent = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
