import { getEnvironmentVariable } from '@eversity/utils/web';

export default {
  app: {
    name: 'eversity/web',
    env: getEnvironmentVariable('ENVIRONMENT'),
    intlEnabled: getEnvironmentVariable<boolean>('INTL_ENABLED', {
      transform: (v) => v === 'true',
      defaultValue: false,
    }),
  },

  sentry: {
    dsn: getEnvironmentVariable('WEB_SENTRY_DSN'),
    env: getEnvironmentVariable('ENVIRONMENT'),
    release: `web@${process.env.BUILD_VAR_SENTRY_RELEASE}`,
    enabled: getEnvironmentVariable<boolean>('WEB_SENTRY_ENABLED', {
      transform: (v) => v === 'true',
      defaultValue: getEnvironmentVariable<boolean>('SENTRY_ENABLED', {
        transform: (v) => v === 'true',
        defaultValue: true,
      }),
    }),
  },

  cdn: {
    url: getEnvironmentVariable('CDN_URL'),
    studentCardEnacoTemplate: getEnvironmentVariable(
      'CDN_STUDENT_CARD_ENACO_TEMPLATE_PATH',
    ),
    studentCardMetierTemplate: getEnvironmentVariable(
      'CDN_STUDENT_CARD_METIER_TEMPLATE_PATH',
    ),
  },
} as const;
