export const PinkishEgg = () => (
  <svg
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1772 0C18.8002 0 24 9.13584 24 17.9665C24 26.7972 18.8002 32 12.1772 32C5.55421 32 1.52588e-05 26.7972 1.52588e-05 17.9665C1.52588e-05 9.13584 5.55421 0 12.1772 0Z"
      fill="#F1CED5"
    />
    <mask
      id="mask0_105_12638"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="32"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1772 0C18.8002 0 24 9.13584 24 17.9665C24 26.7972 18.8002 32 12.1772 32C5.55421 32 1.52588e-05 26.7972 1.52588e-05 17.9665C1.52588e-05 9.13584 5.55421 0 12.1772 0Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_105_12638)">
      <path
        d="M-3.24123 13.7886L2.0122 19.0911L7.26563 13.7886L12.5191 19.0911L17.7725 13.7886L23.0259 19.0911L28.2793 13.7886"
        stroke="#E39EAC"
        strokeWidth="2.52388"
        strokeLinecap="round"
      />
      <path
        d="M-3.34061 19.7474L1.91282 25.0498L7.16625 19.7474L12.4197 25.0498L17.6731 19.7474L22.9265 25.0498L28.18 19.7474"
        stroke="#E39EAC"
        strokeWidth="2.52388"
        strokeLinecap="round"
      />
      <path
        d="M-3.14197 7.8299L2.11146 13.1323L7.36489 7.8299L12.6183 13.1323L17.8717 7.8299L23.1252 13.1323L28.3786 7.8299"
        stroke="#E39EAC"
        strokeWidth="2.52388"
        strokeLinecap="round"
      />
    </g>
  </svg>
);
