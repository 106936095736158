import { xor } from 'lodash';

export const ensureArray = <T>(v: T | T[]): T[] => ([] as T[]).concat(v);

export const setAtIndex = <T>(array: T[], index: number, item: T): T[] => {
  const clone = [...array];
  clone.splice(index, 1, item);
  return clone;
};

export const insertAtIndex = <T>(
  array: T[],
  index: number,
  ...items: T[]
): T[] => {
  const clone = [...array];
  clone.splice(index, 0, ...items);
  return clone;
};

export const removeAtIndex = <T>(array: T[], index: number): T[] => {
  const clone = [...array];
  clone.splice(index, 1);
  return clone;
};

export const getEmptyArrayWhenArraysAreEqual = <T>(
  input: T[] = [],
  comparison: T[] = [],
): T[] => (xor(input, comparison).length ? input : []);

/**
 * Format an array of JS objects to a NDJSON string format.
 *
 * @param data - Data to format.
 * @returns Data as NDJSON.
 */
export const formatArrayObjectAsNdJson = (data: any[]) =>
  data.map((elt) => JSON.stringify(elt)).join('\n');

/**
 * Check if an array includes at least one of the values.
 *
 * @example
 * includesOneOf([1, 2, 3], [2, 4]); // true
 * includesOneOf([1, 2, 3], [4, 5]); // false
 *
 * @param array - The array to check.
 * @param values - The values to check for.
 * @returns True if the array includes at least one of the values.
 */
export const includesOneOf = <T>(array: T[], values: T[]): boolean =>
  values.some((value) => array.includes(value));
