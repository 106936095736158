import { Fragment, memo, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { noop } from 'lodash';

import {
  FILE_UPLOAD_CONTEXT_RULES,
  FILE_UPLOAD_CONTEXTS,
} from '@eversity/domain/constants';
import { type Upload } from '@eversity/types/domain';
import {
  FileUpload,
  Typography,
  useFileUpload,
} from '@eversity/ui/design-system';
import { globalMessages } from '@eversity/ui/intl';

import ProfilePictureItem, {
  type ProfilePictureItemProps,
} from './ProfilePictureItem';
import messages from './SelectProfilePictureField.messages';
import * as styles from './SelectProfilePictureField.styles';

const { maxSize, mimeTypes, maxFiles } =
  FILE_UPLOAD_CONTEXT_RULES[FILE_UPLOAD_CONTEXTS.USER_PROFILE_PICTURE];

export type SelectProfilePictureFieldProps = {
  /** Picture Id. */
  value?: string;
  /** History of user's pictures. */
  picturesHistory?: Upload[];
  /** Callback to update field state. */
  onChange?: ProfilePictureItemProps['onChangeSelected'];
  /** Allow to import new pic. */
  hideImport?: boolean;
  /** Callback to delete an image. */
  onDelete?: ProfilePictureItemProps['onDelete'];
  /** Callback to upload images. */
  onUpload?: Parameters<typeof useFileUpload>[0]['onUpload'];
};

const DEFAULT_PICTURES_HISTORY = [];

export const SelectProfilePictureFieldBase = ({
  value = null,
  picturesHistory = DEFAULT_PICTURES_HISTORY,
  onChange = noop,
  hideImport = false,
  onUpload = null,
  onDelete = noop,
}: SelectProfilePictureFieldProps) => {
  const [uploadedPicture, setUploadedPicture] = useState<Upload>(null);

  const onChangeUploadedPicture = useCallback(
    (picture: Upload) => {
      setUploadedPicture(picture);
      onChange(picture.id);
    },
    [onChange],
  );

  const filesUploadingProps = useFileUpload({ onUpload });

  const onDeleteProxy: ProfilePictureItemProps['onDelete'] = useCallback(
    (pictureId) => {
      onDelete(pictureId);

      // If the deleted picture was just uploaded, empty the file upload field.
      if (pictureId === uploadedPicture.id) {
        setUploadedPicture(null);
      }
    },
    [onDelete, uploadedPicture, setUploadedPicture],
  );

  return (
    <Fragment>
      {!hideImport && (
        <Fragment>
          <div>
            <Typography
              variant={Typography.VARIANTS.HEADING_4}
              css={styles.title}
            >
              <FormattedMessage {...messages.IMPORT_TITLE} />
            </Typography>

            {uploadedPicture ? (
              <ProfilePictureItem
                picture={uploadedPicture}
                isSelected={uploadedPicture.id === value}
                onChangeSelected={onChange}
                onDelete={onDeleteProxy}
              />
            ) : (
              <FileUpload
                {...filesUploadingProps}
                accept={mimeTypes.join(',')}
                onChange={onChangeUploadedPicture}
                value={uploadedPicture}
                maxFiles={maxFiles}
                maxSize={maxSize}
              />
            )}
          </div>

          {!!picturesHistory?.length && (
            <div css={styles.separator}>
              <div css={styles.lineSeparator} />

              <Typography
                variant={Typography.VARIANTS.BODY_SMALL_REGULAR}
                css={styles.separatorText}
              >
                <FormattedMessage {...globalMessages.OR} />
              </Typography>

              <div css={styles.lineSeparator} />
            </div>
          )}
        </Fragment>
      )}

      {!!picturesHistory?.length && (
        <div>
          <Typography
            variant={Typography.VARIANTS.HEADING_5}
            css={styles.title}
          >
            <FormattedMessage {...messages.HISTORY_TITLE} />
          </Typography>

          <div css={styles.picturesContainer}>
            {picturesHistory.map((picture) => (
              <div
                key={picture.id}
                css={styles.historyPicture}
              >
                <ProfilePictureItem
                  key={picture.id}
                  picture={picture}
                  isSelected={picture.id === value}
                  onChangeSelected={onChange}
                  onDelete={onDeleteProxy}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      {!picturesHistory?.length && hideImport && (
        <Typography
          variant={Typography.VARIANTS.HEADING_5}
          css={styles.title}
        >
          <FormattedMessage {...messages.NO_PICTURE_SELECTED} />
        </Typography>
      )}
    </Fragment>
  );
};

SelectProfilePictureFieldBase.displayName = 'SelectProfilePictureField';

export const SelectProfilePictureField = memo(SelectProfilePictureFieldBase);
