import { css, type Theme } from '@emotion/react';

export const container = css`
  margin: auto;
`;

export const card = css`
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 300px;
  padding: 8px 2px;
`;

export const text = (theme: Theme) => css`
  color: ${theme.colors.gray[0]};
`;

export const buttonContainer = css`
  display: flex;
  justify-content: center;
`;
