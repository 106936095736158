import {
  type ForwardedRef,
  forwardRef,
  memo,
  type MouseEvent,
  type ReactElement,
  useContext,
} from 'react';
import cn from 'classnames';
import { noop } from 'lodash';

import { removeButtonAppearance } from '../../../../utils/style';
import { Tooltip } from '../../../general/tooltip/Tooltip';
import { MenuContext } from '../Menu.context';
import { MenuItem, type MenuItemProps } from './MenuItem';
import { containerStyle } from './MenuItem.styles';

export type MenuItemButtonProps = MenuItemProps & {
  /** Disable the tooltip on hover when the menu is collapsed. */
  disableTooltip?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  children?: ReactElement;
};

export const MenuItemButtonBase = forwardRef(
  (
    {
      label,
      onClick = noop,
      isSelected = false,
      isDisabled = false,
      disableTooltip = false,
      children = null,
      ...props
    }: MenuItemButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const { isCollapsed } = useContext(MenuContext);

    const itemContent = (
      <li>
        <button
          ref={ref}
          type="button"
          onClick={onClick}
          disabled={isDisabled}
          className={cn({ isSelected, isCollapsed, isDisabled })}
          css={[removeButtonAppearance, containerStyle]}
        >
          <MenuItem
            {...props}
            label={label}
            isSelected={isSelected}
            isDisabled={isDisabled}
          />
        </button>
        {children}
      </li>
    );

    return isCollapsed && !disableTooltip ? (
      <Tooltip
        content={label}
        placement="right"
      >
        {itemContent}
      </Tooltip>
    ) : (
      itemContent
    );
  },
);

MenuItemButtonBase.displayName = 'MenuItemButton';

export const MenuItemButton = memo(MenuItemButtonBase);
