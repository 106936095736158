import {
  type ForwardedRef,
  forwardRef,
  memo,
  type ReactElement,
  type ReactNode,
  useCallback,
  useContext,
} from 'react';
import { css } from '@emotion/react';
import { noop, without } from 'lodash';

import { DropdownListContext } from '../list/DropdownListContext';
import { ItemRenderer } from '../renderers/item/ItemRenderer';

export type DropdownListItemProps = {
  /** Value of the item, used to determine if the item is selected. */
  value?: string;
  /** Icon from @icon-park/react. */
  icon?: ReactElement;
  /** Text label. */
  label?: ReactNode;
  /**
   * Override default inner renderer.
   * If this is specified, icon and label are ignored.
   * Signature: ({ isSelected, isDisabled }) => React.Node.
   */
  render?: (params: { isSelected: boolean; isDisabled: boolean }) => ReactNode;
  /** Specific behaviour for this item. */
  onClick?: (value?: string) => void;
  /** Is the item disabled. */
  isDisabled?: boolean;
  /** Is the item checkable. */
  isCheckable?: boolean;
};

export const DropdownListItemBase = forwardRef(
  (
    {
      value = null,
      icon = null,
      label = null,
      render = null,
      onClick = noop,
      isDisabled = false,
      isCheckable = false,
    }: DropdownListItemProps,
    ref: ForwardedRef<HTMLLIElement>,
  ) => {
    const { selectedItem, onClickItem, checkedItems, onChangeCheckedItems } =
      useContext(DropdownListContext);

    const isSelected = value !== null && selectedItem === value;
    const isChecked = isCheckable && checkedItems?.includes(value);

    const onClickProxy = useCallback(() => {
      onClick(value);
      onClickItem(value);

      if (isCheckable) {
        onChangeCheckedItems((currentCheckedItems) =>
          currentCheckedItems?.includes(value)
            ? without(currentCheckedItems, value)
            : [...currentCheckedItems, value],
        );
      }
    }, [onClickItem, value, onClick, isCheckable, onChangeCheckedItems]);

    return (
      <li
        ref={ref}
        css={css`
          display: flex;
        `}
      >
        <ItemRenderer
          icon={icon}
          label={label}
          render={render}
          onClick={onClickProxy}
          isSelected={isSelected}
          isDisabled={isDisabled}
          isCheckable={isCheckable}
          isChecked={isChecked}
        />
      </li>
    );
  },
);

DropdownListItemBase.displayName = 'DropdownListItem';

export const DropdownListItem = memo(DropdownListItemBase);
