import {
  type ForwardedRef,
  forwardRef,
  memo,
  type ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import { Checkbox, type CheckboxProps } from '../../checkbox/Checkbox';
import { CheckboxGroupContext } from '../contexts/CheckboxGroup.context';

export type CheckboxGroupCheckboxProps = Omit<
  CheckboxProps,
  'onChange' | 'checked' | 'description' | 'value' | 'label'
> & {
  /** Arbitrary value for this checkbox. */
  value: string;
  /** Checkbox label. */
  label: ReactNode;
};

export const CheckboxGroupCheckboxBase = forwardRef(
  (
    { value, label, disabled = false, ...props }: CheckboxGroupCheckboxProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const { value: selectedValue, onChange } = useContext(CheckboxGroupContext);

    const checked = useMemo(
      () => selectedValue.includes(value),
      [value, selectedValue],
    );

    const onChangeProxy: CheckboxProps['onChange'] = useCallback(
      (_, event) => onChange(event),
      [onChange],
    );

    return (
      <Checkbox
        {...props}
        disabled={disabled}
        ref={ref}
        value={value}
        checked={checked}
        onChange={onChangeProxy}
        description={label}
      />
    );
  },
);

CheckboxGroupCheckboxBase.displayName = 'CheckboxGroupCheckbox';

export const CheckboxGroupCheckbox = memo(CheckboxGroupCheckboxBase);
