export const GreenishEgg = () => (
  <svg
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8231 -1.06454e-06C5.19991 -1.64356e-06 1.9165e-05 9.13563 1.8393e-05 17.9662C1.7621e-05 26.7967 5.19991 32 11.8231 32C18.4462 32 24 26.7967 24 17.9662C24 9.13563 18.4462 -4.85529e-07 11.8231 -1.06454e-06Z"
      fill="#8ED07F"
    />
    <mask
      id="mask0_105_12637"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="32"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8231 -1.06454e-06C5.19991 -1.64356e-06 1.9165e-05 9.13563 1.8393e-05 17.9662C1.7621e-05 26.7967 5.19991 32 11.8231 32C18.4462 32 24 26.7967 24 17.9662C24 9.13563 18.4462 -4.85529e-07 11.8231 -1.06454e-06Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_105_12637)">
      <ellipse
        cx="6.07583"
        cy="12.2646"
        rx="2.02527"
        ry="2.04411"
        fill="#368D22"
      />
      <ellipse
        cx="12.1517"
        cy="17.3748"
        rx="2.02527"
        ry="2.04411"
        fill="#368D22"
      />
      <ellipse
        cx="16.2022"
        cy="9.19841"
        rx="2.02527"
        ry="2.04411"
        fill="#368D22"
      />
      <ellipse
        cx="19.2401"
        cy="16.3528"
        rx="2.02527"
        ry="2.04411"
        fill="#368D22"
      />
      <ellipse
        cx="6.07583"
        cy="22.4851"
        rx="2.02527"
        ry="2.04411"
        fill="#368D22"
      />
      <ellipse
        cx="12.1516"
        cy="24.5292"
        rx="2.02527"
        ry="2.04411"
        fill="#368D22"
      />
    </g>
  </svg>
);
