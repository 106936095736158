import {
  createContext,
  memo,
  type MutableRefObject,
  type ReactNode,
  useMemo,
} from 'react';
import { noop } from 'lodash';

import { type ContentOutlineElement } from '@eversity/types/domain';
import {
  useMemoizedBundle,
  type UseScrollSpy,
  useScrollSpy,
} from '@eversity/ui/utils';

export type ContentScrollSpyContextValue = UseScrollSpy & {
  pageTitles: string[];
  scrollContainerRef: MutableRefObject<HTMLElement>;
};

export const ContentScrollSpyContext =
  createContext<ContentScrollSpyContextValue>({
    activeTitle: null,
    onTitleEnterView: noop,
    onTitleExitView: noop,
    pageTitles: [],
    scrollContainerRef: { current: null },
  });

const getSlugsFromOutline = (outline: ContentOutlineElement[]): string[] =>
  (outline || [])
    .flatMap(({ slug, children: nodeChildren }) => [
      slug,
      ...getSlugsFromOutline(nodeChildren),
    ])
    .filter(Boolean);

export type ContentScrollSpyContextProviderProps = {
  scrollContainerRef: MutableRefObject<HTMLElement>;
  contentOutline?: ContentOutlineElement[];
  children?: ReactNode;
};

const DEFAULT_CONTENT_OUTLINE = [];

export const ContentScrollSpyContextProviderBase = ({
  scrollContainerRef,
  contentOutline = DEFAULT_CONTENT_OUTLINE,
  children = null,
}: ContentScrollSpyContextProviderProps) => {
  const pageTitles = useMemo(
    () => getSlugsFromOutline(contentOutline),
    [contentOutline],
  );

  const { activeTitle, onTitleEnterView, onTitleExitView } =
    useScrollSpy(pageTitles);

  const contextValue = useMemoizedBundle<ContentScrollSpyContextValue>({
    pageTitles,
    onTitleEnterView,
    onTitleExitView,
    activeTitle,
    scrollContainerRef,
  });

  return (
    <ContentScrollSpyContext.Provider value={contextValue}>
      {children}
    </ContentScrollSpyContext.Provider>
  );
};

ContentScrollSpyContextProviderBase.displayName =
  'ContentScrollSpyContextProvider';

export const ContentScrollSpyContextProvider = memo(
  ContentScrollSpyContextProviderBase,
);
