export enum TRACKING_EVENTS_TYPES {
  ACCESS_MAIL = 'ACCESS_MAIL',
  ACCESS_CALENDAR = 'ACCESS_CALENDAR',
  ACCESS_LAST_LESSON_HP = 'ACCESS_LAST_LESSON_HP',
  ACCESS_NOTIFICATION = 'ACCESS_NOTIFICATION',
  ACCESS_TRANSCRIPT = 'ACCESS_TRANSCRIPT',
  APPOINTMENT = 'APPOINTMENT',
  ASSIGNMENT_ATTACHMENT_DOWNLOAD = 'ASSIGNMENT_ATTACHMENT_DOWNLOAD',
  AUDIO_PLAY = 'AUDIO_PLAY',
  CLOSE_TOUR = 'CLOSE_TOUR',
  CREATE_FLASHCARDS_DECK = 'CREATE_FLASHCARDS_DECK',
  START_FLASHCARDS_DECK = 'START_FLASHCARDS_DECK',
  CREATE_HIGHLIGHT_NOTE = 'CREATE_HIGHLIGHT_NOTE',
  DELAYED_PAYMENT_CLICK = 'DELAYED_PAYMENT_CLICK',
  END_SEQUENCE = 'END_SEQUENCE',
  FEEDBACK_CAMPAIGN_ANSWER = 'FEEDBACK_CAMPAIGN_ANSWER',
  FEEDBACK_CAMPAIGN_DISMISS = 'FEEDBACK_CAMPAIGN_DISMISS',
  FINISH_TOUR = 'FINISH_TOUR',
  IMPERSONATE = 'IMPERSONATE',
  LESSON_SEQUENCE_DOWNLOAD = 'LESSON_SEQUENCE_DOWNLOAD',
  LIVE_EVENT_CONNEXION = 'LIVE_EVENT_CONNEXION',
  LIVE_EVENT_REPLAY = 'LIVE_EVENT_REPLAY',
  ON_CLICK_LESSON_RESSOURCES_LINK = 'ON_CLICK_LESSON_RESSOURCES_LINK',
  ON_CLICK_LESSON_RESSOURCE_ITEM = 'ON_CLICK_LESSON_RESSOURCE_ITEM',
  RESTART_LESSON_SEQUENCE_EXERCISE = 'RESTART_LESSON_SEQUENCE_EXERCISE',
  TEACHER_CONTACT = 'TEACHER_CONTACT',
  VIRTUAL_CLASSROOM_REGISTER = 'VIRTUAL_CLASSROOM_REGISTER',
  VISIT_PAGE = 'VISIT_PAGE',
  EASTER_2025 = 'EASTER_2025',
  EASTER_2025_IS_SEEN = 'EASTER_2025_IS_SEEN',
}

export enum EASTER_EGG_IDS {
  HOME_PAGE = 'HOME_PAGE',
  COURSE_PAGE = 'COURSE_PAGE',
  REVISIONS_PAGE = 'REVISIONS_PAGE',
  VIRTUAL_CLASSROOMS_PAGE = 'VIRTUAL_CLASSROOMS_PAGE',
  NEWS_PAGE = 'NEWS_PAGE',
  PROGRESSION_PAGE = 'PROGRESSION_PAGE',
  DOCUMENTS_PAGE = 'DOCUMENTS_PAGE',
  INTERNSHIPS_PAGE = 'INTERNSHIPS_PAGE',
  CONTACT_US_PAGE = 'CONTACT_US_PAGE',
  PROFILE = 'PROFILE',
}
