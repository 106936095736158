import { memo, type PropsWithChildren } from 'react';

import * as styles from './DrawerActions.styles';

const DrawerActionsBase = ({ children }: PropsWithChildren) => {
  return <footer css={styles.footer}>{children}</footer>;
};

DrawerActionsBase.displayName = 'DrawerActions';

export const DrawerActions = memo(DrawerActionsBase);
