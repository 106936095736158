import {
  type ACHIEVEMENT_TIERS,
  type ACHIEVEMENT_TYPES,
  ACHIEVEMENTS_DATA,
  USER_ACCOUNT_TYPES,
  USER_ROLES,
} from '@eversity/domain/constants';
import { type StudentAchievement } from '@eversity/types/domain';

/**
 * Check if the user is a test account based on the role.
 *
 * @param user - User to check.
 * @returns - True if the user is a test account.
 */
export const isUserTestAccount = ({
  roles,
}: {
  [key: string]: unknown;
  roles: USER_ROLES[];
}): boolean => {
  return !!roles?.includes(USER_ROLES.CAMPUS_TESTER);
};

/**
 * Check if the user is a student based on the account type.
 *
 * @param accountType - Account type.
 * @returns - True if the user is a student.
 */
export const isUserStudent = (accountType: USER_ACCOUNT_TYPES) =>
  accountType === USER_ACCOUNT_TYPES.STUDENT;

/**
 * Get the achievement tiers based on the value of the threshold.
 *
 * @param type - Achievement type.
 * @param value - Threshold value.
 * @returns - Corresponding achievement tiers.
 */
export const getAchievementTiersFromThreshold = (
  type: ACHIEVEMENT_TYPES,
  value: number,
) => {
  const typeTiers = ACHIEVEMENTS_DATA[type];

  const tiers = Object.entries(typeTiers).filter(
    ([, tierValue]) => value >= tierValue.threshold,
  );

  return tiers.map(([tier]) => tier as ACHIEVEMENT_TIERS);
};

/**
 * Get all existing achievements.
 *
 * @returns - All existing achievements.
 */
export const getExistingAchievements = () => {
  return Object.entries(ACHIEVEMENTS_DATA).flatMap(
    ([achievementType, tiers]) => {
      return Object.entries(tiers).map(([tier, value]) => ({
        type: achievementType as ACHIEVEMENT_TYPES,
        tier: tier as ACHIEVEMENT_TIERS,
        threshold: value.threshold,
        isHidden: value.isListedIfNotOwned,
      }));
    },
  );
};

/**
 * Get sorted achievements.
 *
 * @param achievements - Achievements to sort.
 * @returns - Sorted achievements.
 */
export const getSortedAchievements = (achievements: StudentAchievement[]) => {
  return achievements.sort((a, b) => {
    const aThreshold = ACHIEVEMENTS_DATA[a.type][a.tier].threshold;
    const bThreshold = ACHIEVEMENTS_DATA[b.type][b.tier].threshold;

    return aThreshold - bThreshold;
  });
};
