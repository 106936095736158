import 'react-loading-skeleton/dist/skeleton.css';

import { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { useTheme } from '@emotion/react';
import { Trophy } from '@icon-park/react';

import { getSortedAchievements } from '@eversity/domain/users';
import { type StudentAchievement } from '@eversity/types/domain';
import {
  OrnamentWrapper,
  removeButtonAppearance,
  Typography,
} from '@eversity/ui/design-system';
import { useBoolState } from '@eversity/ui/utils';

import { AchievementsList } from './achievements-list/AchievementsList';
import { AchievementsListSkeleton } from './achievements-list/AchievementsList.skeleton';
import messages from './AchievementsCard.messages';
import * as styles from './AchievementsCard.styles';
import { AllAchievementsDialog } from './all-achievements-dialog/AllAchievementsDialog';

export type AchievementsCardProps = {
  achievements: StudentAchievement[];
  isLoading?: boolean;
  displayDialogOnMount?: boolean;
};

export const AchievementsCardBase = ({
  achievements,
  isLoading = false,
  displayDialogOnMount = false,
}: AchievementsCardProps) => {
  const { colors } = useTheme();

  const [
    isAchievementsDialogOpen,
    onOpenAchievementsDialog,
    onCloseAchievementsDialog,
  ] = useBoolState(displayDialogOnMount);

  const sortedAchievements = useMemo(
    () => getSortedAchievements(achievements),
    [achievements],
  );
  const firstThreeAchievements = useMemo(
    () => (sortedAchievements || []).slice(0, 3),
    [sortedAchievements],
  );

  return (
    <div css={styles.cardBody}>
      <OrnamentWrapper
        element="sparkles"
        size={OrnamentWrapper.SIZES.SMALL}
      >
        <div css={styles.trophyContainer}>
          <Trophy
            size={50}
            fill={[colors.warning[500], colors.warning[100]]}
          />
        </div>
      </OrnamentWrapper>

      <div css={styles.achievementsZone}>
        <div css={styles.achievementsList}>
          {isLoading ? (
            <AchievementsListSkeleton />
          ) : (
            <AchievementsList achievements={firstThreeAchievements} />
          )}
        </div>

        {isLoading ? (
          <Skeleton width={120} />
        ) : (
          <button
            css={removeButtonAppearance}
            type="button"
            onClick={onOpenAchievementsDialog}
          >
            <Typography
              variant={Typography.VARIANTS.BODY_SMALL_REGULAR}
              css={styles.allAchievementsText}
            >
              <FormattedMessage {...messages.SEE_ALL_ACHIEVEMENTS} />
            </Typography>
          </button>
        )}

        <AllAchievementsDialog
          isOpen={isAchievementsDialogOpen}
          onRequestClose={onCloseAchievementsDialog}
          achievements={achievements}
        />
      </div>
    </div>
  );
};

export const AchievementsCard = memo(AchievementsCardBase);
