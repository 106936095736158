import { memo, useContext, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import {
  HastRenderer,
  type HastRendererDefaultRenderElementProps,
} from '@eversity/ui/design-system';

import { ContentScrollSpyContext } from '../../contexts/ContentScrollSpy.context';

export type ContentRenderRootElementProps = Omit<
  HastRendererDefaultRenderElementProps,
  'depth'
> & {
  headerHeightInPx?: number;
};

export const ContentRenderRootElementBase = ({
  node,
  path,
  headerHeightInPx = 0,
  renderChildren,
}: ContentRenderRootElementProps) => {
  const { pageTitles, onTitleEnterView, onTitleExitView, scrollContainerRef } =
    useContext(ContentScrollSpyContext);

  const { ref, inView } = useInView({
    threshold: 1,

    /**
     * Since the header is sticky, we need to specify its height to insersection-observer.
     * By default, intersection-observer uses the window as reference so we need to give it the
     * closest scroll container, otherwise we would need to also offset the app navbar.
     */
    root: scrollContainerRef.current,
    rootMargin: `${headerHeightInPx * -1}px 0px 0px 0px`,
  });

  useEffect(() => {
    if ('properties' in node && 'id' in node.properties) {
      if (pageTitles.includes(node.properties.id as string)) {
        if (inView) {
          onTitleEnterView(node.properties.id as string);
        } else {
          onTitleExitView(node.properties.id as string);
        }
      }
    }
  }, [pageTitles, inView, node, onTitleEnterView, onTitleExitView]);

  return (
    <HastRenderer.DefaultRenderElement
      ref={ref}
      node={node}
      path={path}
      depth={0}
      renderChildren={renderChildren}
    />
  );
};

ContentRenderRootElementBase.displayName = 'ContentRenderRootElement';

export default memo(ContentRenderRootElementBase);
