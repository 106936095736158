export const CourseraLogo = ({
  color = 'white',
  size = 16,
}: {
  color?: string;
  size?: number;
}) => (
  <svg
    width={size * 6.7}
    height={size}
    viewBox="0 0 100 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8291 7.05842C13.8291 3.20714 17 0.0925293 20.9647 0.0925293C24.957 0.0925293 28.1288 3.20887 28.1288 7.05842C28.1288 10.8811 24.957 14.0529 20.9647 14.0529C17 14.0529 13.8291 10.8777 13.8291 7.05842ZM24.7025 7.05842C24.7025 5.13321 23.0318 3.43394 20.9647 3.43394C18.9261 3.43394 17.2553 5.13321 17.2553 7.05842C17.2553 9.0122 18.9261 10.7115 20.9647 10.7115C23.0353 10.7115 24.7025 9.0122 24.7025 7.05842Z"
      fill={color}
    />
    <path
      d="M85.7852 7.17182C85.7852 2.95264 89.2114 0.0925293 92.3269 0.0925293C94.4503 0.0925293 95.6683 0.743499 96.4889 1.99004L96.8153 0.345299H100V13.7975H96.8153L96.4041 12.4125C95.4701 13.4322 94.3084 14.0572 92.3269 14.0572C89.2685 14.0529 85.7852 11.3624 85.7852 7.17182ZM96.6586 7.14326C96.6548 6.15604 96.259 5.21076 95.5583 4.51538C94.8575 3.81999 93.9092 3.43145 92.922 3.43524C91.9348 3.43903 90.9895 3.83483 90.2941 4.53558C89.5988 5.23632 89.2102 6.18461 89.214 7.17182C89.214 9.04076 90.8847 10.7115 92.9242 10.7115C94.9888 10.7115 96.6586 9.04076 96.6586 7.14326Z"
      fill={color}
    />
    <path
      d="M81.8722 1.90443V0.34626H78.3896V13.7976H81.8722V6.54952C81.8722 4.36894 82.949 3.23667 84.8154 3.23667C84.9392 3.23667 85.0569 3.24533 85.172 3.25658L85.8178 0.00952148C84.0025 0.0190436 82.6867 0.641447 81.8722 1.90443Z"
      fill={color}
    />
    <path
      d="M46.8761 1.9053V0.347127L43.3936 0.354917L43.423 13.8045L46.9055 13.7967L46.8865 6.54952C46.8813 4.36981 47.9555 3.23494 49.8236 3.23061C49.9428 3.23096 50.0618 3.2376 50.1803 3.25052L50.8226 0C49.0047 0.0147161 47.6872 0.639717 46.8761 1.9053Z"
      fill={color}
    />
    <path
      d="M29.3359 8.58705V0.346924H32.8176V8.16289C32.8176 9.88986 33.7802 10.9096 35.4509 10.9096C37.3198 10.9096 38.3941 9.77646 38.3941 7.59588V0.346924H41.8775V13.7974H38.3967V12.2392C37.5761 13.5135 36.2447 14.1367 34.4043 14.1367C31.2897 14.1376 29.3359 11.8722 29.3359 8.58705Z"
      fill={color}
    />
    <path
      d="M63.2461 7.07402C63.2539 3.30757 65.9495 0.0847525 70.225 0.0925434C74.1897 0.100334 76.9866 3.27641 76.9771 7.01775C76.9777 7.38766 76.9488 7.75703 76.8905 8.12232L66.6403 8.10328C67.0333 9.71772 68.3621 10.8812 70.373 10.8855C71.5901 10.8855 72.8938 10.4371 73.6885 9.41907L76.0638 11.3235C74.8424 13.0487 72.6878 14.0642 70.3955 14.0598C66.3486 14.046 63.2392 11.0101 63.2461 7.07402ZM73.4712 5.67686C73.2755 4.31779 71.8619 3.26689 70.1912 3.26429C68.5768 3.26429 67.2731 4.2217 66.7606 5.66474L73.4712 5.67686Z"
      fill={color}
    />
    <path
      d="M50.8428 10.8658L53.9383 9.24531C54.4499 10.3542 55.4731 11.0078 56.9231 11.0078C58.2588 11.0078 58.7704 10.5819 58.7704 9.98457C58.7704 7.82045 51.4375 9.13191 51.4375 4.18471C51.4375 1.45618 53.8249 0.00708008 56.7803 0.00708008C59.0258 0.00708008 61.015 1.00171 62.0954 2.84901L59.0275 4.47211C58.573 3.56231 57.7204 3.05071 56.64 3.05071C55.5891 3.05071 55.0767 3.42034 55.0767 4.04534C55.0767 6.14888 62.4096 4.81318 62.4096 9.84521C62.4096 12.4603 60.2784 14.0514 56.8391 14.0514C53.9115 14.0488 51.8651 13.0827 50.8428 10.8658Z"
      fill={color}
    />
    <path
      d="M0 7.0586C0 3.17962 3.17088 0.0641414 7.13556 0.0641414C8.32763 0.055786 9.50305 0.344171 10.5559 0.903307C11.6087 1.46244 12.5059 2.27476 13.1666 3.26705L10.1939 4.99143C9.84994 4.49991 9.39212 4.09895 8.85955 3.82279C8.32698 3.54663 7.73547 3.40347 7.13556 3.40555C5.09695 3.40555 3.42625 5.10483 3.42625 7.0586C3.42625 9.01238 5.09695 10.7116 7.13556 10.7116C7.76376 10.7157 8.38253 10.5587 8.93279 10.2556C9.48305 9.95255 9.94645 9.51348 10.2787 8.98034L13.222 10.7359C12.5693 11.7572 11.6688 12.5967 10.6043 13.1761C9.53973 13.7555 8.34583 14.056 7.13383 14.0496C3.17088 14.0531 0 10.8779 0 7.0586Z"
      fill={color}
    />
  </svg>
);
