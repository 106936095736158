import { TEACHING_UNIT_LESSON_USER_ROLES } from '@eversity/domain/constants';
import {
  type CourseViewStudent,
  type EmployeeViewMinimal,
} from '@eversity/types/domain';

import { isUserInternal } from '../users';

/**
 * Get the main teacher of a lesson.
 *
 * @param course - Course metadata.
 * @param teachingUnitId - Teaching unit id of the lesson.
 * @param lessonId - Lesson id.
 * @returns Main teacher or undefined.
 */
export function getMainTeacher(
  course: CourseViewStudent | undefined,
  teachingUnitId: string,
  lessonId: string,
): EmployeeViewMinimal {
  return course?.teachingUnits
    .find(({ id }) => id === teachingUnitId)
    ?.lessons.find(({ lesson: { id } }) => id === lessonId)
    ?.users.filter((user) => isUserInternal(user))
    .find(({ role }) => role === TEACHING_UNIT_LESSON_USER_ROLES.TEACHER)?.user;
}
