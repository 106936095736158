export const OrangeishEgg = () => (
  <svg
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1772 0C18.7997 0 24 9.13563 24 17.9662C24 26.7967 18.7997 32 12.1772 32C5.55421 32 1.52588e-05 26.7967 1.52588e-05 17.9662C1.52588e-05 9.13563 5.55421 0 12.1772 0Z"
      fill="#EE9769"
    />
    <mask
      id="mask0_105_12639"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="32"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1772 0C18.7997 0 24 9.13563 24 17.9662C24 26.7967 18.7997 32 12.1772 32C5.55421 32 1.52588e-05 26.7967 1.52588e-05 17.9662C1.52588e-05 9.13563 5.55421 0 12.1772 0Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_105_12639)">
      <line
        x1="4.53784"
        y1="-12.9705"
        x2="4.53784"
        y2="43.2425"
        stroke="#F4BA9B"
        strokeWidth="3"
      />
      <line
        x1="9.84203"
        y1="-12.9705"
        x2="9.84202"
        y2="43.2425"
        stroke="#F4BA9B"
        strokeWidth="3"
      />
      <line
        x1="20.4504"
        y1="-12.9705"
        x2="20.4504"
        y2="43.2425"
        stroke="#F4BA9B"
        strokeWidth="3"
      />
      <line
        x1="15.1462"
        y1="-12.9705"
        x2="15.1462"
        y2="43.2425"
        stroke="#F4BA9B"
        strokeWidth="3"
      />
      <line
        x1="37.4668"
        y1="21.4719"
        x2="-18.2271"
        y2="21.4719"
        stroke="#E97537"
        strokeWidth="1.96429"
      />
      <line
        x1="37.4668"
        y1="16.1183"
        x2="-18.2271"
        y2="16.1182"
        stroke="#E97537"
        strokeWidth="1.96429"
      />
      <line
        x1="37.4668"
        y1="10.7646"
        x2="-18.2271"
        y2="10.7646"
        stroke="#E97537"
        strokeWidth="1.96429"
      />
      <line
        x1="37.4668"
        y1="5.41098"
        x2="-18.2271"
        y2="5.41097"
        stroke="#E97537"
        strokeWidth="1.96429"
      />
      <line
        x1="37.4668"
        y1="26.8254"
        x2="-18.2271"
        y2="26.8254"
        stroke="#E97537"
        strokeWidth="1.96429"
      />
    </g>
  </svg>
);
