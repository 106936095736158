export const BrownishEgg = () => (
  <svg
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8229 -1.06455e-06C5.19994 -1.64356e-06 3.25164e-05 9.13584 3.17444e-05 17.9665C3.09724e-05 26.7971 5.19994 32 11.8229 32C18.4459 32 24 26.7972 24 17.9665C24 9.13584 18.4459 -4.85557e-07 11.8229 -1.06455e-06Z"
      fill="#5B2102"
    />
    <mask
      id="mask0_105_12640"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="32"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8229 -1.06455e-06C5.19994 -1.64356e-06 3.25164e-05 9.13584 3.17444e-05 17.9665C3.09724e-05 26.7971 5.19994 32 11.8229 32C18.4459 32 24 26.7972 24 17.9665C24 9.13584 18.4459 -4.85557e-07 11.8229 -1.06455e-06Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_105_12640)">
      <path
        d="M1.29283 12.4987L0.939281 12.1452L0.585728 12.4987L-0.267644 13.3521L-1.12102 12.4987L-1.47457 12.1452L-1.82812 12.4987L-2.0178 12.6884L-2.37135 13.042L-2.0178 13.3955L-1.16443 14.2489L-2.0178 15.1023L-2.37135 15.4558L-2.0178 15.8094L-1.84538 15.9818L-1.49183 16.3354L-1.13827 15.9818L-0.284903 15.1284L0.568469 15.9818L0.922022 16.3354L1.27558 15.9818L1.46525 15.7921L1.81881 15.4386L1.46525 15.085L0.611881 14.2316L1.46525 13.3783L1.81881 13.0247L1.46525 12.6712L1.29283 12.4987Z"
        fill="#B64204"
        stroke="#B64204"
      />
      <path
        d="M6.00042 17.2064L5.64686 16.8528L5.29331 17.2064L4.43994 18.0598L3.58657 17.2064L3.23301 16.8528L2.87946 17.2064L2.68978 17.3961L2.33623 17.7496L2.68978 18.1032L3.54315 18.9565L2.68978 19.8099L2.33623 20.1635L2.68978 20.517L2.8622 20.6894L3.21575 21.043L3.56931 20.6894L4.42268 19.8361L5.27605 20.6894L5.6296 21.043L5.98316 20.6894L6.17283 20.4998L6.52639 20.1462L6.17283 19.7927L5.31946 18.9393L6.17283 18.0859L6.52639 17.7324L6.17283 17.3788L6.00042 17.2064Z"
        fill="#B64204"
        stroke="#B64204"
      />
      <path
        d="M10.708 21.9139L10.3545 21.5604L10.0009 21.9139L9.14753 22.7673L8.29416 21.9139L7.94061 21.5604L7.58705 21.9139L7.39738 22.1036L7.04382 22.4571L7.39738 22.8107L8.25075 23.6641L7.39738 24.5174L7.04382 24.871L7.39738 25.2245L7.5698 25.397L7.92335 25.7505L8.2769 25.397L9.13027 24.5436L9.98364 25.397L10.3372 25.7505L10.6908 25.397L10.8804 25.2073L11.234 24.8537L10.8804 24.5002L10.0271 23.6468L10.8804 22.7934L11.234 22.4399L10.8804 22.0863L10.708 21.9139Z"
        fill="#B64204"
        stroke="#B64204"
      />
      <path
        d="M15.4156 26.6216L15.062 26.268L14.7085 26.6216L13.8551 27.4749L13.0017 26.6215L12.6482 26.268L12.2946 26.6215L12.105 26.8112L11.7514 27.1648L12.105 27.5183L12.9583 28.3717L12.105 29.2251L11.7514 29.5786L12.105 29.9322L12.2774 30.1046L12.6309 30.4582L12.9845 30.1046L13.8379 29.2512L14.6912 30.1046L15.0448 30.4582L15.3983 30.1046L15.588 29.9149L15.9416 29.5614L15.588 29.2078L14.7346 28.3544L15.588 27.5011L15.9416 27.1475L15.588 26.794L15.4156 26.6216Z"
        fill="#B64204"
        stroke="#B64204"
      />
      <path
        d="M20.1232 31.3291L19.7696 30.9755L19.4161 31.3291L18.5627 32.1824L17.7093 31.3291L17.3558 30.9755L17.0022 31.3291L16.8126 31.5187L16.459 31.8723L16.8126 32.2259L17.6659 33.0792L16.8126 33.9326L16.459 34.2861L16.8126 34.6397L16.985 34.8121L17.3385 35.1657L17.6921 34.8121L18.5454 33.9587L19.3988 34.8121L19.7524 35.1657L20.1059 34.8121L20.2956 34.6224L20.6492 34.2689L20.2956 33.9153L19.4422 33.062L20.2956 32.2086L20.6492 31.855L20.2956 31.5015L20.1232 31.3291Z"
        fill="#B64204"
        stroke="#B64204"
      />
      <path
        d="M2.0172 2.35922L1.66365 2.00567L1.31009 2.35922L0.456721 3.21259L-0.39665 2.35922L-0.750203 2.00567L-1.10376 2.35922L-1.29343 2.5489L-1.64699 2.90245L-1.29343 3.256L-0.440062 4.10938L-1.29343 4.96275L-1.64699 5.3163L-1.29343 5.66985L-1.12102 5.84227L-0.767463 6.19582L-0.413909 5.84227L0.439462 4.9889L1.29283 5.84227L1.64639 6.19583L1.99994 5.84227L2.18962 5.6526L2.54317 5.29904L2.18962 4.94549L1.33625 4.09212L2.18962 3.23875L2.54317 2.88519L2.18962 2.53164L2.0172 2.35922Z"
        fill="#B64204"
        stroke="#B64204"
      />
      <path
        d="M6.7248 7.06674L6.37124 6.71319L6.01769 7.06674L5.16432 7.92011L4.31095 7.06674L3.95739 6.71319L3.60384 7.06674L3.41416 7.25642L3.06061 7.60997L3.41416 7.96352L4.26753 8.81689L3.41416 9.67027L3.06061 10.0238L3.41416 10.3774L3.58658 10.5498L3.94013 10.9033L4.29369 10.5498L5.14706 9.69642L6.00043 10.5498L6.35398 10.9033L6.70754 10.5498L6.89721 10.3601L7.25077 10.0066L6.89721 9.65301L6.04384 8.79964L6.89721 7.94627L7.25077 7.59271L6.89721 7.23916L6.7248 7.06674Z"
        fill="#B64204"
        stroke="#B64204"
      />
      <path
        d="M11.4324 11.7744L11.0788 11.4208L10.7253 11.7744L9.8719 12.6278L9.01853 11.7744L8.66497 11.4208L8.31142 11.7744L8.12174 11.9641L7.76819 12.3176L8.12174 12.6712L8.97511 13.5245L8.12174 14.3779L7.76819 14.7315L8.12174 15.085L8.29416 15.2574L8.64771 15.611L9.00127 15.2574L9.85464 14.4041L10.708 15.2574L11.0616 15.611L11.4151 15.2574L11.6048 15.0678L11.9583 14.7142L11.6048 14.3606L10.7514 13.5073L11.6048 12.6539L11.9583 12.3004L11.6048 11.9468L11.4324 11.7744Z"
        fill="#B64204"
        stroke="#B64204"
      />
      <path
        d="M16.14 16.4819L15.7864 16.1283L15.4329 16.4819L14.5795 17.3353L13.7261 16.4819L13.3726 16.1283L13.019 16.4819L12.8293 16.6716L12.4758 17.0251L12.8293 17.3787L13.6827 18.2321L12.8293 19.0854L12.4758 19.439L12.8293 19.7925L13.0018 19.965L13.3553 20.3185L13.7089 19.965L14.5622 19.1116L15.4156 19.965L15.7692 20.3185L16.1227 19.965L16.3124 19.7753L16.6659 19.4217L16.3124 19.0682L15.459 18.2148L16.3124 17.3614L16.6659 17.0079L16.3124 16.6543L16.14 16.4819Z"
        fill="#B64204"
        stroke="#B64204"
      />
      <path
        d="M20.8476 21.1895L20.494 20.836L20.1404 21.1895L19.2871 22.0429L18.4337 21.1895L18.0801 20.836L17.7266 21.1895L17.5369 21.3792L17.1834 21.7328L17.5369 22.0863L18.3903 22.9397L17.5369 23.7931L17.1834 24.1466L17.5369 24.5002L17.7093 24.6726L18.0629 25.0261L18.4164 24.6726L19.2698 23.8192L20.1232 24.6726L20.4767 25.0261L20.8303 24.6726L21.02 24.4829L21.3735 24.1294L21.02 23.7758L20.1666 22.9224L21.02 22.0691L21.3735 21.7155L21.02 21.362L20.8476 21.1895Z"
        fill="#B64204"
        stroke="#B64204"
      />
      <path
        d="M25.5551 25.8971L25.2016 25.5435L24.848 25.8971L23.9947 26.7504L23.1413 25.8971L22.7877 25.5435L22.4342 25.8971L22.2445 26.0867L21.891 26.4403L22.2445 26.7938L23.0979 27.6472L22.2445 28.5006L21.891 28.8541L22.2445 29.2077L22.4169 29.3801L22.7705 29.7337L23.124 29.3801L23.9774 28.5267L24.8308 29.3801L25.1843 29.7337L25.5379 29.3801L25.7276 29.1904L26.0811 28.8369L25.7276 28.4833L24.8742 27.63L25.7276 26.7766L26.0811 26.423L25.7276 26.0695L25.5551 25.8971Z"
        fill="#B64204"
        stroke="#B64204"
      />
      <path
        d="M7.44915 -3.07266L7.09559 -3.42622L6.74204 -3.07266L5.88867 -2.21929L5.0353 -3.07266L4.68174 -3.42622L4.32819 -3.07266L4.13851 -2.88299L3.78496 -2.52943L4.13851 -2.17588L4.99188 -1.32251L4.13851 -0.469138L3.78496 -0.115585L4.13851 0.237969L4.31093 0.410386L4.66448 0.76394L5.01804 0.410386L5.87141 -0.442985L6.72478 0.410387L7.07833 0.76394L7.43189 0.410387L7.62156 0.220711L7.97512 -0.132843L7.62156 -0.486396L6.76819 -1.33977L7.62156 -2.19314L7.97512 -2.54669L7.62156 -2.90025L7.44915 -3.07266Z"
        fill="#B64204"
        stroke="#B64204"
      />
      <path
        d="M12.1567 1.63486L11.8032 1.2813L11.4496 1.63486L10.5963 2.48823L9.74289 1.63486L9.38934 1.2813L9.03579 1.63486L8.84611 1.82453L8.49256 2.17809L8.84611 2.53164L9.69948 3.38501L8.84611 4.23838L8.49255 4.59193L8.84611 4.94549L9.01853 5.11791L9.37208 5.47146L9.72563 5.11791L10.579 4.26453L11.4324 5.11791L11.7859 5.47146L12.1395 5.11791L12.3292 4.92823L12.6827 4.57468L12.3292 4.22112L11.4758 3.36775L12.3292 2.51438L12.6827 2.16083L12.3292 1.80727L12.1567 1.63486Z"
        fill="#B64204"
        stroke="#B64204"
      />
      <path
        d="M16.8643 6.3425L16.5108 5.98894L16.1572 6.3425L15.3038 7.19587L14.4505 6.3425L14.0969 5.98894L13.7434 6.3425L13.5537 6.53217L13.2001 6.88573L13.5537 7.23928L14.4071 8.09265L13.5537 8.94602L13.2001 9.29958L13.5537 9.65313L13.7261 9.82555L14.0797 10.1791L14.4332 9.82555L15.2866 8.97218L16.14 9.82555L16.4935 10.1791L16.8471 9.82555L17.0367 9.63587L17.3903 9.28232L17.0367 8.92876L16.1834 8.07539L17.0367 7.22202L17.3903 6.86847L17.0367 6.51492L16.8643 6.3425Z"
        fill="#B64204"
        stroke="#B64204"
      />
      <path
        d="M21.5719 11.05L21.2184 10.6965L20.8648 11.05L20.0114 11.9034L19.1581 11.05L18.8045 10.6965L18.451 11.05L18.2613 11.2397L17.9077 11.5932L18.2613 11.9468L19.1147 12.8002L18.2613 13.6535L17.9077 14.0071L18.2613 14.3606L18.4337 14.5331L18.7873 14.8866L19.1408 14.5331L19.9942 13.6797L20.8476 14.5331L21.2011 14.8866L21.5547 14.5331L21.7443 14.3434L22.0979 13.9898L21.7443 13.6363L20.891 12.7829L21.7443 11.9295L22.0979 11.576L21.7443 11.2224L21.5719 11.05Z"
        fill="#B64204"
        stroke="#B64204"
      />
      <path
        d="M26.2795 15.7577L25.926 15.4041L25.5724 15.7577L24.719 16.611L23.8657 15.7577L23.5121 15.4041L23.1586 15.7577L22.9689 15.9473L22.6153 16.3009L22.9689 16.6544L23.8223 17.5078L22.9689 18.3612L22.6153 18.7147L22.9689 19.0683L23.1413 19.2407L23.4949 19.5943L23.8484 19.2407L24.7018 18.3873L25.5551 19.2407L25.9087 19.5943L26.2623 19.2407L26.4519 19.051L26.8055 18.6975L26.4519 18.3439L25.5986 17.4906L26.4519 16.6372L26.8055 16.2836L26.4519 15.9301L26.2795 15.7577Z"
        fill="#B64204"
        stroke="#B64204"
      />
    </g>
  </svg>
);
