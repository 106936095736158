import { css } from '@emotion/react';

export const container = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const tilesContainer = css`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
`;

export const titleContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
