export enum COURSE_VIEWS {
  MINIMAL = 'MINIMAL',
  STUDENT = 'STUDENT',
  FULL = 'FULL',
  EXTERNAL = 'EXTERNAL',
}

export enum COURSE_DIPLOMA_TYPES {
  ADVANCED_TECHNICIAN_CERTIFICATE = 'ADVANCED_TECHNICIAN_CERTIFICATE', // This is BTS in French.
  BACHELOR = 'BACHELOR',
  CAP = 'CAP',
  CQP = 'CQP',
  LICENSE = 'LICENSE',
  MASTER = 'MASTER',
  PROFESSIONAL_DIPLOMA = 'PROFESSIONAL_DIPLOMA',
  SKILLS_TRAINING = 'SKILLS_TRAINING',
}

export const COURSE_DIPLOMA_TYPES_BY_FEDE_ORGANISM = [
  COURSE_DIPLOMA_TYPES.BACHELOR,
  COURSE_DIPLOMA_TYPES.MASTER,
];

export enum TEACHING_UNIT_USER_ROLES {
  MANAGER = 'MANAGER',
}

export enum TEACHING_UNIT_LESSON_USER_ROLES {
  TEACHER = 'TEACHER',
}

export const COURSE_CODE_PREFIX = 'F_';

export const COURSE_CODE_MAX_LENGTH = 12;

export const CLASS_CODE_PREFIX = 'G_';

export const CLASS_CODE_MAX_LENGTH = 12;

export const TEACHING_UNIT_DEFAULT_COEFFICIENT = 1;

export const TEACHING_UNIT_CODE_PREFIXES = ['UE_', 'CCP_', 'UC_', 'BC_'];

export const TEACHING_UNIT_CODE_EXTERNAL_PREFIXES = ['CO_'];

export const TEACHING_UNIT_CODE_MAX_LENGTH = 9;

export const TEACHING_UNIT_TITLE_MAX_LENGTH = 100;

export const TEACHING_UNIT_MINIMUM_COEFFICIENT = 0;

export const TEACHING_UNIT_LESSON_MINIMUM_AVAILABILITY_DAYS = 0;

export const TEACHING_UNIT_LESSON_DEFAULT_AVAILABILITY_DAYS = 90;

export const ASSIGNMENT_MINIMUM_COEFFICIENT = 0;

export const EXAM_DEFAULT_COEFFICIENT = 1;

export const LESSON_ASSIGNMENT_DEFAULT_COEFFICIENT = 1;

export const COURSE_INFORMATION_MAX_PAGES = 6;

export const CLASS_MINIMUM_LESSONS_COUNT = 1;

export const OVERALL_AVERAGE_GRADING_SCALE = 20;

export enum INFORMATION_TYPES {
  INTERNSHIPS = 'INTERNSHIPS',
  EXAMS = 'EXAMS',
  WORK_STUDY = 'WORK_STUDY',
  GUIDE = 'GUIDE',
}

export const INFORMATION_TYPES_DB = {
  [INFORMATION_TYPES.EXAMS]: 'examsInformation',
  [INFORMATION_TYPES.GUIDE]: 'guideInformation',
  [INFORMATION_TYPES.INTERNSHIPS]: 'internshipsInformation',
  [INFORMATION_TYPES.WORK_STUDY]: 'workStudyInformation',
};

export enum TEACHING_UNIT_ORIGIN {
  DIDASKILL = 'DIDASKILL',
  COURSERA = 'COURSERA',
}
