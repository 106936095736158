import {
  type ChangeEvent,
  type ChangeEventHandler,
  type ForwardedRef,
  forwardRef,
  memo,
  type ReactNode,
  useCallback,
} from 'react';
import { noop } from 'lodash';

import { Typography } from '../../general/typography/Typography';
import {
  CheckboxControl,
  type CheckboxControlProps,
} from '../checkbox-control/CheckboxControl';
import * as styles from './Checkbox.styles';

export type CheckboxProps = Omit<CheckboxControlProps, 'onChange'> & {
  /** Checkbox label. */
  description?: ReactNode;
  /** On change handler. (checked, event) => null. */
  onChange?: (checked: boolean, event: ChangeEvent<HTMLInputElement>) => void;
};

export const CheckboxBase = forwardRef(
  (
    {
      description = null,
      onChange = noop,
      className = null,
      indeterminate = false,
      disabled = false,
      checked = false,
      ...props
    }: CheckboxProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const onChangeProxy: ChangeEventHandler<HTMLInputElement> = useCallback(
      (event) => onChange(event.target.checked, event),
      [onChange],
    );

    return (
      // Input is nested.
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label
        className={className}
        css={styles.container}
      >
        <div css={styles.box}>
          <CheckboxControl
            {...props}
            indeterminate={indeterminate}
            disabled={disabled}
            checked={checked}
            ref={ref}
            onChange={onChangeProxy}
          />
        </div>

        {!!description && (
          <Typography variant={Typography.VARIANTS.BODY_MEDIUM_REGULAR}>
            {description}
          </Typography>
        )}
      </label>
    );
  },
);

CheckboxBase.displayName = 'Checkbox';

export const Checkbox = memo(CheckboxBase);
