import { defineMessages } from 'react-intl';

export default defineMessages({
  TITLE: {
    defaultMessage: 'Problème de synchronisation',
  },

  BODY: {
    defaultMessage:
      "Attention, les événements du <b>calendrier</b> n'ont pas pu être récupérés. Veuillez vérifier votre connexion ou réessayer plus tard.",
  },
});
