import { type ReactElement } from 'react';
import { type Theme } from '@emotion/react';
import { EggOne, Fire } from '@icon-park/react';

import {
  ACHIEVEMENT_TIERS,
  ACHIEVEMENT_TYPES,
} from '@eversity/domain/constants';
import { type Color } from '@eversity/types/web';

export enum ACHIEVEMENT_SIZES {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export const TYPE_ICON_MAPPING = (
  theme: Theme,
): Record<
  ACHIEVEMENT_TYPES,
  { icon: ReactElement; overrideFillColors?: string[] }
> => ({
  [ACHIEVEMENT_TYPES.CONNECTION_STREAK]: { icon: <Fire /> },
  [ACHIEVEMENT_TYPES.EASTER_EGGS_2025]: {
    icon: <EggOne />,
    overrideFillColors: [theme.colors.warning[15], 'transparent'],
  },
});

export const ICON_SIZE_MAPPING = {
  [ACHIEVEMENT_SIZES.SMALL]: 12,
  [ACHIEVEMENT_SIZES.MEDIUM]: 16,
  [ACHIEVEMENT_SIZES.LARGE]: 20,
};

export const ACHIEVEMENT_SIZE_MAPPING = Object.fromEntries(
  Object.entries(ICON_SIZE_MAPPING).map(([key, value]) => [
    key,
    (value * 2.5).toString(),
  ]),
);

export const SPARKLE_SIZE_MAPPING = Object.fromEntries(
  Object.entries(ICON_SIZE_MAPPING).map(([key, value]) => [key, value / 2.67]),
);

export const ICON_COLOR_MAPPING = (
  theme: Theme,
): Record<ACHIEVEMENT_TIERS, Color> => ({
  [ACHIEVEMENT_TIERS.NEUTRAL]: theme.colors.primary,
  [ACHIEVEMENT_TIERS.BRONZE]: theme.colors.secondary,
  [ACHIEVEMENT_TIERS.SILVER]: theme.colors.tertiary,
  [ACHIEVEMENT_TIERS.GOLD]: theme.colors.warning,
});
