export const AlienEgg = () => (
  <svg
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.823 -1.06454e-06C5.19989 -1.64356e-06 3.9062e-06 9.13584 3.1342e-06 17.9665C2.3622e-06 26.7971 5.19989 32 11.823 32C18.4462 32 24 26.7972 24 17.9665C24 9.13584 18.4462 -4.85529e-07 11.823 -1.06454e-06Z"
      fill="#393D41"
    />
    <mask
      id="mask0_105_12642"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="32"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.823 -1.06454e-06C5.19989 -1.64356e-06 3.9062e-06 9.13584 3.1342e-06 17.9665C2.3622e-06 26.7971 5.19989 32 11.823 32C18.4462 32 24 26.7972 24 17.9665C24 9.13584 18.4462 -4.85529e-07 11.823 -1.06454e-06Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_105_12642)">
      <path
        d="M4.05054 3.06616L6.24817 3.43584L6.24817 8.2417L9.54463 9.72043L10.2772 14.1566"
        stroke="#69C055"
        strokeWidth="1.45"
      />
      <path
        d="M8.44583 18.9625L8.44583 15.2657L9.91092 13.4173M9.91092 12.3082L12.4748 16.005L15.0387 17.1141L15.7713 20.4412"
        stroke="#69C055"
        strokeWidth="1.45"
      />
    </g>
  </svg>
);
