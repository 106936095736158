import { css, type Theme } from '@emotion/react';

export const divider = css`
  padding: 10px 10px 10px 0px;
`;

export const footer = css`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const legendItem = (theme: Theme) => css`
  width: 20px;
  height: 20px;
  background-color: ${theme.colors.warning[200]};
  border-radius: 4px;
  margin-right: 5px;
`;
