import { type ViewCorrectorSubmissionFeesRow } from '@eversity/domain/analytics';
import { type USER_ROLES } from '@eversity/domain/constants';
import {
  type CourseViewMinimal,
  type CreateEmployeeBody,
  type CreateEmployeeQuery,
  type EmployeeViewFull,
  type EmployeeViewMinimal,
  type GetEmployeeQuery,
  type GetEmployeesQuery,
  type GetEmployeesStatisticsQuery,
  type UpdateEmployeeBody,
} from '@eversity/types/domain';
import { type TypedResponse } from '@eversity/types/misc';
import { type EmployeeStatistics } from '@eversity/types/node';

import { HttpRepository } from '../httpRepository';

const e = encodeURIComponent;

const BASE_EMPLOYEES_URI = '/api/v1/users/employees';

export class EmployeesRepository extends HttpRepository {
  async getEmployees(query?: GetEmployeesQuery): Promise<{
    users: EmployeeViewFull[] | EmployeeViewMinimal[];
    count: number;
  }> {
    const {
      body: { count, users },
    } = await this.http.get(BASE_EMPLOYEES_URI).query(query);

    return {
      count,
      users,
    };
  }

  /**
   * Create employee.
   *
   * @param params - Params.
   * @param params.firstName - First name.
   * @param params.lastName - Last name.
   * @param params.email - Email address.
   * @param params.roles - User roles (USER_ROLES enum).
   * @param query - Query.
   * @param query.view - User view to return (USER_VIEWS enum). Defaults to FULL.
   * @returns Created user employee.
   */
  async createEmployee(
    params: CreateEmployeeBody,
    query?: CreateEmployeeQuery,
  ): Promise<EmployeeViewFull | EmployeeViewMinimal> {
    const { body: user } = await this.http
      .post(BASE_EMPLOYEES_URI)
      .query(query)
      .send(params);

    return user;
  }

  /**
   * Update an employee.
   *
   * @param userId - Employee user id.
   * @param params - Params.
   * @returns Updated user employee.
   */
  async updateEmployee(
    userId: string,
    params: UpdateEmployeeBody,
  ): Promise<EmployeeViewFull> {
    const { body: user } = await this.http
      .patch(`${BASE_EMPLOYEES_URI}/${e(userId)}`)
      .send(params);

    return user;
  }

  /**
   * Get a single employee.
   *
   * @param userId - Employee id.
   * @param query - Query params.
   * @returns The employee data.
   */
  async getEmployee(
    userId: string,
    query?: GetEmployeeQuery,
  ): Promise<EmployeeViewFull | EmployeeViewMinimal | null> {
    const { body: employee } = await this.http
      .get(`${BASE_EMPLOYEES_URI}/${e(userId)}`)
      .query(query);

    return employee;
  }

  async deleteProfilePicture(
    userId: string,
    pictureId: string,
  ): Promise<EmployeeViewFull> {
    const { body: updatedEmployee } = await this.http
      .delete(
        `${BASE_EMPLOYEES_URI}/${e(userId)}/profile-pictures/${e(pictureId)}`,
      )
      .send();

    return updatedEmployee;
  }

  /**
   * Add role to an employee.
   *
   * @param userId - Employee user id.
   * @param role - Role to add.
   * @returns - New role.
   */
  async addRoleToEmployee(
    userId: string,
    role: USER_ROLES,
  ): Promise<USER_ROLES> {
    const { body: updatedEmployee } = await this.http
      .post(`${BASE_EMPLOYEES_URI}/${e(userId)}/roles`)
      .send({ role });

    return updatedEmployee;
  }

  /**
   * Remove role from an employee.
   *
   * @param userId - Employee user id.
   * @param role - Role to remove.
   */
  async removeRoleFromEmployee(
    userId: string,
    role: USER_ROLES,
  ): Promise<boolean> {
    const { status } = await this.http
      .delete(`${BASE_EMPLOYEES_URI}/${e(userId)}/roles/${e(role)}`)
      .send();

    return status === 204;
  }

  /**
   * Get user's statistics (for employees).
   *
   * @param query - Query.
   * @param query.role - Role.
   * @param query.courseId - Course id.
   * @returns The user's statistics.
   */
  async getUserStatistics(
    query: GetEmployeesStatisticsQuery,
  ): Promise<EmployeeStatistics> {
    const { body } = await this.http
      .get(`${BASE_EMPLOYEES_URI}/me/statistics`)
      .query(query);

    return body;
  }

  /**
   * Get the user's courses.
   *
   * @returns - The user's courses.
   */
  async getCurrentEmployeeCourses(): Promise<CourseViewMinimal[]> {
    const { body } = await this.http.get(`${BASE_EMPLOYEES_URI}/me/courses`);

    return body;
  }

  /**
   * Gives the total correction fee for one corrector.
   *
   * @returns - The user's courses.
   */
  async getCurrentEmployeeFees(query: {
    limit?: number;
    offset?: number;
  }): Promise<
    TypedResponse<
      Pick<
        ViewCorrectorSubmissionFeesRow,
        'correctorId' | 'year' | 'month' | 'totalAmount' | 'submissionsCount'
      >[]
    >
  > {
    const res = await this.http
      .get(`${BASE_EMPLOYEES_URI}/me/correction-fees`)
      .query(query);

    return res;
  }
}
