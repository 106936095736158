import { TinyColor } from '@ctrl/tinycolor';
import { css, type Theme } from '@emotion/react';

import { removeButtonAppearance } from '@eversity/ui/design-system';

export const buttonStyle = (theme: Theme) => css`
  ${removeButtonAppearance};

  position: relative;
  width: 125px;
  height: 125px;
  border-radius: 4px;
  opacity: 0.5;

  transition: ${theme.transitions.default()};

  &.SELECTED {
    opacity: 1;
    box-shadow: 0 0 0 4px
      ${new TinyColor(theme.colors.primary[500]).setAlpha(0.15).toRgbString()};
  }
`;

export const imageStyle = (theme: Theme, picture: string) => css`
  width: 100%;
  height: 100%;
  background-image: url(${picture});
  background-position: center;
  background-size: cover;
  border-radius: 4px;

  &.SELECTED {
    background-image:
      linear-gradient(
        ${new TinyColor('#000').setAlpha(0.5).toRgbString()},
        ${new TinyColor('#000').setAlpha(0.5).toRgbString()}
      ),
      url(${picture});
  }
`;
