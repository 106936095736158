import { type ForwardedRef, forwardRef, memo } from 'react';

import { Embedded, type EmbeddedProps } from '../embedded/Embedded';
import * as styles from './LinkedInEmbedded.styles';

export type LinkedInEmbeddedProps = EmbeddedProps;

export const LinkedInEmbeddedBase = forwardRef(
  (
    { src, title, children = null, ...props }: LinkedInEmbeddedProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => (
    <Embedded
      {...props}
      ref={ref}
      css={styles.linkedInEmbedded}
      src={src}
      title={title}
    >
      {children}
    </Embedded>
  ),
);

LinkedInEmbeddedBase.displayName = 'LinkedInEmbedded';

export const LinkedInEmbedded = memo(LinkedInEmbeddedBase);
