import {
  type ForwardedRef,
  forwardRef,
  memo,
  type MemoExoticComponent,
  type ReactElement,
  type ReactNode,
} from 'react';
import { css } from '@emotion/react';

import {
  ELEMENT_VARIANTS,
  SimpleTableOfContentsElement,
  type SimpletableTableOfContentsElementProps,
} from './element/SimpleTableOfContentsElement';

export type SimpleTableOfContentsProps = {
  children?: ReactNode;
};

type ElementProps = Omit<SimpletableTableOfContentsElementProps, 'variant'>;

export const SimpleTableOfContentsBase = forwardRef(
  (
    { children = null }: SimpleTableOfContentsProps,
    ref: ForwardedRef<HTMLUListElement>,
  ) => (
    <ul
      ref={ref}
      css={css`
        list-style-type: none;
        display: flex;
        flex-direction: column;
        gap: 4px;
        overflow: auto;

        overflow-wrap: anywhere;
      `}
    >
      {children}
    </ul>
  ),
);

SimpleTableOfContentsBase.displayName = 'SimpleTableOfContents';

export const SimpleTableOfContents: MemoExoticComponent<
  typeof SimpleTableOfContentsBase
> & {
  Section?: (props: ElementProps) => ReactElement;
  SubSection?: (props: ElementProps) => ReactElement;
  Item?: (props: ElementProps) => ReactElement;
} = memo(SimpleTableOfContentsBase);

SimpleTableOfContents.Section = (props: ElementProps) => (
  <SimpleTableOfContentsElement
    {...props}
    variant={ELEMENT_VARIANTS.SECTION}
  />
);

SimpleTableOfContents.SubSection = (props: ElementProps) => (
  <SimpleTableOfContentsElement
    {...props}
    variant={ELEMENT_VARIANTS.SUB_SECTION}
  />
);

SimpleTableOfContents.Item = (props: ElementProps) => (
  <SimpleTableOfContentsElement
    {...props}
    variant={ELEMENT_VARIANTS.ITEM}
  />
);
