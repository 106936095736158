export const GoldenEgg = () => (
  <svg
    width="24"
    height="32"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.823 0C5.19989 0 7.62939e-06 9.13562 7.62939e-06 17.9662C7.62939e-06 26.7967 5.19989 32 11.823 32C18.4457 32 24 26.7967 24 17.9662C24 9.13562 18.4457 0 11.823 0Z"
      fill="url(#paint0_linear_105_12635)"
    />
    <mask
      id="mask0_105_12635"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="32"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.823 0C5.19989 0 7.62939e-06 9.13562 7.62939e-06 17.9662C7.62939e-06 26.7967 5.19989 32 11.823 32C18.4457 32 24 26.7967 24 17.9662C24 9.13562 18.4457 0 11.823 0Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_105_12635)">
      <path
        d="M13.5832 4.01387C13.3366 3.34127 12.3854 3.34128 12.1388 4.01387L11.6152 5.4419C11.5379 5.65268 11.3724 5.81918 11.1621 5.89773L9.72729 6.4336C9.06049 6.68263 9.06049 7.6258 9.72729 7.87483L11.1621 8.4107C11.3724 8.48925 11.5379 8.65575 11.6152 8.86653L12.1388 10.2946C12.3854 10.9672 13.3366 10.9672 13.5832 10.2946L14.1068 8.86653C14.184 8.65575 14.3495 8.48925 14.5599 8.4107L15.9947 7.87483C16.6615 7.6258 16.6615 6.68263 15.9947 6.4336L14.5599 5.89773C14.3495 5.81918 14.184 5.65268 14.1068 5.4419L13.5832 4.01387Z"
        fill="#FADD99"
      />
      <path
        d="M8.37559 9.75221C8.17831 9.21413 7.4173 9.21413 7.22003 9.75221L6.80119 10.8946C6.73937 11.0633 6.60697 11.1965 6.43872 11.2593L5.29085 11.688C4.75741 11.8872 4.75741 12.6418 5.29085 12.841L6.43872 13.2697C6.60697 13.3325 6.73937 13.4657 6.80119 13.6343L7.22003 14.7768C7.4173 15.3148 8.17831 15.3148 8.37559 14.7768L8.79442 13.6343C8.85625 13.4657 8.98865 13.3325 9.1569 13.2697L10.3048 12.841C10.8382 12.6418 10.8382 11.8872 10.3048 11.688L9.1569 11.2593C8.98865 11.1965 8.85625 11.0633 8.79442 10.8946L8.37559 9.75221Z"
        fill="#FADD99"
      />
      <path
        d="M14.4514 12.8184C14.2541 12.2803 13.4931 12.2803 13.2959 12.8184L13.1505 13.2148C13.0887 13.3834 12.9563 13.5166 12.788 13.5794L12.3793 13.7321C11.8459 13.9313 11.8459 14.6859 12.3793 14.8851L12.788 15.0377C12.9563 15.1006 13.0887 15.2338 13.1505 15.4024L13.2959 15.7988C13.4931 16.3369 14.2541 16.3369 14.4514 15.7988L14.5967 15.4024C14.6586 15.2338 14.791 15.1006 14.9592 15.0377L15.368 14.8851C15.9014 14.6859 15.9014 13.9313 15.368 13.7321L14.9592 13.5794C14.791 13.5166 14.6586 13.3834 14.5967 13.2148L14.4514 12.8184Z"
        fill="#FADD99"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_105_12635"
        x1="12"
        y1="32"
        x2="12"
        y2="0"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C28700" />
        <stop
          offset="1"
          stopColor="#F2A900"
        />
      </linearGradient>
    </defs>
  </svg>
);
