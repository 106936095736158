import { defineMessages } from 'react-intl';

import { FLASHCARDS_DECKS_STATUSES } from '@eversity/domain/constants';

export const tagMessages = defineMessages<FLASHCARDS_DECKS_STATUSES>({
  [FLASHCARDS_DECKS_STATUSES.READY]: {
    defaultMessage: 'Prêt',
  },

  [FLASHCARDS_DECKS_STATUSES.IN_PROGRESS]: {
    defaultMessage: 'En cours',
  },

  [FLASHCARDS_DECKS_STATUSES.COMPLETED]: {
    defaultMessage: 'Terminé',
  },

  [FLASHCARDS_DECKS_STATUSES.EMPTY]: {
    defaultMessage: 'Vide',
  },
});
