import {
  cloneElement,
  memo,
  type MemoExoticComponent,
  type ReactNode,
} from 'react';
import { useTheme } from '@emotion/react';
import { SignalStrength } from '@icon-park/react';
import cn from 'classnames';

import { type LESSON_RESOURCE_TYPES } from '@eversity/domain/constants';

import { LESSON_RESOURCE_ICON_MAPPING, SIZES } from './constants';
import * as styles from './ResourcePicture.styles';

export type ResourcePictureProps = {
  picture?: { href: string } | null;
  type?: LESSON_RESOURCE_TYPES;
  children?: ReactNode;
  size?: SIZES;
};

export const ResourcePictureBase = ({
  picture = null,
  type,
  children = null,
  size = SIZES.MEDIUM,
}: ResourcePictureProps) => {
  const theme = useTheme();

  const icon = (type && LESSON_RESOURCE_ICON_MAPPING[type]) ?? (
    <SignalStrength />
  );

  return (
    <div
      className={cn(size)}
      css={styles.container(picture?.href)}
    >
      {!picture &&
        cloneElement(icon, {
          size: 48,
          strokeWidth: 2,
          fill: [theme.colors.primary[500], theme.colors.primary[50]],
        })}

      {children}
    </div>
  );
};

ResourcePictureBase.displayName = 'ResourcePicture';

export const ResourcePicture: MemoExoticComponent<
  typeof ResourcePictureBase
> & {
  SIZES?: typeof SIZES;
} = memo(ResourcePictureBase);

ResourcePicture.SIZES = SIZES;
