import { css } from '@emotion/react';

export const container = css`
  display: flex;
  flex-direction: column;

  height: 100%;
  gap: 40px;
  flex: 1;
`;

export const item = css`
  display: flex;
  flex-direction: column;
  gap: 20px;

  &.medal-item {
    margin-top: 20px;
  }
`;
