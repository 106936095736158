import { defineMessages } from 'react-intl';

import {
  COURSE_DIPLOMA_TYPES,
  TEACHING_UNIT_ORIGIN,
} from '@eversity/domain/constants';
import { type TrainingType } from '@eversity/types/domain';

export const diplomaTypesMessages = defineMessages<COURSE_DIPLOMA_TYPES>({
  [COURSE_DIPLOMA_TYPES.ADVANCED_TECHNICIAN_CERTIFICATE]: {
    defaultMessage: 'BTS',
  },

  [COURSE_DIPLOMA_TYPES.PROFESSIONAL_DIPLOMA]: {
    defaultMessage: 'Titre Professionnel',
  },

  [COURSE_DIPLOMA_TYPES.LICENSE]: {
    defaultMessage: 'Licence',
  },

  [COURSE_DIPLOMA_TYPES.BACHELOR]: {
    defaultMessage: 'Bachelor Européen',
  },

  [COURSE_DIPLOMA_TYPES.MASTER]: {
    defaultMessage: 'Mastère Européen',
  },

  [COURSE_DIPLOMA_TYPES.SKILLS_TRAINING]: {
    defaultMessage: 'Formation qualifiante',
  },

  [COURSE_DIPLOMA_TYPES.CQP]: {
    defaultMessage: 'CQP',
  },

  [COURSE_DIPLOMA_TYPES.CAP]: {
    defaultMessage: 'CAP',
  },
});

export const courseTypesMessages = defineMessages<TrainingType>({
  continuous: {
    defaultMessage: 'continue',
  },
  scholar: {
    defaultMessage: 'initiale',
  },
});

export const teachingUnitOriginMessages = defineMessages<TEACHING_UNIT_ORIGIN>({
  [TEACHING_UNIT_ORIGIN.DIDASKILL]: {
    defaultMessage: 'Didaskill',
  },

  [TEACHING_UNIT_ORIGIN.COURSERA]: {
    defaultMessage: 'Coursera',
  },
});
