import {
  type ChangeEvent,
  cloneElement,
  type ForwardedRef,
  forwardRef,
  type HTMLProps,
  memo,
  type MemoExoticComponent,
  type ReactElement,
  type ReactNode,
  useCallback,
} from 'react';
import { css, useTheme } from '@emotion/react';
import cn from 'classnames';
import { noop } from 'lodash';

import { useFocus } from '@eversity/ui/utils';

import { type TYPOGRAPHY_VARIANTS } from '../../../config/typography/constants';
import { ICON_SIZES } from '../../general/icon/constants';
import { Typography } from '../../general/typography/Typography';
import { TEXT_MAX_LENGTH_SIZES } from '../text-max-length/constants';
import { TextMaxLength } from '../text-max-length/TextMaxLength';
import { INPUT_SIZES } from './constants';
import { getIconFills, inputStyle, prefixStyle } from './Input.styles';

const TYPOGRAPHY_SIZE_MAPPING: Record<INPUT_SIZES, TYPOGRAPHY_VARIANTS> = {
  [INPUT_SIZES.SMALL]: Typography.VARIANTS.BODY_SMALL_REGULAR,
  [INPUT_SIZES.MEDIUM]: Typography.VARIANTS.BODY_MEDIUM_REGULAR,
  [INPUT_SIZES.LARGE]: Typography.VARIANTS.BODY_LARGE_REGULAR,
};

const ICON_SIZE_MAPPING: Record<
  INPUT_SIZES,
  (typeof ICON_SIZES)[keyof typeof ICON_SIZES]
> = {
  [INPUT_SIZES.SMALL]: ICON_SIZES.SMALL,
  [INPUT_SIZES.MEDIUM]: ICON_SIZES.MEDIUM,
  [INPUT_SIZES.LARGE]: ICON_SIZES.LARGE,
};

const INPUT_MAX_LENGTH_SIZE_MAPPING: Record<
  INPUT_SIZES,
  TEXT_MAX_LENGTH_SIZES
> = {
  [INPUT_SIZES.SMALL]: TEXT_MAX_LENGTH_SIZES.SMALL,
  [INPUT_SIZES.MEDIUM]: TEXT_MAX_LENGTH_SIZES.MEDIUM,
  [INPUT_SIZES.LARGE]: TEXT_MAX_LENGTH_SIZES.LARGE,
};

export type InputProps = Omit<
  HTMLProps<HTMLInputElement>,
  'value' | 'onChange' | 'size' | 'prefix' | 'ref'
> & {
  /** Input size. */
  size?: INPUT_SIZES;
  /** Current input value. */
  value?: string;
  /** If maxLength is > 0, show the maxLength counter. */
  showMaxLength?: boolean;
  /** Prefix in a block before the input. */
  prefix?: ReactNode;
  /** Icon inside the input on the left. */
  iconLeft?: ReactElement;
  /** Icon inside the input on the right. */
  iconRight?: ReactElement;
  /** Content inside the input on the right. */
  rightContent?: ReactElement;
  /** The input has an error. */
  hasError?: boolean;
  /** The input has a warning. */
  hasWarning?: boolean;
  /** Event handler. Called with the new value directly. */
  onChange?: (value: string, event: ChangeEvent<HTMLInputElement>) => void;
};

export const InputBase = forwardRef(
  (
    {
      size = INPUT_SIZES.MEDIUM,
      prefix = null,
      iconLeft = null,
      iconRight = null,
      rightContent = null,
      onChange = noop,
      hasError = false,
      hasWarning = false,
      className = null,
      maxLength = null,
      showMaxLength = true,
      ...props
    }: InputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const theme = useTheme();

    const { isFocused, ...focusProps } = useFocus(props);

    const hasPrefix = !!prefix;
    const hasIconLeft = !!iconLeft;
    const hasIconRight = !!iconRight;

    const iconProps = {
      size: ICON_SIZE_MAPPING[size],
      fill: getIconFills(theme, props.disabled, isFocused),
    };

    const onChangeProxy = useCallback(
      (event: ChangeEvent<HTMLInputElement>) =>
        onChange(event.target.value, event),
      [onChange],
    );

    return (
      <div
        className={className}
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: stretch;
          `}
        >
          {hasPrefix && (
            <div
              css={prefixStyle}
              className={cn(size, { disabled: props.disabled })}
            >
              <Typography variant={TYPOGRAPHY_SIZE_MAPPING[size]}>
                {prefix}
              </Typography>
            </div>
          )}

          <div
            css={css`
              position: relative;
              width: 100%;
            `}
          >
            <input
              {...props}
              {...focusProps}
              ref={ref}
              maxLength={maxLength}
              onChange={onChangeProxy}
              className={cn(size, {
                hasPrefix,
                hasIconLeft,
                hasIconRight,
                hasError,
                hasWarning: !hasError && !!hasWarning,
              })}
              css={[
                inputStyle,
                theme.typography[TYPOGRAPHY_SIZE_MAPPING[size]],
              ]}
            />

            {hasIconLeft &&
              cloneElement(iconLeft, {
                ...iconProps,
                className: cn(iconLeft.props.className, 'iconLeft'),
              })}

            {hasIconRight &&
              cloneElement(iconRight, {
                ...iconProps,
                className: cn(iconRight.props.className, 'iconRight'),
              })}

            {rightContent && (
              <div className={cn(rightContent.props.className, 'rightContent')}>
                {rightContent}
              </div>
            )}
          </div>
        </div>

        {!!maxLength && showMaxLength && (
          <TextMaxLength
            size={INPUT_MAX_LENGTH_SIZE_MAPPING[size]}
            maxLength={maxLength}
            currentLength={props.value?.length || 0}
            isFocused={isFocused}
            css={css`
              align-self: flex-end;
            `}
          />
        )}
      </div>
    );
  },
);

InputBase.displayName = 'Input';

export const Input: MemoExoticComponent<typeof InputBase> & {
  SIZES?: typeof INPUT_SIZES;
  MAX_LENGTH_SIZE_MAPPING?: typeof INPUT_MAX_LENGTH_SIZE_MAPPING;
} = memo(InputBase);

Input.SIZES = INPUT_SIZES;
Input.MAX_LENGTH_SIZE_MAPPING = INPUT_MAX_LENGTH_SIZE_MAPPING;
