import {
  type ForwardedRef,
  forwardRef,
  type HTMLProps,
  memo,
  type ReactNode,
  useContext,
} from 'react';
import { css } from '@emotion/react';
import cn from 'classnames';
import { type Element as HastElement, type Root as HastRoot } from 'hast';
import { last } from 'lodash';

import { type TABLE_BACKGROUND_COLORS } from '@eversity/domain/constants';
import {
  type TableHastElement,
  type TableRowHastElement,
} from '@eversity/types/domain';
import { findParent } from '@eversity/utils/misc';

import { HastRendererContext } from '../../HastRenderer.context';

export type CellProps = Omit<HTMLProps<HTMLTableCellElement>, 'ref'> & {
  /** Root element tag name. */
  tagName: 'th' | 'td';
  /** Path to the element in the hast. */
  path: number[];
  /** Background color enum. */
  backgroundColor?: TABLE_BACKGROUND_COLORS;
  /** Children. */
  children?: ReactNode;
  /** Root element class name. */
  className?: string;
};

export const CellBase = forwardRef(
  (
    {
      tagName: Component,
      backgroundColor = null,
      path,
      children = null,
      className,
      ...props
    }: CellProps,
    ref: ForwardedRef<HTMLTableCellElement>,
  ) => {
    const hast = useContext(HastRendererContext);

    const [tr] = findParent<HastRoot | HastElement>(
      hast,
      path,
      (node) => node.type === 'element' && node.tagName === 'tr',
    ) as [TableRowHastElement, number[]];

    const [table] = findParent<HastRoot | HastElement>(
      hast,
      path,
      (node) => node.type === 'element' && node.tagName === 'table',
    ) as [TableHastElement, number[]];

    const colCount = tr.children?.length || 1;
    const colIndex = last(path);
    const colRatio =
      JSON.parse(table.properties?.dataColRatios || '[]')[colIndex] || 1;

    return (
      <Component
        {...props}
        ref={ref}
        className={cn(className, backgroundColor)}
        css={css`
          width: ${(100 * colRatio) / colCount}%;
        `}
      >
        <div className="cellContent">{children}</div>
      </Component>
    );
  },
);

CellBase.displayName = 'Cell';

export const Cell = memo(CellBase);
