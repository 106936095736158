import { css } from '@emotion/react';

import { removeButtonAppearance } from '@eversity/ui/design-system';

export const eggContainer = css`
  ${removeButtonAppearance};

  position: absolute;

  z-index: -1;

  // Hitbox
  padding: 20px;

  &.orientation-left {
    rotate: -10deg;
  }

  &.orientation-right {
    rotate: 10deg;
  }

  &.top-left {
    top: -40px;
    left: 0px;
  }

  &.top-right {
    top: -40px;
    right: 0px;
  }

  &.bottom-left {
    bottom: -40px;
    left: 0px;
  }

  &.bottom-right {
    bottom: -40px;
    right: 0px;
  }

  &.isHidden {
    display: none;
  }
`;

export const wrapper = css`
  position: relative;

  z-index: 0;
`;

export const childContainer = css`
  z-index: 1;
`;
