import { Fragment } from 'react';

import HeadSvg from '../../../../../assets/images/ornament-wrapper/head.raw.svg';
import LeftHandSvg from '../../../../../assets/images/ornament-wrapper/left-hand.raw.svg';
import RightHandSvg from '../../../../../assets/images/ornament-wrapper/right-hand.raw.svg';
import * as styles from '../OrnamentWrapper.styles';

export const Embrace = () => (
  <Fragment>
    <div css={styles.headSvg(HeadSvg)} />

    <div css={styles.leftHandSvg(LeftHandSvg)} />

    <div css={styles.rightHandSvg(RightHandSvg)} />
  </Fragment>
);
