import { css } from '@emotion/react';

import { SIZES, SPARKLES_POSITION_MAPPING } from './constants';

export const container = css`
  position: relative;
`;

export const headSvg = (headPicture: string) => css`
  position: absolute;
  z-index: -1;
  top: -45px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url(${headPicture});
  width: 85px;
  height: 115px;
`;

export const leftHandSvg = (leftHandPicture: string) => css`
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: -13px;
  background-image: url(${leftHandPicture});
  width: 26px;
  height: 43px;
  z-index: 1;
`;

export const rightHandSvg = (rightHandPicture: string) => css`
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: -12px;
  background-image: url(${rightHandPicture});
  width: 24px;
  height: 43px;
  z-index: 1;
`;

export const magnifyingGlassSvg = (magnifyingGlassPicture: string) => css`
  position: absolute;
  z-index: 1;
  bottom: -122px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url(${magnifyingGlassPicture});
  width: 150px;
  height: 150px;
`;

export const sparkles = css`
  position: absolute;

  &.topRight {
    &.${SIZES.SMALL} {
      top: ${SPARKLES_POSITION_MAPPING.topRight[SIZES.SMALL].top}px;
      right: ${SPARKLES_POSITION_MAPPING.topRight[SIZES.SMALL].right}px;
    }
    &.${SIZES.MEDIUM} {
      top: ${SPARKLES_POSITION_MAPPING.topRight[SIZES.MEDIUM].top}px;
      right: ${SPARKLES_POSITION_MAPPING.topRight[SIZES.MEDIUM].right}px;
    }
    &.${SIZES.LARGE} {
      top: ${SPARKLES_POSITION_MAPPING.topRight[SIZES.LARGE].top}px;
      right: ${SPARKLES_POSITION_MAPPING.topRight[SIZES.LARGE].right}px;
    }
  }

  &.bottom {
    &.${SIZES.SMALL} {
      bottom: ${SPARKLES_POSITION_MAPPING.bottom[SIZES.SMALL].bottom}px;
      left: ${SPARKLES_POSITION_MAPPING.bottom[SIZES.SMALL].left}px;
    }
    &.${SIZES.MEDIUM} {
      bottom: ${SPARKLES_POSITION_MAPPING.bottom[SIZES.MEDIUM].bottom}px;
      left: ${SPARKLES_POSITION_MAPPING.bottom[SIZES.MEDIUM].left}px;
    }
    &.${SIZES.LARGE} {
      bottom: ${SPARKLES_POSITION_MAPPING.bottom[SIZES.LARGE].bottom}px;
      left: ${SPARKLES_POSITION_MAPPING.bottom[SIZES.LARGE].left}px;
    }
  }

  &.bottomLeft {
    &.${SIZES.SMALL} {
      bottom: ${SPARKLES_POSITION_MAPPING.bottomLeft[SIZES.SMALL].bottom}px;
      left: ${SPARKLES_POSITION_MAPPING.bottomLeft[SIZES.SMALL].left}px;
    }
    &.${SIZES.MEDIUM} {
      bottom: ${SPARKLES_POSITION_MAPPING.bottomLeft[SIZES.MEDIUM].bottom}px;
      left: ${SPARKLES_POSITION_MAPPING.bottomLeft[SIZES.MEDIUM].left}px;
    }
    &.${SIZES.LARGE} {
      bottom: ${SPARKLES_POSITION_MAPPING.bottomLeft[SIZES.LARGE].bottom}px;
      left: ${SPARKLES_POSITION_MAPPING.bottomLeft[SIZES.LARGE].left}px;
    }
  }
`;

export const flashCardsSvg = (flashCardsPicture: string) => css`
  position: absolute;
  top: 0;
  z-index: 1;
  left: 50%;

  transform: translate(-50%, -103px);
  background-image: url(${flashCardsPicture});
  width: 141px;
  height: 125px;
`;
