export enum DRAWER_SIZES {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum DRAWER_POSITIONS {
  LEFT = 'left',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  FULL_SCREEN = 'full-screen',
}

export const DRAWER_LAYER_TYPE = Symbol('drawer');
