import { useMemo } from 'react';
import { useTheme } from '@emotion/react';

import { getDeterministicTextColor } from '../../config/colors';

/**
 * Get a deterministic color for a user. The formula does not matter.
 *
 * @param firstName - First name of the user.
 * @param lastName - Last name of the user.
 * @returns Color code.
 */
export const useUserThemeColor = (firstName: string, lastName: string) => {
  const theme = useTheme();

  const availableColors = useMemo(
    () => [
      theme.colors.primary[500],
      theme.colors.secondary[500],
      theme.colors.tertiary[500],
      theme.colors.success[500],
      theme.colors.warning[500],
      theme.colors.danger[500],
    ],
    [theme],
  );

  const color = useMemo(
    () => getDeterministicTextColor(`${firstName}${lastName}`, availableColors),
    [firstName, lastName, availableColors],
  );

  return color;
};
