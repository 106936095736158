import {
  type ForwardedRef,
  forwardRef,
  memo,
  useCallback,
  useMemo,
} from 'react';

import {
  type SanitizeSchema,
  transformHtmlToHast,
} from '@eversity/domain/html';
import {
  HastRenderer,
  type HastRendererProps,
} from '@eversity/ui/design-system';

import ContentRenderRootElement from './render-root-element/ContentRenderRootElement';

export type ContentProps = {
  content: string;
  sanitizeSchema: SanitizeSchema;
  headerHeightInPx?: number;
};

export const ContentBase = forwardRef(
  (
    { content, sanitizeSchema, headerHeightInPx = 0 }: ContentProps,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const contentHast = useMemo(
      () => transformHtmlToHast(content, { sanitizeSchema }),
      [content, sanitizeSchema],
    );

    const renderElementContent: HastRendererProps['renderElement'] =
      useCallback(
        ({ depth, ...props }) =>
          depth === 0 ? (
            <ContentRenderRootElement
              {...props}
              headerHeightInPx={headerHeightInPx}
            />
          ) : (
            <HastRenderer.DefaultRenderElement
              {...props}
              depth={depth}
            />
          ),
        [headerHeightInPx],
      );

    return (
      <HastRenderer
        ref={ref}
        hast={contentHast}
        renderElement={renderElementContent}
      />
    );
  },
);

ContentBase.displayName = 'Content';

export default memo(ContentBase);
